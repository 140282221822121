import Model from '@common/models/orm/OrmBaseModel'

export default class ChatNode extends Model {
  static DEFAULT_WEIGHT = 0

  static className = 'ChatNode'

  static entity = 'chat-nodes'

  static formFields = [
    'id',
    'vid',
    'parentVid',
    'defaultChildWeight',
    'weight',
    'payload',
    'payloadType',
    'storyRevisionId',
    'modifiers',
    'variableId',
  ]

  static primaryKey = ['id', 'vid', 'storyRevisionId']

  static fields() {
    return {
      id: this.attr(''),
      vid: this.attr(''),
      parentVid: this.attr(''),
      storyRevisionId: this.attr(''),
      defaultChildWeight: this.attr(this.DEFAULT_WEIGHT),
      weight: this.attr(this.DEFAULT_WEIGHT),
      activeChildWeight: this.number(this.DEFAULT_WEIGHT), // virtual field, not in api JSON
      payload: this.attr({}),
      payloadType: this.attr(''),
      modifiers: this.attr([]),
      variableId: this.string('').nullable(),
    }
  }

  // TODO: Replace to reduce dependency on chat elements
  /* get chatElement() {
    return ChatElementFactory.create(this.payload, this.payloadType)
  } */

  /**
   * @returns {ChatNode}
   */
  clone() {
    const json = JSON.parse(JSON.stringify(this.$toJson()))
    const clone = new ChatNode(json)

    clone.activeChildWeight = this.activeChildWeight

    return clone
  }

  /**
   * @returns {boolean}
   */
  isNew() {
    return !this.vid
  }

  getModifierByType(type) {
    return this.modifiers.find((i) => i.type === type)
  }
}
