export const MODIFIER_FOCUS_MODE = 'focus'
export const MODIFIER_READ_TIME = 'read-time'
export const MODIFIER_CLOSE = 'close'
export const MODIFIER_SESSION_ONCE = 'once-a-session'
export const MODIFIER_CAROUSEL = 'carousel'
export const MODIFIER_DONT_ENLARGE_IMAGE = 'dont-enlarge-image'
export const MODIFIER_LIVECHAT_AUTO_SUBMIT = 'autosubmit-username'
export const MODIFIER_FILE_UPLOAD = 'dont-show-file-upload-detail'
export const MODIFIER_INPUT_LOCATION_SAMPLE_TEXT = 'input-location-sample-text'
export const MODIFIER_INPUT_CUSTOM_VALIDATION = 'custom-input-validation'
export const MODIFIER_INPUT_EXAMPLES = 'examples'
export const MODIFIER_INPUT_WORKFLOW_VALIDATION = 'workflow-input-validation'
export const MODIFIER_TIME_BASED_CONTENT = 'time-based-content'
export const MODIFIER_UNTRANSLATABLE = 'untranslatable'
export const MODIFIER_WORKFLOW_CURRENT_SESSION_VARIABLES =
  'only-current-session-variables'
export const MODIFIER_SURVEY_USER_INTERACTION_REQUIRED =
  'user-interaction-required'

export const MODIFIER_VISIBLE_ONLY_FOR_MESSAGING_API =
  'visible-only-for-messaging-api'
export const MODIFIER_HIDDEN_FOR_MESSAGING_API = 'hidden-for-messaging-api'
export const MODIFIER_ROBOT_DISCLAIMER = 'robot-disclaimer'

export const MODIFIERS = [
  MODIFIER_FOCUS_MODE,
  MODIFIER_READ_TIME,
  MODIFIER_CLOSE,
  MODIFIER_SESSION_ONCE,
  MODIFIER_CAROUSEL,
  MODIFIER_DONT_ENLARGE_IMAGE,
  MODIFIER_LIVECHAT_AUTO_SUBMIT,
  MODIFIER_FILE_UPLOAD,
  MODIFIER_INPUT_LOCATION_SAMPLE_TEXT,
  MODIFIER_INPUT_CUSTOM_VALIDATION,
  MODIFIER_INPUT_WORKFLOW_VALIDATION,
  MODIFIER_WORKFLOW_CURRENT_SESSION_VARIABLES,
  MODIFIER_INPUT_EXAMPLES,
  MODIFIER_SURVEY_USER_INTERACTION_REQUIRED,
  MODIFIER_UNTRANSLATABLE,
  MODIFIER_TIME_BASED_CONTENT,
  MODIFIER_ROBOT_DISCLAIMER,
]
