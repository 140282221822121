import {
  email,
  helpers,
  maxLength,
  minLength,
  required,
  requiredUnless,
  requiredIf,
  or,
} from 'vuelidate/lib/validators'
import { inArray } from '@common/plugins/validators'
import CountryFactory from '@common/models/CountryFactory'

/**
 * NOTE: Tip: required property seems to be inverted/weird in general
 * Sometimes you need to use opposite of w/e function you're using
 * ie: requiredUnless --> requiredIf, vice-versa, etc ¯\_(ツ)_/¯
 */

export default {
  email: {
    required,
    email,
  },
  firstName: {
    // Required unless company name is set
    required: requiredUnless((model) => {
      return helpers.req(model.companyName)
    }),
    maxLength: maxLength(32),
  },
  lastName: {
    // Required unless company name is set
    required: requiredUnless((model) => {
      return helpers.req(model.companyName)
    }),
    maxLength: maxLength(32),
  },
  companyName: {
    // Required unless first name and last name are set
    required: or(
      requiredIf((model) => {
        return helpers.req(model.firstName) && helpers.req(model.lastName)
      }),
      requiredIf((model) => {
        return helpers.req(model.vatId)
      }),
    ),
    maxLength: maxLength(64),
  },
  vatId: {
    maxLength: maxLength(64),
  },
  addressLine1: {
    maxLength: maxLength(64),
  },
  street: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(64),
  },
  city: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(64),
  },
  postalCode: {
    required,
    minLength: minLength(2),
    maxLength: maxLength(32),
  },
  country: {
    required,
    missingSelection: inArray(
      CountryFactory.getCountryCodes.map((item) => item.countryCode),
    ),
    maxLength: maxLength(2),
  },
}
