import supportedBrowsersRegex from '@/plugins/supportedBrowsers'

const REDIRECT_URL = '/browser-not-supported'
const STATUS_OK = 200

export default ({ route, redirect }) => {
  window.spaRoute = route.fullPath

  const { userAgent } = window.navigator
  const { path } = route

  if (path.includes('webchat-session-handover')) {
    return
  }

  const isBrowserSupported = supportedBrowsersRegex.test(userAgent)
  const isRedirected = path === REDIRECT_URL

  if (!isBrowserSupported && !isRedirected) {
    return redirect(STATUS_OK, REDIRECT_URL)
  }
}
