import TwitterStyle from 'javascript-time-ago/commonjs/style/twitter'
import { canonical, getStep } from 'javascript-time-ago/commonjs/gradation/'
import de from 'javascript-time-ago/locale/de'
import en from 'javascript-time-ago/locale/en'
import enlocale from 'date-fns/locale/en-US'
import delocale from 'date-fns/locale/de'
import formatDate from 'date-fns/format'
import formatISO from 'date-fns/formatISO'

const defaultLocale = 'de'

const dateFnsLocales = {
  en: enlocale,
  de: delocale,
}

export const timeAgoConfig = {
  locale: {
    de,
    en,
  },
  // Customized Twitter format to use longer strings as well as add
  // a custom gradation step for when something has been updated less
  // than 45 seconds ago to show 'just now' instead of a blank string
  // https://github.com/catamphetamine/javascript-time-ago/issues/10
  gradation: [
    {
      ...getStep(canonical, 'now'),
      factor: 1,
    },
    ...TwitterStyle.gradation,
  ],
  flavours: {
    // 'tiny' is custom, 'narrow' is CLDR
    short: ['tiny', 'narrow'],
    // 'long-convenient' is custom, 'long' is CLDR
    long: ['long-convenient', 'long'],
  },

  updateInterval: 15 * 1000,
}

export const formatDateLocale = (value, format, locale = defaultLocale) => {
  const date = typeof value === 'object' ? value : new Date(value)

  return formatDate(date, format, { locale: dateFnsLocales[locale] })
}

export const toLocalDateTime = (
  value,
  locale = defaultLocale,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  },
) => {
  const event = typeof value === 'object' ? value : new Date(value)

  return event.toLocaleDateString(locale, options)
}

export const toLocalDate = (
  value,
  locale = defaultLocale,
  includeDay = true,
  options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
) => {
  const event = typeof value === 'object' ? value : new Date(value)

  if (!includeDay) {
    options.day = undefined
  }

  return event.toLocaleDateString(locale, options)
}

export const toIsoDate = (value) => {
  return formatISO(value, { representation: 'date' })
}

export const getMonths = (local = '', format = 'abbreviated') => {
  const monthIndices = Array(12)
    .fill(0)
    .map((_, idx) => idx)

  if (local === 'en') {
    return monthIndices.map((n) =>
      enlocale.localize.month(n, { width: format }),
    )
  }

  if (local === 'de') {
    return monthIndices.map((n) =>
      delocale.localize.month(n, { width: format }),
    )
  }

  return []
}

export const getFirstDayOfWeek = (locale = '') => {
  // For now, always return Monday (European style). At the moment there is no Intl Support to get this kind of
  // information without pulling in another additional library (moment) or storing this for each locale.
  return 1
}
