import stateSetters from '@common/plugins/state-setters'
import Story from '@common/models/orm/Story'
import { createApiUrl } from '@common/plugins/helpers'
import IncomingStoryLink from '@/models/orm/IncomingStoryLink'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    getByStoryId:
      (state, { query }) =>
      (storyId) => {
        return query().where('storyId', storyId).get()
      },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByStoryId({ dispatch }, storyId) {
      const url = createApiUrl(Story.entity, storyId, 'links')

      try {
        const { data } = await this.$axios.get(url).then(({ data }) => data)
        const { id, incomingStories } = data

        // Delete all existing incoming story links for the story id
        await IncomingStoryLink.delete(
          (incomingStoryLink) => incomingStoryLink.storyId === id,
        )

        // Insert the incoming story by also referencing the id of the story linked to
        await IncomingStoryLink.insert({
          data: incomingStories.map((incomingStory) => {
            incomingStory.storyId = id

            return incomingStory
          }),
        })
      } catch (err) {
        throw err
      }
    },
  },
}
