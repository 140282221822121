import stateSetters from '@common/plugins/state-setters'
import { createApiUrl } from '@common/plugins/helpers'

import Project from '@common/models/orm/Project'
import SessionUser from '@/models/orm/SessionUser'
import Model from '@/models/orm/SessionChat'

const defaultState = {
  totalSessions: 0,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    totalSessions: ({ totalSessions }) => totalSessions,
    getAll: (state, { query }) => query().withAll().get(),
    getByUserId:
      (state, { query }) =>
      (userId) =>
        query().where('userId', userId).withAll().get(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByUserId({ commit }, { id, projectId, options }) {
      const url = createApiUrl(
        Project.entity,
        projectId,
        SessionUser.endpoint,
        id,
        Model.endpoint,
      )

      const searchParams = new URLSearchParams()
      const offset = (options.page - 1) * options.limit
      const direction = options.order === 'desc' ? 'descending' : 'ascending'

      searchParams.append('limit', options.limit)
      searchParams.append('offset', offset)
      searchParams.append('order', `${options.sortBy}:${direction}`)

      if (options.from) {
        searchParams.append('start-startedAt', options.from)
      }

      if (options.to) {
        searchParams.append('end-startedAt', options.to)
      }

      options.filter.forEach((condition) => {
        searchParams.append(condition, true)
      })

      const { data, metadata } = await this.$axios
        .get(`${url}?${searchParams.toString()}`)
        .then(({ data }) => data)

      if (!data) {
        return
      }

      // use create to avoid building up a large list of sessions in memory
      await this.$db().model(Model).create({ data })

      commit('setTotalSessions', metadata.pagination.total)
    },
  },
}
