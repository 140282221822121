<template>
  <component
    :is="tag"
    @submit.prevent="$emit('submit', $event)"
    @change="$emit('change', $event)"
    @keydown="$emit('keydown', $event)"
  >
    <slot></slot>
  </component>
</template>

<script>
export default {
  name: 'FormWrapper',
  provide() {
    return {
      formValidator: this.validator,
      formMessages: this.messages,
    }
  },
  props: {
    tag: {
      type: String,
      default: 'form',
    },
    validator: {
      type: Object,
      required: true,
    },
    messages: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
