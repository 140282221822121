import Model from '@common/models/orm/OrmCrudModel'

export default class Group extends Model {
  static className = 'Group'

  static entity = 'groups'

  static formFields = ['id', 'name', 'items', 'position', 'projectId']

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      items: this.attr([]),
      position: this.number(0),
      projectId: this.string(''),
      ...super.fields(),
    }
  }
}
