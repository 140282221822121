import { required, minLength, maxLength, not } from 'vuelidate/lib/validators'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import { MODIFIER_INPUT_CUSTOM_VALIDATION } from '@common/constants/chat-element-modifiers'
import Model from '@/models/chatElements/ChatElementBaseModel'

const emptyContent = '<p></p>'

function noNewLineValidation(value) {
  if (typeof value !== 'string') {
    return
  }

  const startingIndex = value.indexOf('<p>')
  const closingIndex = value.lastIndexOf('</p>')

  if (startingIndex === -1 || closingIndex === -1) {
    return true
  }

  const substr = value.substring(startingIndex + 3, closingIndex)

  if (substr.includes('<p>') || substr.includes('</p>')) {
    return false
  }

  return true
}

export default class ChatElementButtonGroup extends Model {
  static className = 'ChatElementButtonGroup'
  static entity = 'button-group'
  static label = 'general.chat_element_button_group'

  static formFields = ['buttons', 'content', 'allowCustomValue', 'placeholder']

  static direction = Model.INTERACTION_IN
  static MAX_BUTTON_COUNT = 5

  static fields() {
    return {
      buttons: this.attr([
        {
          id: '',
          value: '',
        },
      ]),
      content: this.attr(''),
      allowCustomValue: this.attr(false),
      placeholder: this.attr(''),
    }
  }

  static getAvailableModifiers() {
    return [MODIFIER_INPUT_CUSTOM_VALIDATION]
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonButtonGroup')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormButtonGroup')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormButtonGroup/ChatFormButtonGroupTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/navigation-menu.svg')
  }

  static getFormValidations() {
    return {
      form: {
        buttons: {
          required,
          minLength: minLength(1),
          $each: {
            value: {
              required,
              maxLength: maxLength(144),
              sameAsRawValue: not((value) => {
                return value === emptyContent
              }),
              noNewLine: noNewLineValidation,
            },
          },
        },
        content: {},
      },
      variableId: {},
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        buttons: {
          required,
          minLength: minLength(1),
          $each: {
            value: {
              required,
              maxLength: maxLength(144),
              sameAsRawValue: not((value) => {
                return value === emptyContent
              }),
              noNewLine: noNewLineValidation,
            },
          },
        },
        content: {},
      },
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
      isRoot: true,
    }
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      buttons: this.buttons,
      content: this.content,
      variables,
      storyLinkTargets,
    }
  }
}
