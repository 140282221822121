<template>
  <ModalDialog v-model="isOpen" :width="modalWidth" :can-cancel="false">
    <div class="email-verification-modal has-text-centered">
      <h1 class="title is-3" v-html="$t('register.email_verification.title')" />
      <div class="email-verification-modal--inner-container flex-hv-centered">
        <IllustrationBottle
          class="email-verification-modal--inner-container--illustration"
        />
        <div class="email-verification-modal--inner-container--text">
          <p
            v-html="$t('register.confirm.email_has_sent_to', [email])"
            class="is-6"
          />
          <p
            class="is-6"
            v-html="$t('register.email_verification.inner_text.title')"
          />
          <p
            class="is-6"
            v-html="$t('register.email_verification.inner_text.subtitle')"
          />
        </div>

        <div class="flex-hv-centered">
          <p
            class="is-6"
            v-html="$t('register.email_verification.no_confirmation')"
          />
          <button class="button request-again" @click="$emit('resend')">
            {{ $t('register.email_verification.button.request_again.text') }}
          </button>
        </div>
      </div>
      <div class="button-submit flex-hv-centered">
        <div class="button-submit--control">
          <b-button
            class="has-stamp-icon"
            type="is-secondary"
            rounded
            @click="$emit('refresh')"
          >
            {{ $t('register.email_verification.button.refresh_status.text') }}
            <IconArrowRightCircle class="icon" />
          </b-button>
        </div>
      </div>
    </div>
  </ModalDialog>
</template>
<script>
import ModalDialog from '@common/components/modals/ModalDialog'
import UiNotifications from '@common/mixins/UiNotifications'
import IllustrationBottle from '~/assets/illustrations/illustration-bottle.svg'
import IconArrowRightCircle from '~common/assets/inline/circle-icon/arrow-circle-right-flat.svg'

export default {
  components: {
    ModalDialog,
    IllustrationBottle,
    IconArrowRightCircle,
  },
  mixins: [UiNotifications],
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    email: {
      type: String,
      required: true,
    },
    modalWidth: {
      type: Number,
      default: 740,
    },
  },
  data() {
    return {
      isOpen: this.value,
    }
  },
  watch: {
    value(val) {
      this.isOpen = val
    },
    isOpen(val) {
      this.$emit('input', val)
    },
  },
}
</script>
<style lang="scss">
.email-verification-modal {
  &--inner-container {
    flex-direction: column;
    margin: 0px $sp-8;

    &--illustration {
      display: block;
      width: 60%;
    }

    .request-again {
      background: transparent;
      color: $blue-500;
      border: none;
      box-shadow: none;
      text-decoration: underline;
      font-weight: normal;
      cursor: pointer;

      &:focus:not(:active) {
        box-shadow: none;
      }
    }
    &--text {
      p:nth-child(1) {
        margin-bottom: $sp-4;
      }

      p:nth-child(2) {
        margin-top: $sp-20;
      }

      p:nth-child(3) {
        font-weight: bold;
        margin: $sp-4 0px $sp-16 0px;
      }
    }

    .verification-input {
      margin: $sp-4 0px;
    }
  }

  .button-submit {
    width: 100%;
    &--control {
      position: absolute;
      margin-top: $sp-64;
    }
  }
}
</style>
