import { APP_PUBLIC_API_PREFIX } from '@common/plugins/api'
import stateSetters from '@common/plugins/state-setters'
import Model from '@/models/orm/ChatbotSessionHandover'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById({ dispatch }, id) {
      await dispatch(
        'fetchByModelId',
        {
          Model,
          id,
          apiPrefix: APP_PUBLIC_API_PREFIX,
        },
        {
          root: true,
        },
      )
    },
    async setCurrent({ commit }, id) {
      await commit('setCurrent', id)
    },
  },
}
