import Model from './OrmCrudModel'
import ChatNode from './ChatNode'

export default class StoryRevision extends Model {
  static className = 'StoryRevision'

  static entity = 'story-revisions'

  static STATUS_UNPUBLISHED = 'unpublished'

  static STATUS_PUBLISHED = 'published'

  static STATUS_DRAFT = 'draft'

  static formFields = ['id', 'storyId', 'language', 'status']

  static fields() {
    return {
      id: this.attr(''),
      language: this.attr(''),
      status: this.attr(''),
      storyId: this.attr(''),
      chatNodes: this.hasMany(ChatNode, 'storyRevisionId'),
      ...super.fields(),
    }
  }
}
