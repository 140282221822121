import Model from '@common/models/orm/OrmCrudModel'

export default class IncomingStoryLink extends Model {
  static className = 'IncomingStoryLink'

  static entity = 'incoming-story-links'

  static fields() {
    return {
      id: this.attr(''),
      storyId: this.attr(''),
      isActive: this.boolean(false),
      name: this.attr(''),
      storyRevisionStatusList: this.attr([]),
    }
  }
}
