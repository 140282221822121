<template>
  <div class="story-builder">
    <AppOutdatedBanner v-if="isAppOutdated" />
    <StoryBuilderHeaderTranslate
      v-if="translate"
      :project="project"
      :story="story"
    />
    <StoryBuilderHeader v-else :project="project" :story="story" />
    <nuxt />
    <portal-target name="modals" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Project from '@common/models/orm/Project'
import Story from '@common/models/orm/Story'
import UiNotifications from '@common/mixins/UiNotifications'
import AppOutdatedBanner from '@common/components/AppOutdatedBanner'
import StoryBuilderHeader from '@/components/storyBuilder/StoryBuilderHeader'
import StoryBuilderHeaderTranslate from '@/components/storyBuilder/StoryBuilderHeaderTranslate'

export default {
  components: {
    StoryBuilderHeader,
    StoryBuilderHeaderTranslate,
    AppOutdatedBanner,
  },
  mixins: [UiNotifications],
  middleware: ['app-init'],
  computed: {
    ...mapGetters({
      currentRevision: 'story-builder/currentRevision',
      isAppOutdated: 'env-config/isAppOutdated',
    }),
    project() {
      return this.$store.$db().model(Project).getters('current')
    },
    story() {
      return this.$store.$db().model(Story).getters('current')
    },
    translate() {
      return this.$route.params.lang !== undefined
    },
  },
  head() {
    return {
      htmlAttrs: {
        class: 'hide-body-overflow',
      },
      bodyAttrs: {
        class: this.$route.query.grid ? 'grid-overlay' : '',
      },
    }
  },
}
</script>
