import ErrorHandler from '@common/models/ErrorHandler'

export default {
  methods: {
    entityDeletedNotice(entity = '') {
      this.notify(
        this.$t('toast.entity_deleted', {
          entity,
        }),
        null,
        'is-info',
      )
    },
    entityCreatedNotice(entity = '') {
      this.notify(
        this.$t('toast.entity_created', {
          entity,
        }),
        null,
        'is-success',
      )
    },
    entityUpdatedNotice(entity = '') {
      this.notify(
        this.$t('toast.entity_updated', {
          entity,
        }),
        null,
        'is-success',
      )
    },
    entityDuplicatedNotice(entity = '') {
      this.notify(
        this.$t('toast.entity_duplicated', {
          entity,
        }),
        null,
        'is-success',
      )
    },
    errorNotice(error = {}) {
      const glitch = new ErrorHandler(error, this.$i18n)
      const message = glitch.getNotificationString()

      this.notify(message, null, 'is-danger', 6000, false)
    },
    notify(
      message,
      title = 'Success',
      type = 'is-success',
      duration = 3000,
      autoClose = true,
    ) {
      this.$buefy.notification.open({
        message,
        title,
        type,
        duration,
        'has-icon': true,
        'auto-close': autoClose,
        position: 'is-bottom-right',
        class: 'float',
      })
    },
  },
}
