<template>
  <div class="app-outdated-banner">
    <p>
      🎉 {{ $t('general.storybuilder.banner.app_outdated.info.primary') }}
      <span>
        <a @click="onReload" v-t="'cta.reload.now'" />
      </span>
    </p>
  </div>
</template>

<script>
export default {
  methods: {
    onReload(event) {
      event.stopPropagation()
      location.reload(true)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-outdated-banner {
  display: flex;
  font-weight: 500;
  justify-content: center;
  background-color: $blue-100;
  padding: 0.75rem 1rem;
  grid-area: banner;
}
</style>
