import stateSetters from '@common/plugins/state-setters'
import Model from '@common/models/orm/Project'
import DeeplLanguage from '@common/models/orm/DeeplLanguage'
import { createApiUrl } from '@common/plugins/helpers'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {},
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchDeeplLanguages({ dispatch }, id) {
      const url = createApiUrl(Model.entity, id, 'deepl-languages')

      const { data } = await this.$axios
        .get(url, { expectList: true })
        .then(({ data }) => data)

      if (!data) {
        return
      }

      await this.$db().model(DeeplLanguage).insertOrUpdate({
        data,
      })
    },
  },
}
