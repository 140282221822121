<template>
  <div class="site">
    <header class="simple-header is-flex">
      <nuxt-link class="simple-header--link" :to="localePath('account-login')">
        <Logo class="simple-header--logo" />
      </nuxt-link>
    </header>
    <nuxt class="site--content" />
    <footer class="footer footer-transparent">
      <p
        class="subtitle is-7 has-text-weight-semibold has-text-white has-text-centered"
      >
        {{ $t('general.registered_trademark') }}
      </p>
    </footer>
  </div>
</template>

<script>
import PreferredLanguage from '@common/mixins/PreferredLanguage'
const Logo = () => import('~common/assets/inline/aiaibot-logo.svg')

export default {
  components: {
    Logo,
  },
  mixins: [PreferredLanguage],
  head() {
    return {
      bodyAttrs: {
        class: ['bg-primary-radial-gradient'].join(' '),
      },
    }
  },
}
</script>

<style lang="scss">
.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.site--content {
  flex: 1;
  width: 100%;
}

.simple-header {
  justify-content: center;
  padding: 4rem 1.5rem 1.5rem;
  flex-direction: column;
  align-items: center;
}

.simple-header--link {
  flex: 1;
}

.simple-header--logo {
  width: 150px;
  height: auto;

  path {
    fill: $secondary;
  }
}

.footer-transparent {
  background: rgba(255, 255, 255, 0.1);
}
</style>
