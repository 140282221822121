import Model from '@common/models/orm/OrmCrudModel'
import LineItem from '@common/models/orm/LineItem'
import { DEFAULT_CURRENCY } from '@common/models/BillingFactory'

export default class Order extends Model {
  static className = 'Order'

  static entity = 'billing-orders'

  static endpoint = 'contracts'

  static formFields = []

  static fields() {
    return {
      id: this.attr(null),
      contractId: this.string(''),
      currency: this.string(DEFAULT_CURRENCY),
      preview: this.boolean(false),
      nextTotal: this.number(0),
      total: this.number(0),
      totalVat: this.number(0),
      itemIds: this.attr([]),
      items: this.hasManyBy(LineItem, 'itemIds'),
    }
  }

  static getFormValidations() {
    return {}
  }
}
