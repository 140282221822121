<template>
  <div
    class="orientation-locator flex-hv-centered"
    :class="isOnProject ? 'is-on-project' : 'is-on-workspace'"
  >
    <template v-if="isOnProject">
      <a
        v-if="workspace"
        class="button home-link flex-hv-centered"
        :class="[type, { 'is-on-project': isOnProject }]"
        :href="`/workspace/${workspace.id}`"
        data-cy-id="orientation-locator-workspace-button"
      >
        <House class="icon-size-m" />
      </a>

      <multiselect
        v-model="selectedProject"
        :options="filteredProjects"
        :allow-empty="false"
        deselect-label=""
        track-by="id"
        label="name"
        :placeholder="$t('multiselect.placeholder')"
        :show-labels="false"
        class="project--chooser"
        data-cy-id="orientation-locator-project-chooser"
        @open="loadProjects"
        @select="navigate"
      >
        <template slot="caret" slot-scope="{ toggle }">
          <div
            class="multiselect__select flex-hv-centered"
            @mousedown.prevent.stop="toggle()"
          >
            <ChevronDown class="icon-size-m" />
          </div>
        </template>
        <template slot="noResult">
          {{ $t('multiselect.no_result') }}
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
          <span
            data-cy-id="orientation-locator-project-name"
            class="ellipsis project--name"
            v-text="option.name"
          />
        </template>
        <template slot="option" slot-scope="{ option }">
          <div
            class="project--initials flex-hv-centered"
            v-text="option.initials"
          />
          <span class="ellipsis" v-text="option.name" />
          <div
            v-if="option.id === project?.id"
            class="project--selection-indicator flex-hv-centered"
          >
            <Check />
          </div>
        </template>
      </multiselect>
    </template>

    <template v-else>
      <a
        v-if="workspace"
        class="button flex-hv-centered"
        :class="[type]"
        :href="`/workspace/${workspace.id}`"
        data-cy-id="orientation-locator-workspace-button"
      >
        <House class="icon-size-m" />
      </a>

      <multiselect
        v-if="allWorkspaces && allWorkspaces.length"
        v-model="selectedWorkspace"
        :options="allWorkspaces"
        :allow-empty="false"
        deselect-label=""
        track-by="id"
        label="name"
        :placeholder="$t('multiselect.workspace')"
        :show-labels="false"
        class="project--chooser"
        data-cy-id="orientation-locator-project-chooser"
        @select="updateWorkspace"
      >
        <template slot="caret" slot-scope="{ toggle }">
          <div
            class="multiselect__select flex-hv-centered"
            @mousedown.prevent.stop="toggle()"
          >
            <ChevronDown class="icon-size-m" />
          </div>
        </template>
        <template slot="noResult">
          {{ $t('multiselect.no_result') }}
        </template>
        <template slot="singleLabel" slot-scope="{ option }">
          <span
            data-cy-id="orientation-locator-project-name"
            class="ellipsis project--name"
            v-text="option.name"
          />
        </template>
        <template slot="option" slot-scope="{ option }">
          <span class="ellipsis" v-text="option.name" />
          <div
            v-if="option.id === workspace.id"
            class="project--selection-indicator flex-hv-centered"
          >
            <Check />
          </div>
        </template>
      </multiselect>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { PLAN_EXPERT } from '@common/models/BillingFactory'
import Multiselect from 'vue-multiselect'

const Check = () => import('~common/assets/inline/check.svg')
const ChevronDown = () => import('~common/assets/inline/chevron-down.svg')
const House = () => import('~common/assets/inline/house-chimney.svg')

export default {
  components: {
    Check,
    ChevronDown,
    House,
    Multiselect,
  },
  props: {
    type: {
      type: String,
      default: 'is-dark',
    },
    isOnProject: {
      type: Boolean,
      default: false,
    },
    expert: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      relationsLoaded: false,
      selectedProject: '',
      selectedWorkspace: '',
    }
  },
  computed: {
    ...mapGetters({
      workspace: 'entities/workspaces/current',
      workspaces: 'entities/workspaces/all',
      project: 'entities/projects/current',
      projects: 'entities/projects/getAll',
    }),
    currentWorkspaceProjects() {
      if (this.workspace) {
        return this.projects.filter(
          (project) => project.workspaceId === this.workspace.id,
        )
      }

      return this.projects
    },
    filteredProjects() {
      if (!this.expert) {
        return this.currentWorkspaceProjects
      }

      return this.currentWorkspaceProjects.filter(
        ({ billingPlan }) => billingPlan === PLAN_EXPERT,
      )
    },
    allWorkspaces() {
      return this.workspaces()
    },
    isWorkspace() {
      return this.$route.name === 'workspace-id'
    },
  },
  watch: {
    project(project) {
      this.selectedProject = project
    },
    workspace(workspace) {
      this.selectedWorkspace = workspace
    },
  },
  mounted() {
    this.selectedProject = this.project
    this.selectedWorkspace = this.workspace
  },
  methods: {
    ...mapActions({
      fetchRelations: 'entities/workspaces/fetchRelations',
    }),
    ...mapMutations({
      setChanging: 'entities/workspaces/setChanging',
    }),

    navigate(project) {
      this.$emit('navigate', project)
    },
    loadProjects() {
      if (this.relationsLoaded) {
        return
      }

      this.fetchRelations(this.workspace.id)
      this.relationsLoaded = !this.relationsLoaded
    },
    updateWorkspace(workspace) {
      this.setChanging(true)
      this.$emit('workspace', workspace)
    },
  },
}
</script>

<style lang="scss" scoped>
.orientation-locator {
  max-width: $column-gap * 10;
  width: 100%;
  font-size: $size-6;

  .home-link {
    color: $white;
    width: $fontbase * 2;
    height: $fontbase * 2;
    padding: var(--nudge);
    margin: 0 var(--nudge);
    flex: 1 1 0;
  }

  .workspace-link {
    height: $fontbase * 2;
    font-family: $title-family;

    svg {
      margin-right: var(--nudge);
    }
  }

  .project--name {
    position: relative;
    margin-left: $sp-8;
  }

  .project--initials {
    height: $fontbase * 2; // make it a square
    width: $fontbase * 2;
    border-radius: $radius;
    color: $blue-300;
    background-color: $blue-200;
    font-weight: $weight-bold;
    font-size: $size-6;
    line-height: 1;
    margin-right: $nudge * 2;
  }

  .project--selection-indicator {
    border-radius: 50%;
    background-color: $blue-200;
    border: 1px solid $blue-500;
    padding: calc($nudge / 2);

    svg {
      width: $fontbase;
      height: $fontbase;
      color: $blue-500;
    }
  }
}

.project--chooser {
  width: 100%;
  min-height: $fontbase * 2;
  font-weight: $weight-semibold;
  font-family: $title-family;
  font-size: $size-6;
  box-shadow: var(--box-shadow);

  &::v-deep {
    > * {
      min-height: $fontbase * 2;
    }

    .multiselect__content-wrapper {
      box-shadow: var(--box-shadow);
    }

    .multiselect__select {
      display: flex;
    }

    .multiselect__select,
    .multiselect__tags {
      height: $fontbase * 2;
      padding-top: 0;
      padding-bottom: 0;
    }

    .multiselect__tags {
      padding-left: var(--nudge);
      display: flex;
      align-items: center;
    }

    .multiselect__input {
      margin: 0;
      font-size: $size-6;
      padding-left: $sp-8;
    }

    .multiselect__single {
      display: flex;
      margin: 0;
      align-items: center;
      padding-left: 0;
      font-size: $size-6;
    }

    .multiselect__element {
      padding: 0 $nudge;
      margin: 1px 0;
      font-size: $size-6;

      .ellipsis {
        flex: 1;
      }
    }

    .multiselect__content {
      padding: $nudge 0;
    }

    .multiselect__option {
      width: 100%;
      display: flex;
      align-items: center;
    }

    .multiselect__option--selected {
      border-radius: $radius;
      background-color: transparent;
      color: $blue-grey-900;
      font-weight: $weight-semibold;
    }

    .multiselect__option--highlight {
      border-radius: $radius;
      background-color: $blue-100;
      color: $blue-grey-900;

      &::after {
        background-color: transparent;
        color: $grey-600;
      }
    }

    .multiselect__select {
      &::before {
        display: none;
      }
    }
  }
}
</style>
