import axios from 'axios'
import stateSetters from '@common/plugins/state-setters'
import stateGetters from '@common/plugins/state-getters'
import { getModuleKeyForLocalStorage } from '@common/plugins/helpers'

const checkAppVersionTime = 30 * 1000 // 30 seconds

const defaultState = {
  config: null,
  info: null,
  isAppOutdated: false,
  appVersionTimer: null,
}

export const state = () => defaultState

export const mutations = {
  ...stateSetters(defaultState),
}

export const getters = {
  ...stateGetters(defaultState),
  getConfig({ config }) {
    return config
  },
}

export const actions = {
  async fetchConfig({ getters, commit }, fileName) {
    const cfg = getters.getConfig

    if (cfg) {
      return cfg
    }

    try {
      const { data } = await axios.get(`/config/${fileName}`)

      commit('setConfig', data)

      return data
    } catch (err) {
      throw err
    }
  },
  async fetchInfo({ dispatch }, payload) {
    try {
      const { module, fileName } = payload

      const { data } = await axios.get(`/config/${fileName}`)

      window.localStorage.setItem(
        getModuleKeyForLocalStorage(module),
        data.VERSION,
      )

      dispatch('startAppVersionCheckerTimer', payload)

      return data
    } catch (err) {
      throw err
    }
  },
  startAppVersionCheckerTimer({ dispatch, commit }, payload) {
    clearTimeout(state.appVersionTimer)

    const timerId = setTimeout(() => {
      dispatch('checkAppVersion', payload)
    }, checkAppVersionTime)

    commit('setAppVersionTimer', timerId)
  },

  async checkAppVersion({ commit, dispatch }, payload) {
    const { fileName, module } = payload

    const { data } = await axios.get(`/config/${fileName}`)

    if (!data) {
      return
    }

    const appVersionInLocalStorage = window.localStorage.getItem(
      getModuleKeyForLocalStorage(module),
    )

    dispatch('startAppVersionCheckerTimer', payload)

    if (data.VERSION !== appVersionInLocalStorage) {
      commit('setIsAppOutdated', true)

      return
    }

    commit('setIsAppOutdated', false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
