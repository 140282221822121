import ModelFactoryBase from '@common/models/ModelFactoryBase'
import * as Models from '@/models/chatElements'

// Widgets order shown in Story Builder widget list
const SortedWidgets = [
  'ChatElementGhostMessage',
  'ChatElementText',
  'ChatElementMedia',
  'ChatElementUserInput',
  'ChatElementFileUpload',
  'ChatElementFileDownload',
  'ChatElementDynamicList',
  'ChatElementCarousel',
  'ChatElementButtonGroup',
  'ChatElementButtonGroupLink',
  'ChatElementIntent',
  'ChatElementFAQ',
  'ChatElementSurvey',
  'ChatElementHandoverEmail',
  'ChatElementLiveChat',
  'ChatElementStoryLink',
  'ChatElementSubStory',
  'ChatElementGoal',
  'ChatElementGoTo',
  'ChatElementVariable',
  'ChatElementWorkflow',
]

const availableModels = SortedWidgets.map((key) => ({
  entity: Models[key].entity,
  Model: Models[key],
  icon: Models[key].getIcon(),
  form: Models[key].getForm(),
  label: Models[key].label,
  platform: Models[key].platform,
  planAvailability: Models[key].getPlanAvailability(),
  dropValidation: Models[key].getGraphValidations(),
}))

export default class ChatElementFactory extends ModelFactoryBase {
  static map = availableModels.filter((item) => item.Model.active)

  static getAvailableTypes(unavailableWidgets = []) {
    return this.getTypes().filter(
      (item) => !unavailableWidgets.includes(item.entity),
    )
  }

  static fromChatNode(node) {
    return this.create(node.payload, node.payloadType)
  }
}
