import { required } from 'vuelidate/lib/validators'
import DOMpurify from 'dompurify'
import { differsTo } from '@common/plugins/validators'
import {
  MODIFIER_HIDDEN_FOR_MESSAGING_API,
  MODIFIER_READ_TIME,
  MODIFIER_SESSION_ONCE,
  MODIFIER_VISIBLE_ONLY_FOR_MESSAGING_API,
} from '@common/constants/chat-element-modifiers'

import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import { isPlainObject } from '@common/plugins/helpers'
import Model from '@/models/chatElements/ChatElementBaseModel'

export const VAR_TAG_RE = /\${([A-z0-9-]+)}/gi
export const VAR_HTML_TAG_RE = /<var[^>]*id="([A-z0-9-]+)"[^>]*>.*?<\/var>/gi

// eslint-disable-next-line no-template-curly-in-string
const VAR_TAG_TEMPLATE = '$${$1}'

export default class ChatElementText extends Model {
  static className = 'ChatElementText'

  static entity = 'html'

  static label = 'general.chat_element_text'

  static formFields = ['content']

  static emptyContent = '<p></p>'

  static fields() {
    // added sip to the default value
    /* eslint-disable no-useless-escape */
    const ALLOWED_URI_REGEXP =
      /^(?:(?:(?:f|ht)tps?|sip|mailto|tel|callto|cid|xmpp|xxx):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i

    return {
      content: this.attr('', (value) =>
        DOMpurify.sanitize(value, {
          ADD_ATTR: ['target'],
          ALLOWED_URI_REGEXP,
        }),
      ),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonText')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormText')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormText/ChatFormTextTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/paragraph-normal.svg')
  }

  static getAvailableModifiers() {
    return [
      MODIFIER_READ_TIME,
      MODIFIER_SESSION_ONCE,
      MODIFIER_VISIBLE_ONLY_FOR_MESSAGING_API,
      MODIFIER_HIDDEN_FOR_MESSAGING_API,
    ]
  }

  static getFormValidations() {
    return {
      form: {
        content: {
          required,
          empty: differsTo('<p></p>'),
        },
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        content: {
          required,
          empty: differsTo('<p></p>'),
        },
      },
    }
  }

  /**
   * @param {Array} buttons
   * @param {("toHTML"|"toTEXT")} type
   * @param {Array} variables
   */
  static mapButtons(buttons, type, variables = []) {
    return buttons.map((button) => {
      if (isPlainObject(button)) {
        const value =
          type === 'toHTML'
            ? this.toHTML(button.value, variables)
            : this.fromHTML(button.value)
                .replace(/<p>|<\/p>/gi, '')
                .trim()

        return {
          id: button.id,
          value,
        }
      }

      return type === 'toHTML'
        ? ChatElementText.toHTML(button, variables)
        : ChatElementText.fromHTML(button).trim()
    })
  }

  static toHTML(content, variables) {
    const varsById = variables.reduce((acc, v) => {
      acc[v.id] = v

      return acc
    }, {})

    if (!content || typeof content !== 'string') {
      return content
    }

    return content.replace(VAR_TAG_RE, (_, variableId) => {
      const variable = varsById[variableId]

      if (!variable) {
        return `<var id="${variableId}" data-invalid>${variableId}</var>`
      }

      return `<var id="${variableId}">${variable.name}</var>`
    })
  }

  static fromHTML(html) {
    if (html && typeof html === 'string') {
      return html.replace(VAR_HTML_TAG_RE, VAR_TAG_TEMPLATE)
    }

    return ''
  }

  static getUsedVariables({ content }) {
    const variables = content.matchAll(VAR_TAG_RE)

    return Array.from(variables).map(([, id]) => ({ id }))
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      storyLinkTargets,
      variables,
      value: DOMpurify.sanitize(this.content),
    }
  }
}
