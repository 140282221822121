<template>
  <header class="app-header">
    <div class="is-flex justify-left">
      <div class="app-header--company-icon">
        <a href="/">
          <img src="/images/aiaibot-logo-icon-light.svg" alt="aiaibot" />
        </a>
      </div>
    </div>
    <div class="is-flex justify-center">
      <slot />
    </div>
    <div class="flex-h-centered justify-right app-header--user">
      <DemoProjectIndicator
        v-if="isOnProjectPage && isProjectInDemoMode"
        class="navbar-demo-indicator"
      />

      <ReportingLink v-if="user && user.hasReporting" />

      <HelpMenuDropdown :links="helpMenuDropdownItems" />

      <UserMenuDropdown
        v-if="user"
        :label="user.name"
        data-cy-id="user-menu-dropdown"
      >
        <template #icon>
          <Avatar
            class="avatar-wrapper avatar-s"
            :name="user.name"
            :src="user.avatar"
          />
        </template>
        <template #submenu>
          <a
            aria-role="list"
            class="dropdown-item button is-text"
            href="/account/me/"
          >
            <UserIcon class="icon-size-m" />
            <span v-text="$t('general.my_profile')" />
          </a>

          <a
            v-if="workspace && workspace.isAdmin"
            aria-role="list"
            class="dropdown-item button is-text"
            :href="`/workspace/${workspace.id}/settings`"
          >
            <Settings class="icon-size-m" />
            <span v-text="$t('general.workspace_settings')" />
          </a>

          <hr class="dropdown-divider" />

          <a
            aria-role="list"
            class="dropdown-item button is-text"
            href="/account/logout/"
            data-cy-id="logout"
          >
            <Logout class="icon-size-m" />
            <span v-text="$t('cta.log_out')" />
          </a>
        </template>
      </UserMenuDropdown>
    </div>
  </header>
</template>

<script>
import User from '@common/models/orm/User'
import Workspace from '@common/models/orm/Workspace'
import Project from '@common/models/orm/Project'
import BillingFactory from '@common/models/BillingFactory'

const UserMenuDropdown = () => import('@common/components/UserMenuDropdown')
const Avatar = () => import('@common/components/Avatar')
const HelpMenuDropdown = () => import('@common/components/HelpMenuDropdown')
const ReportingLink = () => import('@common/components/ReportingLink')

const DemoProjectIndicator = () =>
  import('@common/components/DemoProjectIndicator')

export default {
  components: {
    UserMenuDropdown,
    Avatar,
    HelpMenuDropdown,
    DemoProjectIndicator,
    ReportingLink,
    UserIcon: () => import('~common/assets/inline/avatar.svg'),
    Settings: () => import('~common/assets/inline/cog.svg'),
    Logout: () => import('~common/assets/inline/logout.svg'),
  },
  props: {
    user: {
      type: [User, Object],
      required: true,
    },
    workspace: {
      type: [Workspace, Object],
      required: true,
    },
    project: {
      type: [Project, Object],
      default: null,
    },
    isOnProjectPage: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    helpMenuDropdownItems: [
      {
        name: 'general.support_help',
        url: 'general.support_help_url',
      },
      {
        name: 'general.feature_request',
        url: 'general.feature_request_url',
      },
      {
        name: 'general.bug_report',
        url: 'general.bug_report_url',
      },
    ],
  }),
  computed: {
    isProjectInDemoMode() {
      return BillingFactory.isTrialStatus(this.project?.billingStatus)
    },
  },
}
</script>

<style lang="scss">
.app-header {
  background: $white;
  height: $header-height;
  background: $blue-500-gradient;
  box-shadow: 0 2px 4px 0 rgb(167, 186, 205);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  position: relative;
  grid-area: header;

  &--user {
    height: 100%;
  }
}

.navbar-demo-indicator {
  margin-right: $sp-24;
}

.app-header--company-icon {
  width: calc(var(--grid-gutter) * 2);
  height: $header-height;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  img {
    width: calc(var(--baseline) + var(--nudge));
    height: calc(var(--baseline) + var(--nudge));
  }
}
</style>
