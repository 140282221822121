import Model from '@common/models/orm/OrmCrudModel'

export default class ClassifierLabels extends Model {
  static className = 'ClassifierLabels'

  static entity = 'classifier-labels'

  // label ids are only unique within their classifier, so need to use both fields
  static primaryKey = ['id', 'classifierId']

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      classifierId: this.attr(''),
    }
  }
}
