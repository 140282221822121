import stateSetters from '@common/plugins/state-setters'
import Agreement from '@common/models/orm/Agreement'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    getByUserId:
      (state, { query }) =>
      (userId) =>
        query().where('userId', userId).get(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByUser({ dispatch }, id) {
      let { data } = await this.$axios
        .get('/users/me/agreements', {
          expectList: true,
        })
        .then(({ data }) => data)

      if (!data) {
        return
      }

      // Add the userId as a foreign key to the agreements to establish a connection to user
      data = data.map((user) => {
        user.userId = id

        return user
      })

      await this.$db().model(Agreement).insertOrUpdate({
        data,
      })
    },
    async accept({ dispatch }, { type, userId }) {
      // eslint-disable-next-line no-console
      const { data } = await this.$axios
        .post('/users/me/agreements', {
          type,
        })
        .then(({ data }) => data)

      if (!data) {
        return
      }

      data.userId = userId
      await this.$db().model(Agreement).insertOrUpdate({
        data,
      })
    },
  },
}
