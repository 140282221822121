import uuid4 from 'uuid4'
import { getIdFromUrl as getYoutubeIdFromUrl } from 'vue-youtube'
import { helpers } from 'vuelidate/lib/validators'
import _snakeCase from 'lodash.snakecase'
import { getVimeoIdFromUrl, removeWhitespace } from './helpers'

const hexRegex = /^#([a-f0-9]{6})$/i

/**
 * @param value
 * @returns {boolean}
 */
export const isValidDomain = (value) => {
  const reg = new RegExp(
    '^(?!\\-)(xn--)?(?:[a-zA-Z\u3040-\\uFFEF\\d\\-]{0,62}[a-zA-Z\u3040-\\uFFEF\\d\\*]\\.){1,126}(?!\\d+)[a-zA-Z\u3040-\\uFFEF\\d\\-]{1,63}$',
  )

  return !helpers.req(value) || reg.test(value)
}

/**
 * @param value
 * @returns {boolean}
 */
export const isYoutubeUrl = (value) => {
  let isValid = false

  if (value) {
    isValid = !!getYoutubeIdFromUrl(value)
  }

  return !helpers.req(value) || isValid
}

/**
 * @param value
 * @returns {boolean}
 */
export const isVimeoUrl = (value) => {
  let isValid = false

  if (value) {
    isValid = !!getVimeoIdFromUrl(value)
  }

  return !helpers.req(value) || isValid
}

/**
 * @param value
 * @returns {boolean}
 */
export const isImageUrl = (value) => {
  const reg = new RegExp('(https:)([/|.|\\w|\\s|-])*\\.(?:jp(e)?g|gif|png)')

  return !helpers.req(value) || reg.test(value)
}

/**
 * @param param
 * @returns {function(*=): boolean}
 */
export const equalsTo = (param) => (value) =>
  !helpers.req(value) || param === value

/**
 * @param param
 * @returns {function(*=): boolean}
 */
export const differsTo = (param) => (value) =>
  !helpers.req(value) || param !== value

/**
 * @param param
 * @returns {function(*=): (boolean|*)}
 */
export const inArray = (param) => (value) =>
  !helpers.req(value) || param.includes(value)

/**
 * @param value
 * @returns {boolean}
 */
export const isSnakeCase = (value) => value === _snakeCase(value)

/**
 * @param value
 * @returns {boolean}
 */
export const isLegalAccepted = (value) => value === true

/**
 * Validates string to start with https
 * @param  {String} value
 * @return {Boolean}
 */
export const isHttps = (value) => {
  return !helpers.req(value) || decodeURIComponent(value).startsWith('https://')
}

/**
 * Validates uuid4 value
 * @param  {String} value
 * @return {Boolean}
 */
export const isUuid4 = (value) => uuid4.valid(value)

/**
 * @param color
 * @returns {boolean}
 */
export const is6DigitHexColor = (color) => {
  if (!color) {
    return true
  }

  return hexRegex.test(color)
}

/**
 * Validates hex value
 *
 * @link https://gist.github.com/rijkvanzanten/560dd06c4e2143aebd552abaeeee3e9b
 * @param  {String} color hex color value
 * @return {Boolean}
 */
export const isHex = (color) => {
  if (!color) {
    return true
  }

  if (typeof color !== 'string') {
    return false
  }

  // Validate hex values
  if (color.substring(0, 1) === '#') {
    color = color.substring(1)
  }

  switch (color.length) {
    case 3:
      return /^[0-9A-F]{3}$/i.test(color)
    case 6:
      return /^[0-9A-F]{6}$/i.test(color)
    case 8:
      return /^[0-9A-F]{8}$/i.test(color)
    default:
      return false
  }
}

/**
 * @param value
 * @returns {boolean}
 */
export const isCssSelector = (value) => {
  try {
    document.querySelector(value)

    return true
  } catch {
    return false
  }
}

/**
 * @param value
 * @returns {boolean}
 */
export const isValidUrl = (string) => {
  const res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g,
  )

  return res !== null
}

export const isValidCreditCardNumber = (minLength, maxLength) =>
  helpers.withParams(
    { type: 'isValidCreditCardNumber', minLength, maxLength },
    (value) => {
      const rawCardNumber = removeWhitespace(value)

      return (
        rawCardNumber.length >= minLength && rawCardNumber.length <= maxLength
      )
    },
  )
