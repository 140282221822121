import stateSetters from '@common/plugins/state-setters'
import ContractBilling from '@common/models/orm/ContractBilling'
import LineItem from '@common/models/orm/LineItem'
import { APP_BILLING_API_PREFIX } from '@common/plugins/api'
import { createApiUrl } from '@common/plugins/helpers'
import { DEFAULT_CURRENCY } from '@common/models/BillingFactory'
import { v4 as uuidv4 } from 'uuid'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByContractId:
      (state, { query }) =>
      (contractId) =>
        query().whereId(contractId).withAll().first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById({ commit, dispatch }, id) {
      const uri = createApiUrl(
        APP_BILLING_API_PREFIX.slice(1),
        ContractBilling.endpoint,
        id,
        '/billing',
      )

      const { data } = await this.$axios.get(uri)

      const contractBilling = data.data

      contractBilling.currency = DEFAULT_CURRENCY

      // delete old LineItems to prevent memory leak
      await this.$db()
        .model(LineItem)
        .delete((r) => r.contractId === id)

      contractBilling.items = contractBilling.items.map((item) => ({
        ...item,
        id: uuidv4(),
        contractId: id,
        currency: DEFAULT_CURRENCY,
      }))

      // set up contractBilling foreign key
      contractBilling.itemIds = contractBilling.items.map((i) => i.id)

      await this.$db()
        .model(ContractBilling)
        .insertOrUpdate({ data: contractBilling })
    },
  },
}
