import { required, minLength, maxLength, url } from 'vuelidate/lib/validators'
import { MODIFIER_CAROUSEL } from '@common/constants/chat-element-modifiers'

import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementCarousel extends Model {
  static className = 'ChatElementCarousel'

  static entity = 'carousel'
  static label = 'general.chat_element_carousel'
  static direction = Model.INTERACTION_IN
  static active = true
  static formFields = ['name', 'button', 'items', 'required']

  static fields() {
    return {
      name: this.string(),
      button: this.string(''),
      items: this.attr([]),
      required: this.boolean(true),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonCarousel')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormCarousel')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormCarousel/ChatFormCarouselTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/carousel.svg')
  }

  static getAvailableModifiers() {
    return [MODIFIER_CAROUSEL]
  }

  static getFormValidations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        button: {
          maxLength: maxLength(24),
        },
        items: {
          required,
          minLength: minLength(1),
        },
      },
      variableId: {},
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
        button: {
          maxLength: maxLength(24),
        },
      },
    }
  }

  static getItemFormValidations() {
    return {
      form: {
        image: {
          url,
        },
        title: {
          required,
          maxLength: maxLength(24),
        },
        description: {
          maxLength: maxLength(512),
        },
        urlText: {
          maxLength: maxLength(24),
        },
        url: {
          url,
        },
        externalId: {
          maxLength: maxLength(96),
        },
      },
    }
  }

  props() {
    return {
      name: this.name,
      button: this.button,
      items: this.items,
    }
  }
}
