import stateSetters from '@common/plugins/state-setters'
import Model from '@common/models/orm/Story'
import ChatNode from '@common/models/orm/ChatNode'
import { createApiUrl } from '@common/plugins/helpers'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByProjectId:
      (state, { query }) =>
      (projectId) =>
        query()
          .where('projectId', (value) => value === projectId)
          .orderBy('updatedAt', 'desc')
          .withAll()
          .get(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async apiUpdateOrCreate({ dispatch }, formData) {
      const apiAction = formData.id ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(Model.entity, formData.id)
      const { data } = await apiAction(url, formData)

      if (!data) {
        return {}
      }

      const { [Model.entity]: entities } = await this.$db()
        .model(Model)
        .insertOrUpdate({
          data,
        })

      await this.$db()
        .model(StoryLinkTarget)
        .insertOrUpdate({
          data: {
            id: data.id,
            name: data.name,
          },
        })

      return entities.shift()
    },
    async fetchById({ dispatch }, id) {
      await dispatch('fetchByModelId', { Model, id }, { root: true })
    },
    async setCurrent({ commit }, id) {
      await commit('setCurrent', id)
    },
    /* async setCurrentTo({ state, commit, dispatch, getters, rootGetters }, id) {
      if (!state.data[id]) {
        await dispatch('fetchByModelId', { Model, id }, { root: true })
      }

      commit('setCurrent', id)

      const entity = getters.query().withAll().find(id)
      const Project = rootGetters['entities/projects/current']

      if (!Project || Project.id !== entity.projectId) {
        await dispatch('entities/projects/setCurrentTo', entity.projectId, {
          root: true,
        })
      }
    }, */
    async duplicate({ commit }, { id, name }) {
      const url = createApiUrl(Model.entity, id, 'duplicate')

      const { data } = await this.$axios.post(url, {
        name,
      })

      const entity = await Model.insertOrUpdate({
        data: data.data,
      })

      return entity
    },
    async fetchChatNodeData({ commit }, { id, node, endpoint, suffix = '' }) {
      const apiUrl = createApiUrl(
        Model.entity,
        id,
        'chat-node-types',
        node,
        endpoint,
      )

      const url = apiUrl + suffix

      const { data } = await this.$axios.get(url)

      return data
    },
    async uploadMediaFile({ commit }, { id, file }) {
      if (!file) {
        throw new Error('No file provided')
      }

      const url = createApiUrl(Model.entity, id, 'upload-image')
      const form = new FormData()

      form.append('file', file)

      const { data } = await this.$axios.post(url, form)

      return data
    },
    async uploadPrivateFile({ commit }, { id, file }) {
      if (!file) {
        throw new Error('No file provided')
      }

      const url = createApiUrl('projects', id, 'upload-file')
      const form = new FormData()

      form.append('file', file)
      form.append('private', true)

      const { data } = await this.$axios.post(url, form)

      return data
    },
    async updateStory({ dispatch, state }, payload) {
      const apiAction = payload.id ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(Model.entity, payload.id)

      delete payload.id

      const { data } = await apiAction(url, payload)

      if (!data) {
        return {}
      }

      this.$db()
        .model(Model)
        .update({
          where: data.id,
          data: {
            ...data,
          },
        })

      return data
    },
    async fetchGraphData({ commit }, payload) {
      const apiUrl = createApiUrl(Model.entity, payload.id, 'graph')

      const url = `${apiUrl}?status=${payload.mode ?? ''}`

      const { data } = await this.$axios.get(url)

      return data
    },

    async translate({ dispatch }, formData) {
      const url = createApiUrl(Model.entity, formData.id)

      const { data } = await this.$axios.$post(`${url}/translate`, formData)

      return data
    },

    async fetchTranslateNodes({ dispatch }, payload) {
      const url = createApiUrl(Model.entity, payload.storyId)

      const { data } = await this.$axios
        .get(
          `${url}/${payload.status}/translations/${payload.langId}/chat-nodes`,
          {
            expectList: true,
          },
        )
        .then(({ data }) => data)

      const chatNodes = data.chatNodes ?? []

      await this.$db().model(ChatNode).insertOrUpdate({
        data: chatNodes,
      })

      return chatNodes
    },
    async getTranslationByVid({ dispatch }, payload) {
      const url = createApiUrl(Model.entity, payload.storyId)
      const { status = 'draft', vid, langId } = payload

      const { data } = await this.$axios
        .get(`${url}/${status}/chat-nodes/${vid}/languages/${langId}`)
        .then(({ data }) => data)

      return data
    },
    async updateTranslateNodes({ dispatch }, payload) {
      const url = createApiUrl(Model.entity, payload.storyId)
      const { status = 'draft', vid, langId } = payload

      const { data } = await this.$axios
        .put(
          `${url}/${status}/chat-nodes/${vid}/languages/${langId}`,
          payload.data,
        )
        .then(({ data }) => data)

      await this.$db().model(ChatNode).insertOrUpdate({
        data: data.chatNode,
      })

      return data
    },
    async autoTranslateNode({ dispatch }, payload) {
      const url = createApiUrl(Model.entity, payload.storyId)
      const { status = 'draft', vid, langId } = payload

      const { data } = await this.$axios
        .post(`${url}/${status}/chat-nodes/${vid}/languages/${langId}`)
        .then(({ data }) => data)

      await this.$db().model(ChatNode).insertOrUpdate({
        data: data.chatNode,
      })

      return data
    },
  },
}
