import Vue from 'vue'
import VuelidateErrorExtractor from 'vuelidate-error-extractor'

import FormGroup from '@common/components/forms/FormGroup'
import FormWrapper from '@common/components/forms/FormWrapper'
import FormSummary from '@common/components/forms/FormSummary'

import English from '@/locale/en.json'
// todo: import German from '@/locale/de_CH.json'

const i18n = {
  en: { ...English },
}
// filter for translation keys with this prefix
const validationPrefix = 'form_validation.'
const messages = {}
const i18nAttributes = {}
// use only translation keys of type 'form.field_name.label'
const regex = /^form\.(\w*)\.label/

const toCamel = (s) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

for (const language in i18n) {
  messages[language] = {}
  // message contains { en: { form_validation.error_type: 'translated string with {vars}' }, de: ... }
  Object.keys(i18n[language])
    .filter((key) => key.indexOf(validationPrefix) === 0)
    .forEach((key) => (messages[language][key] = i18n[language][key]))
}

// i18nAttributes contains { fieldName: 'translation.key.for_the.$t_function' }
// field variable names are camelCased
Object.keys(i18n.en)
  .filter((key) => regex.exec(key) !== null)
  .forEach((key) => {
    const result = regex.exec(key)

    i18nAttributes[toCamel(result[1])] = key
  })

Vue.use(VuelidateErrorExtractor, {
  messages,
  i18n: 'form_validation',
  i18nAttributes,
})

Vue.component('FormGroup', FormGroup)
Vue.component('FormSummary', FormSummary)
Vue.component('FormWrapper', FormWrapper)
