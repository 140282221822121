<template>
  <FormWrapper class="form--story" :validator="$v.form" @submit="formSubmit">
    <slot />
    <ApiErrorSummary v-model="errors" />
    <FormSummary />

    <FormGroup name="name" :label="$t('form.title.label')">
      <b-input
        id="name"
        v-model.trim="form.name"
        v-autofocus
        :placeholder="$t('form.title.placeholder')"
        required
        maxlength="64"
        :has-counter="false"
        data-cy-id="story-form-name"
      />
    </FormGroup>

    <FormGroup
      v-if="showLanguagePicker"
      name="language"
      :label="$t('form.language.label')"
    >
      <b-field>
        <b-select
          id="language"
          v-model="form.languageId"
          :placeholder="$t('form.language.label')"
          icon="earth"
          expanded
          data-cy-id="story-form-language-select"
        >
          <option
            v-for="language in languageList"
            :key="language.id"
            :value="language.id"
            v-text="$t(language.name)"
          />
        </b-select>
      </b-field>
    </FormGroup>

    <FormGroup
      v-if="showGroupSelector && groupsAvailable"
      name="groups"
      :label="$t('form.group.label')"
    >
      <FilterableDropdown
        class="dropdown is-expanded"
        :placeholder="$t('form.group.dropdown.placeholder')"
        :lists="filterableGroups"
        :value="currentSelectedGroup"
        @input="onChangeGroup"
        @typedInput="onGroupInput"
      >
        <template #header>
          <div class="dropdown-header" role="li" @click="onAddNewGroup">
            <span v-if="newGroupNameAvailable">
              {{ $t('form.group.dropdown.header.create') }} -
              <span>{{ newGroupName }}</span></span
            >

            <span v-else v-t="'form.group.dropdown.header.initial'" />
          </div>
        </template>
      </FilterableDropdown>
    </FormGroup>

    <slot name="submit" v-bind="isLoadingClass">
      <div class="field">
        <div class="control">
          <b-button
            native-type="submit"
            type="is-primary"
            rounded
            :class="isLoadingClass"
          >
            {{ $t('cta.save') }}
          </b-button>
        </div>
      </div>
    </slot>
  </FormWrapper>
</template>

<script>
import Model from '@common/models/orm/Story'
import Project from '@common/models/orm/Project'
import Group from '@common/models/orm/Group'
import ApiFormMixin from '@common/mixins/ApiFormMixin'

export default {
  components: {
    FilterableDropdown: () =>
      import('@common/components/atomic/FilterableDropdown'),
  },
  mixins: [ApiFormMixin],
  props: {
    value: {
      type: [Model, Object],
      default: () => new Model(),
    },
    relationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      form: {
        id: '',
        vid: '',
        name: '',
        languageId: '',
        projectId: '',
        isActive: false,
      },
      groups: [],
      newGroupName: '',
      currentSelectedGroup: '',
    }
  },
  computed: {
    project() {
      return this.$store.$db().model(Project).getters('current')
    },
    languageList() {
      if (this.project) {
        return this.project.languages
      }

      return []
    },
    newGroupNameAvailable() {
      return this.newGroupName.length > 0
    },
    showLanguagePicker() {
      return this.languageList.length > 1
    },
    showGroupSelector() {
      return this.form.id === ''
    },
    groupsAvailable() {
      return Boolean(this.groups?.length)
    },
    filterableGroups() {
      return this.groups.map(({ id, name }) => {
        return {
          name,
          value: id,
        }
      })
    },
  },
  beforeMount() {
    this.form = this.value.getFormJson()
    const { form, value } = this

    form.projectId = form.projectId || this.relationId
    form.languageId = value.languageId || this.project.languages[0]?.id

    this.groups = [
      ...this.$store.$db().model(Group).getters('getByProjectId')(
        this.form.projectId,
      ),
    ]

    if (this.isNew) {
      if (!this.relationId) {
        throw new Error('Missing id to create entity!')
      }
    }
  },
  methods: {
    async onAddNewGroup() {
      if (this.newGroupNameAvailable === false) {
        return
      }

      try {
        await Group.dispatch('apiUpdateOrCreate', {
          groupId: '',
          name: this.newGroupName,
          items: [],
          projectId: this.form.projectId,
          entityType: 'story',
        })

        const groups = await Group.getters('getByProjectId')(
          this.form.projectId,
        )

        this.groups = [...groups]
      } catch (e) {
        throw new Error(e)
      }
    },
    onGroupInput(groupName) {
      this.newGroupName = groupName
    },
    onChangeGroup(groupId) {
      const group = this.groups.find((group) => group.id === groupId)

      if (!group) {
        return
      }

      this.$emit('groupChanged', {
        groupId: group.id,
        items: [...group.items],
        projectId: this.form.projectId,
      })
    },
  },
  validations: Model.getFormValidations(),
}
</script>

<style lang="scss" scoped>
.group-select {
  .group-list-item {
    .icon-ctn {
      background: white;
      border: 1px solid $blue-grey-200;
      border-radius: $radius;
      margin-right: $sp-16;
      padding: $nudge;

      .icon {
        color: $blue-500;
        display: block;
      }
    }
  }

  &::v-deep {
    .dropdown-item.is-active {
      background-color: $blue-100 !important;
    }

    .dropdown-item:not(.is-active):hover {
      background-color: rgba($blue-100, 0.5) !important;
    }

    .dropdown-trigger--chevron {
      color: $blue-500;
    }
  }
}

.dropdown-header {
  border-bottom: 1px solid $grey-200;
  padding: $sp-12;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $size-6;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background-color: $blue-100;
  }

  .button {
    height: unset;
    padding: $sp-4 $sp-12;

    .icon-size-s {
      margin-right: $nudge;
      color: $blue-500;
    }
  }
}
</style>
