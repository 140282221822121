import ErrorHandler from '@common/models/ErrorHandler'
import ApiErrorSummary from '@common/components/forms/ApiErrorSummary'
import { autofocus } from '@common/plugins/directives'

/**
 * Encapsulate creation of the store action to call.
 * (No need to make it a vm property)
 * @param {string} action
 * @param {Object} value
 * @param {Object} i18n
 * @param {Object} db The VuexORM database (this.$store.$db).
 * @returns {string}
 */
const getStoreActionByModel = (
  action = '',
  value = {},
  i18n = {},
  db = null,
) => {
  if (typeof action === 'string') {
    return action
  }

  if (!value) {
    throw new Error(i18n.t('md.error.fallback_error_message'))
  }

  const storeAction = 'apiUpdateOrCreate'

  if (typeof value.constructor.namespace === 'function') {
    const actualModel = db.model(value.constructor)

    return actualModel.namespace(storeAction)
  }

  if (!value.constructor.entity) {
    throw new Error(i18n.t('md.error.fallback_error_message'))
  }

  // value may be just an orm object
  return ['entities', value.constructor.entity, storeAction].join('/')
}

export default {
  directives: {
    autofocus,
  },
  components: {
    ApiErrorSummary,
  },
  data() {
    return {
      forceUpdateNotice: false,
      isAjaxPending: false,
      errors: [],
      form: {},
    }
  },
  computed: {
    isNew() {
      return !this.form.id
    },
    isLoadingClass() {
      return {
        'is-loading': this.isAjaxPending,
      }
    },
  },
  methods: {
    emitSuccess(data) {
      if (this.isNew && !this.forceUpdateNotice) {
        return this.$emit('entityCreated', data)
      }

      this.$emit('entityUpdated', data)
    },
    setAjaxPending(bool = true) {
      this.isAjaxPending = bool
    },
    catchErrorResponse(error) {
      const glitch = new ErrorHandler(error, this.$i18n)

      this.errors = glitch.getMessageArray()
    },
    isFormValid() {
      this.$v.$touch()
      this.errors = []

      return !this.$v.$invalid
    },
    async formSubmit(action) {
      if (!this.isFormValid()) {
        return
      }

      this.setAjaxPending()

      try {
        const db =
          typeof this.$store.$db === 'function' ? this.$store.$db() : {}

        action = getStoreActionByModel(action, this.value, this.$i18n, db)

        const entity = await this.$store.dispatch(action, this.form)

        this.emitSuccess(entity)
      } catch (error) {
        this.catchErrorResponse(error)
      }

      // fake backend validation, for better visual feedback
      setTimeout(() => {
        this.setAjaxPending(false)
        this.$v.$reset()
        this.$emit('closeForm')
      }, 200)
    },
  },
}
