<template>
  <b-field
    :label-for="name"
    :label="label"
    :custom-class="customClass"
    :type="type"
    :message="errorMessage || firstErrorMessage"
    :grouped="grouped"
    :expanded="expanded"
  >
    <template #label>
      <slot name="label" :clazz="labelClass">
        <span class="label" :class="labelClass">
          {{ label }}
          <small v-if="subLabel !== ''">({{ subLabel }})</small>
        </span>
        <slot name="description">
          <span v-if="description" class="help" v-html="description" />
        </slot>
      </slot>
    </template>

    <slot />
  </b-field>
</template>

<script>
import { singleErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  extends: singleErrorExtractorMixin,
  props: {
    errorMessage: {
      type: String,
      default: null,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    grouped: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    subLabel: {
      type: String,
      default: '',
    },
    expanded: {
      type: Boolean,
      default: false,
    },
    labelInvisible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    type() {
      if (this.hasErrors) {
        return 'is-danger'
      }

      if (this.isValid) {
        return 'is-success'
      }

      return ''
    },
    customClass() {
      let result = ''

      if (this.hasErrors) {
        result += ' has-text-danger'
      }

      if (this.isValid) {
        result += ' has-text-success'
      }

      return result.trim()
    },
    labelClass() {
      const classes = []

      if (this.hasErrors) {
        classes.push('has-text-danger')
      }

      if (this.isValid) {
        classes.push('has-text-success')
      }

      if (this.isRequired) {
        classes.push('is-required')
      }

      if (this.isOptional) {
        classes.push('is-optional')
      }

      if (this.labelInvisible) {
        classes.push('is-invisible')
      }

      return classes
    },
  },
}
</script>

<style lang="scss" scoped>
.field ::v-deep .label .help {
  font-size: $size-7;
}

.label small {
  font-size: $size-7;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 0;
}
</style>
