<template>
  <div class="app-sidebar">
    <nav class="app-sidebar--top-section" data-cy-id="app-sidebar">
      <a
        v-if="currentProject"
        class="app-sidebar--nav-link"
        :class="getActiveLinkClass(`/project/${currentProject.id}`, true)"
        :href="`/project/${currentProject.id}`"
        data-cy-id="app-sidebar-chatbot"
      >
        <AppSidebarNavItem
          :icon="chatbotIcon"
          :label="$t('general.module_nav_item.chatbot.label')"
          :is-open="isSidebarOpen"
        />
      </a>

      <a
        v-if="currentProject"
        class="app-sidebar--nav-link"
        :href="`/ai/${currentProject.id}`"
        :class="getActiveLinkClass(`/ai/${currentProject.id}`)"
        data-cy-id="app-sidebar-ai"
      >
        <AppSidebarNavItem
          :icon="aiIcon"
          :label="aiLabel"
          :tooltip="aiLabel"
          :is-open="isSidebarOpen"
        />

        <div class="deactivated" v-if="!aiEnabled">
          <DeactivatedIcon />
        </div>
      </a>

      <a
        v-if="currentProject"
        class="app-sidebar--nav-link"
        :class="getActiveLinkClass(`/robot/${currentProject.id}`)"
        :href="`/robot/${currentProject.id}`"
        data-cy-id="app-sidebar-robot"
      >
        <AppSidebarNavItem
          :icon="robotIcon"
          :label="robotLabel"
          :tooltip="robotLabel"
          :is-open="isSidebarOpen"
        />

        <div class="deactivated" v-if="!robotEnabled">
          <DeactivatedIcon />
        </div>
      </a>
    </nav>

    <aside
      class="app-sidebar--bottom-section"
      :class="{ 'extra-bottom-padding': isAppOutdated }"
    >
      <a
        v-if="currentProject"
        class="app-sidebar--nav-link"
        :class="getActiveLinkClass(`/project/${currentProject.id}/settings`)"
        :href="`/project/${currentProject.id}/settings`"
        data-cy-id="app-sidebar-settings"
      >
        <AppSidebarNavItem
          :icon="settingsIcon"
          :label="$t('settings.label')"
          :is-open="isSidebarOpen"
        />
      </a>

      <hr class="app-sidebar--divider" />

      <a class="app-sidebar--nav-link" href="#" @click.prevent="toggleSidebar">
        <AppSidebarNavItem
          :tooltip="$t('general.module_nav_item.sidebar_show.label')"
          :icon="toggleIcon"
          :label="$t('general.module_nav_item.sidebar_hide.label')"
          :is-open="isSidebarOpen"
          alternative-appearance-if-open
          data-cy-id="app-sidebar-toggle"
        />
      </a>
    </aside>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import Project from '@common/models/orm/Project'
import { PLAN_PRO } from '@common/models/BillingFactory'
import DeactivatedIcon from '~common/assets/inline/deactivated-minus-icon.svg'

const AppSidebarNavItem = () => import('@common/components/AppSidebarNavItem')
const MessageBubble = () => import('~common/assets/inline/messages-bubble.svg')
const Brain = () => import('~common/assets/inline/brain.svg')
const Robot = () => import('~common/assets/inline/robot-head.svg')
const Cog = () => import('~common/assets/inline/cog.svg')
const ArrowRight = () => import('~common/assets/inline/arrow-right.svg')

export default {
  components: {
    AppSidebarNavItem,
    DeactivatedIcon,
  },
  props: {
    currentProject: {
      type: [Project, Object],
      required: true,
    },
    plan: {
      type: String,
      default: PLAN_PRO,
    },
    isAppOutdated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isSidebarOpen: 'layout/isSidebarOpen',
    }),
    aiEnabled() {
      return this.currentProject.enabledFeatures?.includes('module_ai')
    },
    robotEnabled() {
      return this.currentProject.enabledFeatures?.includes('module_robot')
    },
    toggleIcon() {
      return ArrowRight
    },
    chatbotIcon() {
      return MessageBubble
    },
    settingsIcon() {
      return Cog
    },
    aiIcon() {
      return Brain
    },
    robotIcon() {
      return Robot
    },
    robotLabel() {
      return this.$t(`general.module_nav_item.robot.label`)
    },
    aiLabel() {
      return this.$t('general.module_nav_item.machine_learning.label')
    },
  },
  methods: {
    ...mapMutations({
      toggleSidebar: 'layout/toggleSidebar',
    }),
    getActiveLinkClass(path) {
      return {
        'nuxt-link-active': this.isRouteActive(path, true),
      }
    },
    isRouteActive(path, exact = false) {
      const { fullPath } = this.$route

      if (exact) {
        return fullPath === path
      }

      return fullPath?.startsWith(path)
    },
  },
}
</script>

<style lang="scss">
$disabled-icon-size: 14px;

.expanded .app-sidebar {
  align-items: flex-start;
}

.app-sidebar {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding-bottom: $baseline;
  grid-area: sidebar;
  min-height: calc(100vh - 60px);
  background: $white;

  &--top-section,
  &--bottom-section {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
  }

  &--top-section {
    padding-top: calc($fontbase / 2);
  }

  &--divider {
    background: $grey-300;
    width: calc(100% - 24px);
    margin: calc($baseline / 4) calc($baseline / 2);
  }

  &--nav-link {
    display: block;
    position: relative;
    width: 100%;
    padding: calc($baseline / 4) calc($baseline / 2);
    background: transparent;

    &.nuxt-link-active > * {
      color: $blue-500;
    }

    &:hover .sidebar-nav-item,
    &:focus .sidebar-nav-item {
      background: $grey-100;
    }

    &[disabled]:hover .sidebar-nav-item,
    &[disabled]:focus .sidebar-nav-item {
      background: transparent;
    }

    &[disabled] > * {
      color: $grey-400;
      cursor: not-allowed;
    }

    > .sidebar--tooltip .tooltip-trigger {
      display: flex;
      align-items: center;
      background: transparent;
      width: 100%;
      border-radius: $radius;
      color: $grey-700;
      font-size: $size-6;
      font-weight: $weight-semibold;
      cursor: pointer;
      height: $nudge * 10;
      padding: $nudge * 2;
      transition: all 0.2s ease-in-out;

      .is-expanded & {
        justify-content: flex-start;
      }
    }

    .deactivated {
      position: absolute;
      top: calc($sp-20 / 10);
      right: calc($sp-20 / 5) * 3;
      z-index: 2000;

      svg {
        height: $disabled-icon-size;
        width: $disabled-icon-size;
        color: $blue-500;
      }
    }
  }

  &--nav-label {
    margin-left: $sp-12;
  }

  a:hover .tooltip-trigger {
    background-color: $grey-100;
  }

  .nuxt-link-active .tooltip-trigger > * {
    color: $blue-500;
  }
}

.extra-bottom-padding {
  padding-bottom: 3rem;
}
</style>
