import stateSetters from '@common/plugins/state-setters'
import { createApiUrl } from '@common/plugins/helpers'

import Project from '@common/models/orm/Project'
import Model from '@/models/orm/SessionUser'

const defaultState = {
  totalUsers: 0,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    totalUsers: ({ totalUsers }) => totalUsers,
    getAll: (state, { query }) => query().withAll().get(),
    getByUserId:
      (state, { query }) =>
      (userId) =>
        query().whereId(userId).withAll().first(),
    getByProjectId:
      (state, { query }) =>
      (projectId) =>
        query().where('projectId', projectId).withAll().get(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByProjectId({ commit }, { id, options }) {
      const url = createApiUrl(Project.entity, id, Model.endpoint)

      const searchParams = new URLSearchParams()
      const offset = (options.page - 1) * options.limit
      const direction = options.order === 'desc' ? 'descending' : 'ascending'

      searchParams.append('limit', options.limit)
      searchParams.append('offset', offset)
      searchParams.append('order', `${options.sortBy}:${direction}`)

      const dateFilterKey = options.filter.length
        ? 'lastEngagementAt'
        : 'createdAt'

      if (options.from) {
        searchParams.append(`start-${dateFilterKey}`, options.from)
      }

      if (options.to) {
        searchParams.append(`end-${dateFilterKey}`, options.to)
      }

      options.filter.forEach((condition) => {
        searchParams.append(condition, true)
      })

      const { data, metadata } = await this.$axios
        .get(`${url}?${searchParams.toString()}`)
        .then(({ data }) => data)

      if (!data) {
        return
      }

      // use create to avoid building up a large list of sessions in memory
      await this.$db().model(Model).create({ data })

      commit('setTotalUsers', metadata.pagination.total)
    },
    async fetchByUserId({ commit }, { userId, id: projectId }) {
      const url = createApiUrl(
        Project.entity,
        projectId,
        Model.endpoint,
        userId,
      )

      const { data } = await this.$axios.get(url).then(({ data }) => data)

      if (!data) {
        return
      }

      await this.$db().model(Model).create({ data })
    },
  },
}
