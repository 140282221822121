<template>
  <header class="story-builder--header">
    <div class="left">
      <nuxt-link
        :to="projectDetailsRoute"
        class="sb-header--project-link"
        :disabled="!project"
        :title="$t('cta.back_to_name', { name: projectName })"
        data-cy-id="storybuilder-back-button"
      >
        <ArrowRightCircle aria-hidden="false" tabindex="-1" />
        <span
          class="sb-header--project-name ellipsis is-hidden-touch"
          v-text="projectName"
        />
      </nuxt-link>
      <div class="vertical-separator" v-if="story" />
      <StoryBuilderUpdateStoryButton
        v-if="story"
        :entity="story"
        @update="onUpdate"
      />
    </div>

    <h1 class="sb-header--company-icon">
      <AiaibotIcon />
      <span class="is-hidden-touch" v-text="$t('general.story_builder')" />
    </h1>

    <div class="right">
      <DemoProjectIndicator v-if="isProjectInDemoMode" />

      <p
        class="is-7 has-text-weight-semibold is-uppercase is-white sb-header--status"
        v-text="
          $t('general.property_value', {
            key: $t('general.status'),
            value: statusText,
          })
        "
      />
    </div>
  </header>
</template>

<script>
import { PROJECT_COLORS } from '@common/constants/project-colors'

import Story from '@common/models/orm/Story'
import Project from '@common/models/orm/Project'
import BillingFactory from '@common/models/BillingFactory'
import StoryBuilderUpdateStoryButton from '@/components/storyBuilder/StoryBuilderUpdateStoryButton'

import ArrowRightCircle from '~common/assets/inline/circle-icon/arrow-circle-right-flat.svg'
import AiaibotIcon from '~common/assets/inline/aiaibot-icon.svg'

const DemoProjectIndicator = () =>
  import('@common/components/DemoProjectIndicator')

export default {
  components: {
    AiaibotIcon,
    ArrowRightCircle,
    StoryBuilderUpdateStoryButton,
    DemoProjectIndicator,
  },
  props: {
    project: {
      type: [Object, Project],
      default: null,
    },
    story: {
      type: [Object, Story],
      default: null,
    },
  },
  computed: {
    projectName() {
      return this.project?.name ?? this.$tc('general.project', 1)
    },
    projectColor() {
      return this.project?.color ?? PROJECT_COLORS[0]
    },
    projectDetailsRoute() {
      return this.project?.detailsRoute ?? '#'
    },
    isProjectInDemoMode() {
      return BillingFactory.isTrialStatus(this.project?.billingStatus)
    },
    statusText() {
      return this.story?.isActive
        ? this.$t('md.active')
        : this.$t('md.inactive')
    },
  },
  methods: {
    onUpdate() {
      this.$emit('update')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~common/styles/_mixins';

.story-builder--header {
  height: $header-height;
  width: 100%;
  background-color: $blue-grey-800;
  display: grid;
  grid-template-columns: calc(11 / 24) * 100% calc(1 / 12) * 100% calc(11 / 24) * 100%;
  grid-column-gap: 0;
  align-items: stretch;
  position: relative;
  grid-area: header;

  @include desktop() {
    grid-template-columns: calc(5 / 12) * 100% calc(1 / 6) * 100% calc(5 / 12) * 100%;
  }

  &::before {
    position: absolute;
    content: '';
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: $fontbase;
    background: linear-gradient(
      rgba(34, 57, 81, 0.18) 0%,
      rgb(18, 39, 61) 100%
    );
  }

  &::v-deep svg {
    width: $baseline;
    height: $baseline;
  }

  .left {
    display: flex;
    width: 100%;
    justify-content: stretch;
  }

  .right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .vertical-separator {
      top: 0;
      margin: 0 $sp-12;
    }

    .sb-header--status {
      color: $white;
    }
  }

  .vertical-separator {
    display: block;
    content: '';
    background-color: $blue-grey-500;
    top: calc(($header-height - $baseline) / 2);
    height: $baseline;
    width: 1px;
    position: relative;
  }
}

.sb-header--project-link {
  padding: 0 calc($column-gap / 2);
  display: flex;
  align-items: center;
  color: $white;
  position: relative;
  font-weight: $weight-semibold;
  font-family: $title-family;
  line-height: 1;
  background-color: transparent;
  font-size: $fontbase;
  border: none;
  outline: none !important;
  overflow: hidden;

  svg {
    transition: all $duration-m ease-in-out;
    transform: rotate(180deg);
    width: $fontbase * 2;
    height: $fontbase * 2;
  }

  span {
    line-height: 2;

    &:first-of-type {
      margin-left: calc($column-gap / 3);
    }
  }

  &:hover {
    color: $white;

    svg {
      color: $teal;
    }
  }

  &:focus,
  &:active {
    color: $white;
    background-color: rgba($white, 0.2);
  }

  .project--color-indicator {
    display: block;
    content: '';
    width: 3px;
    height: $size-4;
    border-radius: 1px;
    margin-right: $nudge;
    flex-shrink: 0;
  }
}

.sb-header--company-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  position: relative;
  font-weight: $weight-semibold;
  font-family: $title-family;

  span {
    margin-left: calc($column-gap / 4);
  }
}

.sb-header--updated {
  text-align: right;
  font-size: $size-normal;
  color: rgba($white, 0.5);
  padding-right: calc($column-gap / 2);
  line-height: 1;

  span:last-child {
    display: inline-block;
  }
}

.sb-header--status {
  padding-right: calc($column-gap / 2);
}

.indicator--demo {
  margin-right: $sp-16;
}
</style>
