import { required, requiredIf } from 'vuelidate/lib/validators'
import DOMpurify from 'dompurify'
import { differsTo } from '@common/plugins/validators'
import {
  STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS,
  STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS,
} from '@common/constants/project-colors'
import { MODIFIER_LIVECHAT_AUTO_SUBMIT } from '@common/constants/chat-element-modifiers'
import Model from '@/models/chatElements/ChatElementBaseModel'

const FailIcon = () => import('~/assets/inline/cross-circle.svg')
const SuccessIcon = () => import('~/assets/inline/check-box.svg')
const NotAvailableIcon = () => import('~/assets/inline/sleep.svg')

export const PROVIDER_SLACK = 'slack'
export const PROVIDER_UNBLU = 'unblu'
export const PROVIDER_AIAIBOT_API = 'aiaibot-api'
export const PROVIDER_MICROSOFT = 'msteams'

export const SUPPORTED_PROVIDERS = [
  {
    name: PROVIDER_SLACK,
    icon: () => import('~/assets/illustrations/slack.svg'),
  },
  {
    name: PROVIDER_UNBLU,
    icon: () => import('~/assets/illustrations/unblu.svg'),
  },
  {
    name: PROVIDER_AIAIBOT_API,
    icon: () => import('~/assets/illustrations/aiaibot-api.svg'),
  },
  {
    name: PROVIDER_MICROSOFT,
    icon: () => import('~/assets/illustrations/ms-teams.svg'),
  },
]

// TODO similar getTitle thing as ChatElementMedia so we can show the integration in draghandle "Live-Chat: Unblu"

const VAR_TAG_RE = /\${([A-z0-9-]+)}/gi

const MS_DATA_VALIDATOR = ({ provider }) => provider === PROVIDER_MICROSOFT

export default class ChatElementLiveChat extends Model {
  static className = 'ChatElementLiveChat'

  static entity = 'live-chat'

  static label = 'general.chat_element_live_chat'

  static formFields = ['provider', 'description', 'tenantId', 'channel']

  static direction = Model.INTERACTION_EFFECT

  static fields() {
    return {
      provider: this.string(''),
      description: this.attr('', (value) => DOMpurify.sanitize(value)),
      tenantId: this.string(''),
      channel: this.string(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonLiveChat')
  }

  static getBalloonExplanation() {
    return () =>
      import('@/components/chatBalloons/ChatBalloonExplanationLiveChat')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormLiveChat')
  }

  static getTranslateForm() {
    return () =>
      import('@/components/chatForms/translations/ChatFormNoTranslate')
  }

  static getIcon() {
    return () => import('~/assets/inline/livechat.svg')
  }

  static getAvailableModifiers() {
    return [MODIFIER_LIVECHAT_AUTO_SUBMIT]
  }

  static getFormValidations() {
    return {
      form: {
        provider: {
          required,
          valid_provider: (v) => SUPPORTED_PROVIDERS.some((i) => i.name === v),
        },
        description: {
          required,
          empty: differsTo('<p></p>'),
        },
        tenantId: {
          required: requiredIf(MS_DATA_VALIDATOR),
        },
        channel: {
          required: requiredIf(MS_DATA_VALIDATOR),
        },
      },
      variableId: {},
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
      isRoot: false,
      hasCustomValidator: true,
    }
  }

  static customGraphValidator(node, nodes) {
    const { provider } = node.payload

    if (provider === PROVIDER_UNBLU) {
      const successWeight = 0
      const hasSuccessChild = nodes.find((i) => {
        return i.parentVid === node.vid && i.weight === successWeight
      })

      if (hasSuccessChild) {
        return 'graph_validation.unblu_no_children'
      }
    }

    return false
  }

  static getResponses(provider, i18n = {}) {
    return [
      {
        label: i18n.t('md.live_chat.handover_completed'),
        color: STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS.positive,
        icon: SuccessIcon,
      },
      {
        label: i18n.t('md.live_chat.handover_rejected'),
        color: STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS.negative,
        icon: FailIcon,
      },
      {
        label: i18n.t('md.live_chat.handover_not_possible'),
        color: STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS.neutral,
        icon: NotAvailableIcon,
      },
    ]
  }

  static getExplanations(provider, i18n = {}) {
    // NOTE generalize on next integration
    const successLocale =
      provider === PROVIDER_UNBLU
        ? 'md.live_chat.handover_completed.explanation_unblu'
        : 'md.live_chat.handover_completed.explanation'

    return [
      {
        text: i18n.t(successLocale),
        color: STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS.positive.color,
        backgroundColor:
          STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS.positive.backgroundColor,
      },
      {
        text: i18n.t('md.live_chat.handover_rejected.explanation'),
        color: STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS.negative.color,
        backgroundColor:
          STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS.negative.backgroundColor,
      },
      {
        text: i18n.t('md.live_chat.handover_not_possible.explanation'),
        color: STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS.neutral.color,
        backgroundColor:
          STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS.neutral.backgroundColor,
      },
    ]
  }

  static getUsedVariables({ description }) {
    const variables = description.matchAll(VAR_TAG_RE)

    return Array.from(variables).map(([, id]) => ({ id }))
  }

  props() {
    return {
      provider: SUPPORTED_PROVIDERS.find((i) => i.name === this.provider),
      description: DOMpurify.sanitize(this.description),
      tenantId: this.tenantId,
      channel: this.channel,
    }
  }
}
