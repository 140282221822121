import { createApiUrl } from '@common/plugins/helpers'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import Project from '@common/models/orm/Project'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    getAll: (state, { query }) => query().orderBy('name', 'ASC').get(),
    getByProjectId: (state, { query }) => {
      return (projectId, sortBy = 'name', order = 'asc') =>
        query().where('projectId', projectId).orderBy(sortBy, order).get()
    },
  },
  mutations: {},
  actions: {
    async fetchById({ dispatch }, id) {
      const url = createApiUrl(ProjectVariable.endpoint, id)
      const { data } = await this.$axios.get(url).then(({ data }) => data)

      if (!data) {
        return
      }

      const { [ProjectVariable.entity]: entities } = await this.$db()
        .model(ProjectVariable)
        .insertOrUpdate({
          data,
        })

      return entities
    },
    async deleteById({ dispatch }, id) {
      const url = createApiUrl(ProjectVariable.endpoint, id)

      await this.$axios.delete(url).then(({ data }) => data)
      await this.$db().model(ProjectVariable).delete(id)

      return id
    },
    async fetchByProjectId({ dispatch }, projectId) {
      const url = createApiUrl(
        Project.entity,
        projectId,
        ProjectVariable.endpoint,
      )

      const { data } = await this.$axios
        .get(url, { expectList: true })
        .then(({ data }) => data)

      if (!Array.isArray(data)) {
        return
      }

      await this.$db()
        .model(ProjectVariable)
        .create({
          data: data.map((variable) => {
            return {
              ...variable,
              projectId,
            }
          }),
        })
    },
    async apiUpdateOrCreate({ dispatch }, formData) {
      const apiAction = formData.id ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(ProjectVariable.endpoint, formData.id)
      const { data } = await apiAction(url, formData)

      if (!data) {
        return {}
      }

      const { [ProjectVariable.entity]: entities } = await this.$db()
        .model(ProjectVariable)
        .insertOrUpdate({
          data,
        })

      return entities.shift()
    },
  },
}
