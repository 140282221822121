import stateSetters from '@common/plugins/state-setters'
import { createApiUrl } from '@common/plugins/helpers'
import Workspace from '@common/models/orm/Workspace'
import Invitation from '@common/models/orm/Invitation'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByWorkspaceId:
      (state, { query }) =>
      (workspaceId) =>
        query().where('workspaceId', workspaceId).get(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async setCurrent({ commit }, id) {
      await commit('setCurrent', id)
    },
    async fetchOne({ state, commit, dispatch }, { workspaceId, invitationId }) {
      const url = createApiUrl('workspace-invitations', invitationId)

      try {
        const data = await this.$axios
          .$get(url)
          .then(({ data }) => data)
          .then(({ projects, role, user, ...data }) => {
            const projectIds = projects.map(({ id }) => id)

            return { ...user, ...data, role, projects: projectIds, workspaceId }
          })

        if (!data) {
          return
        }

        await this.$db().model(Invitation).insertOrUpdate({ data })
      } catch (error) {
        throw new Error(error)
      }
    },
    async fetchAll({ state, commit, dispatch }, id) {
      try {
        const url = createApiUrl(Workspace.entity, id, Invitation.entity)
        const { data } = await this.$axios.$get(url, {
          expectList: true,
        })

        if (!Array.isArray(data)) {
          return
        }

        const pendingInvitations = data
          .filter(({ status }) => status === Invitation.STATUS_PENDING)
          .map(({ projects, role, user, ...data }) => {
            const projectIds = projects.map(({ id }) => id)

            return {
              ...user,
              ...data,
              role,
              projects: projectIds,
              workspaceId: id,
            }
          })

        await this.$db()
          .model(Invitation)
          .insertOrUpdate({ data: pendingInvitations })
      } catch (error) {
        throw new Error(error)
      }
    },
    async sendInvitation(
      { state, commit, dispatch },
      { workspaceId, email, role, projects = [] },
    ) {
      const url = createApiUrl(Workspace.entity, workspaceId, Invitation.entity)

      // NOTE: using try/catch on an axios call messes up the error (only preserves err.message?)
      const data = await this.$axios
        .$post(url, {
          email,
          role,
          projects,
        })
        .then(({ data }) => data)
        .then(({ projects, role, user, ...data }) => {
          const projectIds = projects.map(({ id }) => id)

          return { ...user, ...data, role, projects: projectIds, workspaceId }
        })

      await this.$db().model(Invitation).insert({ data })
    },
    async updatePermission(
      { state, commit, dispatch },
      { invitationId, workspaceId, role, projects = [] },
    ) {
      const url = createApiUrl('workspace-invitations', invitationId)

      try {
        const data = await this.$axios
          .$put(url, { role, projects })
          .then(({ data }) => data)
          .then(({ projects, role, user, ...data }) => {
            const projectIds = projects.map(({ id }) => id)

            return { ...user, ...data, role, projects: projectIds, workspaceId }
          })

        await this.$db().model(Invitation).update({
          where: invitationId,
          data,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async removeInvitation({ state, commit, dispatch }, { invitationId }) {
      const url = createApiUrl('workspace-invitations', invitationId)

      try {
        return await this.$axios.$delete(url)
      } catch (error) {
        throw new Error(error)
      }
    },
  },
}
