import { required, minLength } from 'vuelidate/lib/validators'
import { PLAN_EXPERT } from '@common/models/BillingFactory'
import {
  MODIFIER_WORKFLOW_CURRENT_SESSION_VARIABLES,
  MODIFIER_ROBOT_DISCLAIMER,
} from '@common/constants/chat-element-modifiers'
import Project from '@common/models/orm/Project'
import Workflow from '@/models/orm/Workflow'

import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementWorkflow extends Model {
  static className = 'ChatElementWorkflow'

  static entity = 'workflow'

  static label = 'general.chat_element_workflow'

  static formFields = ['id', 'name', 'paths']

  static direction = Model.INTERACTION_EFFECT

  static PATH_SUCCESS = 'success'
  static PATH_ERROR = 'error'
  static DEFAULT_PATH_NAME_SUCCESS = 'Workflow success'
  static DEFAULT_PATH_NAME_ERROR = 'Workflow error'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      paths: this.attr([]),
      workflowId: this.attr(''),
      isDirty: this.boolean(false),
      isNew: this.boolean(false),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonWorkflow')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormWorkflow')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormWorkflow/ChatFormWorkflow'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/robot-workflow.svg')
  }

  static getPlanAvailability() {
    return [PLAN_EXPERT]
  }

  static getAvailableModifiers() {
    return [
      MODIFIER_WORKFLOW_CURRENT_SESSION_VARIABLES,
      MODIFIER_ROBOT_DISCLAIMER,
    ]
  }

  static getFormValidations() {
    return {
      form: {
        id: {},
        name: { required },
        paths: { minLength: minLength(0) },
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static async onAfterSave(store, node) {
    const { storyId } = store.getters
    const { id: projectId } = this.$db().model(Project).getters('current')

    // refresh workflow db
    // TODO: refresh only if we are creating new workflow
    await this.$db()
      .model(Workflow)
      .dispatch('fetchByProjectId', { projectId, storyId })

    // // if creating a new workflow, set id
    // if (!node.payload.id) {
    //   const newNode = newNodes.find((i) => i.vid === node.vid)
    //
    //   if (!newNode) {
    //     return
    //   }
    //
    //   store.commit('updateNode', {
    //     vid: node.vid,
    //     payload: {
    //       ...node.payload,
    //       id: newNode.payload.id,
    //       isDirty: false, // race condition with afterAutoSave action
    //     },
    //   })
    // }
  }

  // custom override
  getFormJson() {
    const result = {}
    const json = this.$toJson()

    this.constructor.formFields.forEach((key) => {
      result[key] = json[key]
    })

    if (json.isDirty) {
      result.isDirty = true
    }

    // if we are creating a new workflow, omit id
    if (json.isNew) {
      delete result.id
    }

    return result
  }

  props({ $store }) {
    const { id, name, paths } = this

    // new workflow, we fake the data until autosave is done
    if (!id) {
      return {
        workflow: {
          id: 1,
          name,
        },
        paths,
        erroredPaths: [],
      }
    }

    const workflow = Workflow.getters('getById')(id) ?? {}

    // validate paths
    const pathIds = workflow?.paths?.map((i) => i.id) ?? []
    const erroredPaths = this.paths.filter((i) => !pathIds.includes(i.id))

    return {
      id,
      workflow,
      paths,
      erroredPaths,
    }
  }
}
