import { minValue, maxValue, integer } from 'vuelidate/lib/validators'
import Model from '@common/models/triggerBehaviours/TriggerBehaviourBaseModel'

export default class TriggerBehaviourEventModel extends Model {
  static DELAY_MIN_VALUE = 0

  static DELAY_VALUE_STEP = 1

  static DELAY_MAX_VALUE = 60 // seconds

  static formFields = [...super.formFields, 'event', 'delay']

  static getFieldsDefault() {
    return {
      ...super.getFieldsDefault(),
      event: '',
      delay: this.DELAY_MIN_VALUE,
      ghostMessagesDelaySeconds: 0,
      ghostMessagesTimePeriodHours: 0,
    }
  }

  static getFormValidations() {
    return {
      ...super.getFormValidations(),
      event: {},
      delay: {
        integer,
        minValue: minValue(this.DELAY_MIN_VALUE),
        maxValue: maxValue(this.DELAY_MAX_VALUE),
      },
    }
  }
}
