import stateSetters from '@common/plugins/state-setters'
import Story from '@common/models/orm/Story'
import { createApiUrl } from '@common/plugins/helpers'
import { toIsoDate } from '@common/plugins/datetime'
import KpiStat from '@/models/orm/KpiStat'
import KpiTrigger from '@/models/orm/KpiTrigger'
import KpiSurvey from '@/models/orm/KpiSurvey'
import KpiSurveyFeedback from '@/models/orm/KpiSurveyFeedback'
import KpiGoal from '@/models/orm/KpiGoal'

export const FILTER_FROM_PARAMETER = 'start-createdAt'
export const FILTER_TO_PARAMETER = 'end-createdAt'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getStats:
      (state, { query }) =>
      (storyId) =>
        query().where('storyId', storyId).get(),
    getActivations:
      (state, { query }) =>
      (storyId) =>
        query()
          .where('storyId', storyId)
          .where('type', KpiStat.TYPE_ACTIVATION)
          .get(),
    getEngagements:
      (state, { query }) =>
      (storyId) =>
        query()
          .where('storyId', storyId)
          .where('type', KpiStat.TYPE_ENGAGEMENT)
          .get(),
    getCompletions:
      (state, { query }) =>
      (storyId) =>
        query()
          .where('storyId', storyId)
          .where('type', KpiStat.TYPE_COMPLETION)
          .get(),
    getSurveys:
      (state, { query }) =>
      (storyId) =>
        KpiSurvey.query()
          .where('storyId', storyId)
          .with(KpiSurveyFeedback.entity)
          .get(),
    getTriggers:
      (state, { query }) =>
      (storyId) =>
        KpiTrigger.query().where('storyId', storyId).get(),
    getGoals:
      (state, { query }) =>
      (storyId) =>
        KpiGoal.query().where('storyId', storyId).get(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByStoryId(
      { commit },
      { storyId, filterFrom = null, filterTo = null },
    ) {
      const url = createApiUrl(Story.entity, storyId, 'kpi')

      const params = {}

      if (filterFrom) {
        params[FILTER_FROM_PARAMETER] = toIsoDate(filterFrom)
      }

      if (filterTo) {
        params[FILTER_TO_PARAMETER] = toIsoDate(filterTo)
      }

      try {
        const { data } = await this.$axios
          .get(url, { params })
          .then(({ data }) => data)
        const {
          activations = [],
          engagements = [],
          completions = [],
          goals = [],
          surveys = [],
          triggers = [],
        } = data

        await this.$db().model(KpiStat).deleteAll()
        await this.$db()
          .model(KpiStat)
          .insert({
            data: activations.map(({ date, ...activation }) => ({
              ...activation,
              date: Date.parse(date),
              storyId,
              type: KpiStat.TYPE_ACTIVATION,
            })),
          })

        await this.$db()
          .model(KpiStat)
          .insert({
            data: engagements.map(({ date, ...engagement }) => ({
              ...engagement,
              date: Date.parse(date),
              storyId,
              type: KpiStat.TYPE_ENGAGEMENT,
            })),
          })

        await this.$db()
          .model(KpiStat)
          .insert({
            data: completions.map(({ date, ...completion }) => ({
              ...completion,
              date: Date.parse(date),
              storyId,
              type: KpiStat.TYPE_COMPLETION,
            })),
          })

        await this.$db()
          .model(KpiSurvey)
          .create({
            data: surveys.map(({ surveyId, surveyName, surveyType }) => ({
              id: surveyId,
              name: surveyName,
              type: surveyType,
              storyId,
            })),
          })

        await this.$db()
          .model(KpiSurveyFeedback)
          .create({
            data: surveys.flatMap(({ surveyId, feedback }) =>
              feedback.map((f) => ({
                surveyId,
                storyId,
                ...f,
              })),
            ),
          })

        await this.$db()
          .model(KpiTrigger)
          .create({
            data: triggers.map(
              ({
                id: triggerId,
                name,
                count,
                behaviourType,
                urlPatterns = [],
              }) => {
                const path = urlPatterns
                  .map(({ pattern }) => pattern.value)
                  .join('/')

                return {
                  id: triggerId,
                  name,
                  count,
                  type: behaviourType,
                  url: path,
                  storyId,
                }
              },
            ),
          })

        await this.$db()
          .model(KpiGoal)
          .create({
            data: goals.map((goal) => ({
              ...goal,
              storyId,
            })),
          })
      } catch (err) {
        throw err
      }
    },
  },
}
