<template>
  <div class="error-grid col-x">
    <div class="illustration flex-hv-centered">
      <div :class="animate && 'error-message--illustration'">
        <slot name="illustration">
          <Bottle aria-hidden="true" />
        </slot>
      </div>
    </div>

    <div class="content">
      <h1 class="title is-spaced has-text-white" v-html="title" />
      <p
        v-if="hasSubtitle"
        class="subtitle is-2 has-text-white"
        v-html="subtitle"
      />
      <p class="error--message has-text-white" v-html="message" />
      <br />
      <div class="field">
        <a
          v-if="showCallToAction"
          class="button is-secondary error-link error-link--cta"
          :href="callToActionLink"
        >
          <template v-if="callToActionIconEnabled">
            <ArrowRightCircle
              aria-hidden="true"
              class="icon-size-m mirror-horizontal"
            />
            &nbsp;
          </template>
          {{ callToActionText }}
        </a>

        <a
          v-if="showSupportButton"
          :href="supportLink"
          target="_blank"
          rel="noopener"
          class="button is-primary is-inverted is-outlined"
          v-text="supportText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Bottle from '~common/assets/illustrations/bottle.svg'
import ArrowRightCircle from '~common/assets/inline/circle-icon/arrow-circle-right-flat.svg'

export default {
  components: {
    Bottle,
    ArrowRightCircle,
  },
  props: {
    title: {
      type: [String, Number],
      required: true,
    },
    subtitle: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      required: true,
    },
    callToActionText: {
      type: String,
      default: null,
    },
    callToActionLink: {
      type: String,
      default: '/',
    },
    callToActionIconEnabled: {
      type: Boolean,
      default: true,
    },
    supportText: {
      type: String,
      default: null,
    },
    supportLink: {
      type: String,
      default: null,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasSubtitle() {
      return this.subtitle && this.subtitle.trim().length > 0
    },
    showCallToAction() {
      return this.callToActionText && this.callToActionText.trim().length > 0
    },
    showSupportButton() {
      return this.supportText && this.supportText.trim().length > 0
    },
  },
}
</script>

<style lang="scss">
.error-grid {
  .title {
    font-size: 100px;
    line-height: 1;
    letter-spacing: 0.63px;
    text-shadow: rgba(0, 0, 0, 0.08) 0 2px 11px;
  }

  .error-link--cta {
    margin-right: 1em;
  }

  .content {
    p {
      &.error--message {
        font-family: $title-family;

        a {
          color: $white;

          &:focus,
          &:hover {
            color: $white;
          }
        }
      }
    }
  }

  .mirror-horizontal {
    transform: rotate(180deg);
  }

  .error-message--illustration {
    animation: illustration 10s infinite;
  }

  @media screen and (min-width: $tablet - 1px) {
    display: grid;
    grid-template-columns: 3fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: '.' '.';
    grid-column-gap: $column-gap;
    width: 100%;
    max-width: calc($fullhd / 12) * 8;

    .illustration {
      order: 1;
    }
  }
}

@keyframes illustration {
  0% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(-4deg);
  }
}
</style>
