import { required } from 'vuelidate/lib/validators'
import DOMpurify from 'dompurify'
import { differsTo } from '@common/plugins/validators'
import {
  MODIFIER_HIDDEN_FOR_MESSAGING_API,
  MODIFIER_READ_TIME,
  MODIFIER_SESSION_ONCE,
  MODIFIER_VISIBLE_ONLY_FOR_MESSAGING_API,
  MODIFIER_TIME_BASED_CONTENT,
} from '@common/constants/chat-element-modifiers'

import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import Model from '@/models/chatElements/ChatElementBaseModel'

const VAR_TAG_RE = /\${([A-z0-9-]+)}/gi

export default class ChatElementGhostMessage extends Model {
  static className = 'ChatElementGhostMessage'

  static entity = 'ghost-message'

  static label = 'Ghost Message'

  static formFields = ['content']

  static fields() {
    // added sip to the default value
    /* eslint-disable no-useless-escape */
    const ALLOWED_URI_REGEXP =
      /^(?:(?:(?:f|ht)tps?|sip|mailto|tel|callto|cid|xmpp|xxx):|[^a-z]|[a-z+.\-]+(?:[^a-z+.\-:]|$))/i

    return {
      content: this.attr('', (value) =>
        DOMpurify.sanitize(value, {
          ADD_ATTR: ['target'],
          ALLOWED_URI_REGEXP,
        }),
      ),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonText')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormGhostMessage')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormGhostMessage/ChatFormGhostMessageTranslation'
      )
  }

  static getIcon() {
    return () => import('~/assets/inline/ghost-message.svg')
  }

  static getAvailableModifiers() {
    return [
      MODIFIER_READ_TIME,
      MODIFIER_SESSION_ONCE,
      MODIFIER_VISIBLE_ONLY_FOR_MESSAGING_API,
      MODIFIER_HIDDEN_FOR_MESSAGING_API,
      MODIFIER_TIME_BASED_CONTENT,
    ]
  }

  static getFormValidations() {
    return {
      form: {
        content: {
          required,
          empty: differsTo('<p></p>'),
        },
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        content: {
          required,
          empty: differsTo('<p></p>'),
        },
      },
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
      allowOtherWidgetInBetween: false,
    }
  }

  static getUsedVariables({ content }) {
    const variables = content.matchAll(VAR_TAG_RE)

    return Array.from(variables).map(([, id]) => ({ id }))
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      storyLinkTargets,
      variables,
      value: DOMpurify.sanitize(this.content),
    }
  }
}
