import { Database, install } from '@vuex-orm/core'
import stateSetters from '@common/plugins/state-setters'
import stateGetters from '@common/plugins/state-getters'
import * as modules from '@/modules'
import * as models from '@/models/orm'

const database = new Database()

Object.keys(models).forEach((key) => {
  const keyLowerCase = key.charAt(0).toLowerCase() + key.slice(1)

  if (Object.keys(modules).includes(keyLowerCase)) {
    // eslint-disable-next-line import/namespace
    database.register(models[key], modules[keyLowerCase])
  } else {
    // eslint-disable-next-line import/namespace
    database.register(models[key])
  }
})

export const plugins = [install(database)]

export const defaultState = {}

export const state = () => defaultState

export const mutations = {
  ...stateSetters(defaultState),
}

export const getters = {
  ...stateGetters(defaultState),
}

export const actions = {
  async fetchByModelId({ dispatch }, { Model, id, apiPrefix = '' }) {
    try {
      const { data } = await this.$axios
        .get(`${apiPrefix}/${Model.endpoint || Model.entity}/${id}`)
        .then(({ data }) => data)

      await dispatch('entities/insertOrUpdate', {
        entity: Model.entity,
        data,
      })
    } catch (error) {
      throw new Error(error)
    }
  },
}
