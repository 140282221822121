import stateSetters from '@common/plugins/state-setters'
import Order from '@common/models/orm/Order'
import LineItem from '@common/models/orm/LineItem'
import Contract from '@common/models/orm/Contract'
import { APP_BILLING_API_PREFIX } from '@common/plugins/api'
import { createApiUrl } from '@common/plugins/helpers'
import { v4 as uuidv4 } from 'uuid'
import { DEFAULT_CURRENCY } from '@common/models/BillingFactory'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getById:
      (state, { query }) =>
      (id) =>
        query().whereId(id).withAll().first(),
    getByContractId:
      (state, { query }) =>
      (contractId, preview = false) =>
        query().where({ contractId, preview }).withAll().first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById({ commit, dispatch }, id) {
      await dispatch(
        'fetchByModelId',
        {
          Order,
          id,
          apiPrefix: APP_BILLING_API_PREFIX,
        },
        {
          root: true,
        },
      )

      commit('setCurrent', id)
    },
    async fetchPreview({ commit, dispatch }, { id, plan }) {
      const uri = createApiUrl(
        APP_BILLING_API_PREFIX.slice(1),
        Order.endpoint,
        id,
        'buy-preview',
      )

      const { data } = await this.$axios.post(uri, { plan })

      const order = data.data

      order.id = uuidv4()
      order.contractId = id
      order.preview = true
      order.currency = DEFAULT_CURRENCY

      order.items = order.items.map((item) => ({
        ...item,
        id: uuidv4(),
        contractId: id,
        orderId: order.id,
        currency: DEFAULT_CURRENCY,
      }))

      // set up order foreign key
      order.itemIds = order.items.map((i) => i.id)

      // delete all orders and line items
      // NOTE probably should be more specific in the future, deleteByCondition?
      await Promise.all([
        this.$db().model(Order).deleteAll(),
        this.$db().model(LineItem).deleteAll(),
      ])

      await this.$db().model(Order).insertOrUpdate({ data: order })

      // get entry withAll relationships
      const insertedData = this.$db().model(Order).getters('getById')(order.id)

      return insertedData
    },
    async confirmOrder({ commit, dispatch }, { id, plan }) {
      const uri = createApiUrl(
        APP_BILLING_API_PREFIX.slice(1),
        Order.endpoint,
        id,
        'buy',
      )

      const { data } = await this.$axios
        .post(uri, { plan })
        .then(({ data }) => data)

      await this.$db().model(Contract).insertOrUpdate({ data })
    },
  },
}
