import ModelFactoryBase from '@common/models/ModelFactoryBase'
import EventModel from '@common/models/triggerBehaviours/TriggerBehaviourEventModel'
import PageloadIcon from '~common/assets/inline/pageload-circle.svg'
import TimeoutIcon from '~common/assets/inline/timeout.svg'
import CustomEventIcon from '~common/assets/inline/custom-event.svg'

// TODO in future, all models will have a top-level delay property

const availableModels = [
  {
    entity: 'page-load',
    Model: EventModel,
    label: 'form.behaviour_type.page_load.label',
    help: 'md.behaviour_type.page_load.help',
    behaviour: EventModel.getFieldsDefault(),
    placeholder: '',
    icon: PageloadIcon,
    active: true,
  },
  {
    entity: 'timeout',
    Model: EventModel,
    label: 'form.behaviour_type.timeout.label',
    help: 'md.behaviour_type.timeout.help',
    behaviour: EventModel.getFieldsDefault(),
    placeholder: '',
    icon: TimeoutIcon,
    active: true,
  },
  {
    entity: 'event',
    Model: EventModel,
    label: 'form.behaviour_type.event.label',
    help: 'md.behaviour_type.event.help',
    behaviour: EventModel.getFieldsDefault(),
    placeholder: '',
    icon: CustomEventIcon,
    active: true,
  },
]

export default class TriggerBehaviourFactory extends ModelFactoryBase {
  static map = availableModels.filter((item) => item.active)
}
