import stateSetters from '@common/plugins/state-setters'
import SelfServiceToken from '@common/models/orm/SelfServiceToken'
import Contract from '@common/models/orm/Contract'
import { createApiUrl } from '@common/plugins/helpers'
import { APP_BILLING_API_PREFIX } from '@common/plugins/api'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getById:
      (state, { query }) =>
      (contractId) =>
        query().whereId(contractId).first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById({ state, commit, dispatch }, id) {
      const url = createApiUrl(
        APP_BILLING_API_PREFIX.slice(1),
        Contract.endpoint,
        id,
        'self-service-token',
      )

      try {
        const { data } = await this.$axios.get(url).then(({ data }) => data)

        await this.$db().model(SelfServiceToken).insertOrUpdate({
          data,
        })
      } catch (error) {
        throw error
      }

      commit('setCurrent', id)
    },
  },
}
