export default class TriggerBehaviourBaseModel {
  static formFields = ['open', 'openInFullscreen', 'fullscreenAllowToggle']

  static getFieldsDefault() {
    return {
      openInFullscreen: false,
      fullscreenAllowToggle: false,
      open: false,
    }
  }

  static getFormValidations() {
    return {}
  }
}
