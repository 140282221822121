const { colors } = require('tailwindcss/defaultTheme')

module.exports = {
  important: false,
  purge: false,
  theme: {
    colors: {
      black: '#000000',
      white: '#FFFFFF',
      grey: {
        100: '#F1F3F5',
        200: '#E8ECEF',
        300: '#DEE2E6',
        400: '#CED4DA',
        500: '#ADB5BD',
        600: '#868E96',
        700: '#495057',
        800: '#343A40',
        900: '#21252B',
      },
      red: {
        100: '#FFF5F5',
        200: '#FED7D7',
        300: '#FEB2B2',
        400: '#FC8181',
        500: '#F56565',
        600: '#E53E3E',
        700: '#C53030',
        800: '#9B2C2C',
        900: '#742A2A',
      },
      green: {
        100: '#F0FFF4',
        200: '#C6F6D5',
        300: '#9AE6B4',
        400: '#68D391',
        500: '#48BB78',
        600: '#38A169',
        700: '#2F855A',
        800: '#276749',
        900: '#22543D',
      },
      orange: {
        100: '#FFFAF0',
        200: '#FEEBC8',
        300: '#FBD38D',
        400: '#F6AD55',
        500: '#ED8936',
        600: '#DD6B20',
        700: '#C05621',
        800: '#9C4221',
        900: '#7B341E',
      },
      teal: {
        100: '#E4FBF5',
        200: '#B6F6E4',
        300: '#80EFCF',
        400: '#33E5B3',
        500: '#00E0A1',
        600: '#00CA91',
        700: '#00B280',
        800: '#00946A',
        900: '#006B4D',
      },
      'blue-grey': {
        100: '#F1F3F5',
        200: '#D0DBE7',
        300: '#A7BACD',
        400: '#829AB1',
        500: '#627D98',
        600: '#486581',
        700: '#334E68',
        800: '#243B53',
        900: '#102A43',
      },
      blue: {
        100: '#E6F0FC',
        200: '#BDDCFF',
        300: '#7ABBFF',
        400: '#3D9AFF',
        500: '#1A73E8', // brand blue
        600: '#1767D1',
        700: '#145AB6',
        800: '#104A96',
        900: '#0B346A',
      },
      yellow: colors.yellow,
      turquoise: colors.indigo, // renamed
      indigo: colors.indigo,
      purple: colors.purple,
    },
  },
}
