import { required, email as isEmail } from 'vuelidate/lib/validators'
import DOMpurify from 'dompurify'
import { differsTo } from '@common/plugins/validators'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import Model from '@/models/chatElements/ChatElementBaseModel'

const VAR_TAG_RE = /\${([A-z0-9-]+)}/gi

export default class ChatElementHandoverEmail extends Model {
  static className = 'ChatElementHandoverEmail'

  static entity = 'handover-email'

  static label = 'general.chat_element_handover_email'

  static formFields = ['description', 'email']

  static direction = Model.INTERACTION_EFFECT

  static fields() {
    return {
      description: this.attr('', (value) => DOMpurify.sanitize(value)),
      email: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonHandoverEmail')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormHandoverEmail')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormEmailHandover/ChatFormEmailHandoverTranslate.vue'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/email-action-send.svg')
  }

  static getFormValidations() {
    return {
      form: {
        description: {
          required,
          empty: differsTo('<p></p>'),
        },
        email: {
          required,
          email: isEmail,
        },
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
      isRoot: false,
    }
  }

  static getUsedVariables({ description }) {
    const variables = description.matchAll(VAR_TAG_RE)

    return Array.from(variables).map(([, id]) => ({ id }))
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()

    return {
      description: DOMpurify.sanitize(this.description),
      email: this.email,
      variables,
    }
  }
}
