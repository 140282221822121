import Model from '@common/models/orm/OrmCrudModel'

export default class KpiSurveyFeedback extends Model {
  static className = 'KpiSurveyFeedback'
  static entity = 'feedback'

  static PARTICIPANT_TYPE_PROMOTER = 'promoter'
  static PARTICIPANT_TYPE_DETRACTOR = 'detractor'
  static PARTICIPANT_TYPE_PASSIVES = 'passives'

  static NPS_MIN_VALUE = -100
  static NPS_MAX_VALUE = 100

  static primaryKey = ['storyId', 'surveyId', 'rating']

  static fields() {
    return {
      id: this.attr(''),
      storyId: this.attr(''),
      surveyId: this.attr(''),
      rating: this.number(0),
      respondents: this.number(0),
    }
  }

  get category() {
    if (this.rating >= 0 && this.rating <= 6) {
      return KpiSurveyFeedback.PARTICIPANT_TYPE_DETRACTOR
    }

    if (this.rating >= 7 && this.rating <= 8) {
      return KpiSurveyFeedback.PARTICIPANT_TYPE_PASSIVES
    }

    if (this.rating >= 9 && this.rating <= 10) {
      return KpiSurveyFeedback.PARTICIPANT_TYPE_PROMOTER
    }

    return ''
  }
}
