<template>
  <div class="story-builder">
    <StoryGraphHeader :project="project" :story="story" />
    <nuxt />
    <portal-target name="modals" />
  </div>
</template>

<script>
import UiNotifications from '@common/mixins/UiNotifications'

import Project from '@common/models/orm/Project'
import Story from '@common/models/orm/Story'
import StoryGraphHeader from '@/components/storyBuilder/StoryGraphHeader'

export default {
  components: {
    StoryGraphHeader,
  },
  mixins: [UiNotifications],
  middleware: ['app-init'],
  computed: {
    project() {
      return this.$store.$db().model(Project).getters('current')
    },
    story() {
      return this.$store.$db().model(Story).getters('current')
    },
  },
  head() {
    return {
      htmlAttrs: {
        class: 'hide-body-overflow',
      },
      bodyAttrs: {
        class: this.$route.query.grid ? 'grid-overlay' : '',
      },
    }
  },
}
</script>
