import {
  required,
  minValue,
  maxValue,
  requiredIf,
} from 'vuelidate/lib/validators'
import { differsTo, inArray } from '@common/plugins/validators'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import {
  MODIFIER_INPUT_LOCATION_SAMPLE_TEXT,
  MODIFIER_INPUT_CUSTOM_VALIDATION,
  MODIFIER_INPUT_EXAMPLES,
} from '@common/constants/chat-element-modifiers'
import Model from '@/models/chatElements/ChatElementBaseModel'

const PhoneIcon = () => import('~/assets/inline/phone.svg')
const TextIcon = () => import('~/assets/inline/text.svg')
const LocationIcon = () => import('~/assets/inline/location.svg')
const EmailIcon = () => import('~common/assets/inline/email.svg')
const CalendarIcon = () => import('~common/assets/inline/calendar.svg')

const TYPE_LOCATION = 'location'

const allowedInputTypes = [
  {
    label: 'form.input_type.text',
    key: 'text',
    desc: 'form.input_type.text.desc',
    maxLengthDisabled: false,
    icon: TextIcon,
    active: true,
    category: 'text',
  },
  {
    label: 'form.input_type.email',
    key: 'email',
    desc: 'form.input_type.email.desc',
    maxLengthDisabled: true,
    icon: EmailIcon,
    active: true,
    category: 'email',
  },
  {
    label: 'form.input_type.phone',
    key: 'phone',
    desc: 'form.input_type.phone.desc',
    maxLengthDisabled: true,
    icon: PhoneIcon,
    active: true,
    category: 'phone',
  },
  {
    label: 'form.input_type.datetime',
    key: 'datetime',
    desc: 'form.input_type.datetime.desc',
    icon: CalendarIcon,
    active: true,
    category: 'datetime',
  },
  {
    key: 'date',
    icon: CalendarIcon,
    active: false,
    category: 'datetime',
  },
  {
    key: 'date_range',
    icon: CalendarIcon,
    active: false,
    category: 'datetime',
  },
  {
    key: 'datetime_range',
    icon: CalendarIcon,
    active: false,
    category: 'datetime',
  },
  {
    label: 'form.input_type.location',
    desc: 'form.input_type.location.desc',
    key: TYPE_LOCATION,
    icon: LocationIcon,
    maxLengthDisabled: true,
    active: true,
    category: TYPE_LOCATION,
  },
]

const config = [
  {
    name: 'form.input_type.datetime.config.date',
    example: '22.06.2021',
    value: 'date',
  },
  {
    name: 'form.input_type.datetime.config.datetime',
    example: '22.06.2021 15:00',
    value: 'datetime',
  },
  {
    name: 'form.input_type.datetime.config.date_range',
    example: '22.06.2021 - 30.06.2021',
    value: 'date_range',
  },
  {
    name: 'form.input_type.datetime.config.datetime_range',
    example: '22.06.2021 15:00 - 16:30',
    value: 'datetime_range',
  },
]

const types = config.map(({ value }) => value)

const format = [
  {
    name: 'form.input_type.datetime.format.german',
    example: '22.06.2021 12:00',
    value: 'de-DE',
  },
  {
    name: 'form.input_type.datetime.format.english',
    example: '06/22/2021, 03:00 PM',
    value: 'en-US',
  },
]

const allowedCountryTypes = [
  {
    label: 'general.country.switzerland',
    value: 'ch',
  },
  {
    label: 'general.country.germany',
    value: 'de',
  },
  {
    label: 'general.country.austria',
    value: 'at',
  },
]

export default class ChatElementUserInput extends Model {
  static DEFAULT_MAX_LENGTH = 255
  static DEFAULT_LOCATION_GRANULARITY = 'exact'
  static TYPE_LOCATION = TYPE_LOCATION

  static DATETIME = 'datetime'

  static className = 'ChatElementUserInput'
  static entity = 'user-input'
  static label = 'general.chat_element_user_input'

  static formFields = [
    'autofill',
    'inputType',
    'maxLength',
    'required',
    'content',
    'locationGranularity',
    'locationCountry',
    'datetimeType',
    'datetimeFormat',
    'datetimeTimezone',
    'placeholder',
  ]

  static direction = Model.INTERACTION_IN

  static TYPE_TEXT = 'text'
  static TYPE_EMAIL = 'email'
  static TYPE_PHONE = 'phone'
  static TYPE_DATETIME = 'datetime'
  static TYPE_DATE = 'date'
  static TYPE_DATE_RANGE = 'date_range'
  static TYPE_DATETIME_RANGE = 'datetime_range'

  static LOCATION_GRANULARITY_EXACT = 'exact'
  static LOCATION_GRANULARITY_AREA = 'area'

  static fields() {
    return {
      autofill: this.attr(false),
      inputType: this.attr(''),
      maxLength: this.number(0),
      required: this.attr(true),
      content: this.attr(''),
      locationGranularity: this.attr(this.DEFAULT_LOCATION_GRANULARITY),
      locationCountry: this.attr(['ch']),
      datetimeType: this.attr(''),
      datetimeFormat: this.attr(''),
      datetimeTimezone: this.attr(''),
      placeholder: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonUserInput')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormUserInput')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormUserInput/ChatFormUserInputTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/typing.svg')
  }

  static getAvailableModifiers() {
    return [
      MODIFIER_INPUT_CUSTOM_VALIDATION,
      MODIFIER_INPUT_LOCATION_SAMPLE_TEXT,
      MODIFIER_INPUT_EXAMPLES,
    ]
  }

  static getFormValidations() {
    return {
      form: {
        inputType: {
          required,
          missingSelection: inArray(allowedInputTypes.map((item) => item.key)),
        },
        maxLength: {
          minValue: minValue(1),
          maxValue: maxValue(ChatElementUserInput.DEFAULT_MAX_LENGTH),
        },
        datetimeType: {
          required: requiredIf(({ inputType }) => types.includes(inputType)),
        },
        datetimeFormat: {
          required: requiredIf(({ inputType }) => types.includes(inputType)),
        },
        datetimeTimezone: {
          required: requiredIf(({ inputType }) => types.includes(inputType)),
        },
        autofill: {},
        content: {},
        locationCountry: {
          required: requiredIf(({ inputType }) => inputType === TYPE_LOCATION),
        },
        locationGranularity: {
          required: requiredIf(({ inputType }) => inputType === TYPE_LOCATION),
        },
      },
      variableId: {
        required,
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        content: {
          empty: differsTo('<p></p>'),
        },
      },
    }
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      inputType: this.inputType,
      maxLength: this.maxLength,
      required: this.required,
      autofill: this.autofill,
      content: this.content,
      datetimeType: this.datetimeType,
      datetimeFormat: this.datetimeFormat,
      datetimeTimezone: this.datetimeTimezone,
      storyLinkTargets,
      variables,
      placeholder: this.placeholder,
    }
  }

  static getActiveInputTypes() {
    return allowedInputTypes.filter(({ active }) => active)
  }

  static getInputTypes() {
    return allowedInputTypes
  }

  static getCountryTypes() {
    return allowedCountryTypes
  }

  static getDateTimeConfig() {
    return config
  }

  static getDateTimeFormat() {
    return format
  }

  static defaultType() {
    return allowedInputTypes[0]
  }
}
