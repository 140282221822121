<template>
  <component :is="wrapperComponent" :error="error">
    <ErrorMessage
      :title="$t('md.404.title')"
      :subtitle="$t('md.404.subtitle')"
      :message="$t('md.404.body')"
      :call-to-action-text="callToActionButtonText"
      :call-to-action-link="callToActionButtonTarget"
      :support-text="$t('cta.support')"
      :support-link="`mailto:${$t('general.support_email_address')}`"
    >
      <template #illustration>
        <Bottle aria-hidden="true" />
      </template>
    </ErrorMessage>
  </component>
</template>

<script>
import AuthenticatedError from '@common/components/error/AuthenticatedError'
import UnauthenticatedError from '@common/components/error/UnauthenticatedError'
import ErrorMessage from '@common/components/error/ErrorMessage'
import Bottle from '~common/assets/illustrations/bottle.svg'
import ArrowRightCircle from '~common/assets/inline/circle-icon/arrow-circle-right-flat.svg'

export default {
  components: {
    Bottle,
    ArrowRightCircle,
    AuthenticatedError,
    UnauthenticatedError,
    ErrorMessage,
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
    isAuthenticated: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wrapperComponent() {
      return this.isAuthenticated ? AuthenticatedError : UnauthenticatedError
    },
    callToActionButtonText() {
      return this.isAuthenticated
        ? this.$t('cta.back_to_dashboard')
        : this.$t('cta.back_to_login')
    },
    callToActionButtonTarget() {
      return this.isAuthenticated ? '/' : '/account/login/'
    },
  },
}
</script>
