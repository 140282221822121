import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5b5031be = () => interopDefault(import('../pages/browser-not-supported.vue' /* webpackChunkName: "pages/browser-not-supported" */))
const _4e0fb7bc = () => interopDefault(import('../pages/feature-flags/index.vue' /* webpackChunkName: "pages/feature-flags/index" */))
const _6b377de4 = () => interopDefault(import('../pages/account/email-change-confirmation.vue' /* webpackChunkName: "pages/account/email-change-confirmation" */))
const _36524489 = () => interopDefault(import('../pages/account/email-confirmation.vue' /* webpackChunkName: "pages/account/email-confirmation" */))
const _6c503628 = () => interopDefault(import('../pages/account/enable-tfa.vue' /* webpackChunkName: "pages/account/enable-tfa" */))
const _68045976 = () => interopDefault(import('../pages/account/login.vue' /* webpackChunkName: "pages/account/login" */))
const _34d04466 = () => interopDefault(import('../pages/account/logout.vue' /* webpackChunkName: "pages/account/logout" */))
const _39e72c5b = () => interopDefault(import('../pages/account/me.vue' /* webpackChunkName: "pages/account/me" */))
const _78ae1140 = () => interopDefault(import('../pages/account/password-reset.vue' /* webpackChunkName: "pages/account/password-reset" */))
const _705c4f44 = () => interopDefault(import('../pages/account/request-password-reset.vue' /* webpackChunkName: "pages/account/request-password-reset" */))
const _363e7b4a = () => interopDefault(import('../pages/account/signup.vue' /* webpackChunkName: "pages/account/signup" */))
const _4c0aad84 = () => interopDefault(import('../pages/account/workspace-invitation-confirmation.vue' /* webpackChunkName: "pages/account/workspace-invitation-confirmation" */))
const _1c4f8494 = () => interopDefault(import('../pages/project/_id/index.vue' /* webpackChunkName: "pages/project/_id/index" */))
const _51a2dea1 = () => interopDefault(import('../pages/story-builder-graph/_id/index.vue' /* webpackChunkName: "pages/story-builder-graph/_id/index" */))
const _7ceb4aa0 = () => interopDefault(import('../pages/story-builder/_id/index.vue' /* webpackChunkName: "pages/story-builder/_id/index" */))
const _31140b2c = () => interopDefault(import('../pages/webchat-session-handover/_id/index.vue' /* webpackChunkName: "pages/webchat-session-handover/_id/index" */))
const _33db7c72 = () => interopDefault(import('../pages/workspace/_id/index.vue' /* webpackChunkName: "pages/workspace/_id/index" */))
const _0a519ca8 = () => interopDefault(import('../pages/project/_id/sessions/index.vue' /* webpackChunkName: "pages/project/_id/sessions/index" */))
const _6b739322 = () => interopDefault(import('../pages/project/_id/settings.vue' /* webpackChunkName: "pages/project/_id/settings" */))
const _26931c24 = () => interopDefault(import('../pages/story/_id/kpi.vue' /* webpackChunkName: "pages/story/_id/kpi" */))
const _65151c19 = () => interopDefault(import('../pages/workspace/_id/invite.vue' /* webpackChunkName: "pages/workspace/_id/invite" */))
const _7c30a39a = () => interopDefault(import('../pages/workspace/_id/settings.vue' /* webpackChunkName: "pages/workspace/_id/settings" */))
const _78948582 = () => interopDefault(import('../pages/project/_id/sessions/_userId/index.vue' /* webpackChunkName: "pages/project/_id/sessions/_userId/index" */))
const _7b13981a = () => interopDefault(import('../pages/story-builder/_id/translate/_lang.vue' /* webpackChunkName: "pages/story-builder/_id/translate/_lang" */))
const _76c44b67 = () => interopDefault(import('../pages/workspace/_id/invitation/_uid.vue' /* webpackChunkName: "pages/workspace/_id/invitation/_uid" */))
const _4a7e1575 = () => interopDefault(import('../pages/workspace/_id/user/_uid.vue' /* webpackChunkName: "pages/workspace/_id/user/_uid" */))
const _cec67a7e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _485d69ee = () => interopDefault(import('../pages/__icons__.vue' /* webpackChunkName: "pages/__icons__" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/browser-not-supported",
    component: _5b5031be,
    name: "browser-not-supported"
  }, {
    path: "/feature-flags",
    component: _4e0fb7bc,
    name: "feature-flags"
  }, {
    path: "/account/email-change-confirmation",
    component: _6b377de4,
    name: "account-email-change-confirmation"
  }, {
    path: "/account/email-confirmation",
    component: _36524489,
    name: "account-email-confirmation"
  }, {
    path: "/account/enable-tfa",
    component: _6c503628,
    name: "account-enable-tfa"
  }, {
    path: "/account/login",
    component: _68045976,
    name: "account-login"
  }, {
    path: "/account/logout",
    component: _34d04466,
    name: "account-logout"
  }, {
    path: "/account/me",
    component: _39e72c5b,
    name: "account-me"
  }, {
    path: "/account/password-reset",
    component: _78ae1140,
    name: "account-password-reset"
  }, {
    path: "/account/request-password-reset",
    component: _705c4f44,
    name: "account-request-password-reset"
  }, {
    path: "/account/signup",
    component: _363e7b4a,
    name: "account-signup"
  }, {
    path: "/account/workspace-invitation-confirmation",
    component: _4c0aad84,
    name: "account-workspace-invitation-confirmation"
  }, {
    path: "/project/:id",
    component: _1c4f8494,
    name: "project-id"
  }, {
    path: "/story-builder-graph/:id",
    component: _51a2dea1,
    name: "story-builder-graph-id"
  }, {
    path: "/story-builder/:id",
    component: _7ceb4aa0,
    name: "story-builder-id"
  }, {
    path: "/webchat-session-handover/:id",
    component: _31140b2c,
    name: "webchat-session-handover-id"
  }, {
    path: "/workspace/:id",
    component: _33db7c72,
    name: "workspace-id"
  }, {
    path: "/project/:id?/sessions",
    component: _0a519ca8,
    name: "project-id-sessions"
  }, {
    path: "/project/:id?/settings",
    component: _6b739322,
    name: "project-id-settings"
  }, {
    path: "/story/:id?/kpi",
    component: _26931c24,
    name: "story-id-kpi"
  }, {
    path: "/workspace/:id?/invite",
    component: _65151c19,
    name: "workspace-id-invite"
  }, {
    path: "/workspace/:id?/settings",
    component: _7c30a39a,
    name: "workspace-id-settings"
  }, {
    path: "/project/:id?/sessions/:userId",
    component: _78948582,
    name: "project-id-sessions-userId"
  }, {
    path: "/story-builder/:id?/translate/:lang",
    component: _7b13981a,
    name: "story-builder-id-translate-lang"
  }, {
    path: "/workspace/:id?/invitation/:uid?",
    component: _76c44b67,
    name: "workspace-id-invitation-uid"
  }, {
    path: "/workspace/:id?/user/:uid?",
    component: _4a7e1575,
    name: "workspace-id-user-uid"
  }, {
    path: "/",
    component: _cec67a7e,
    name: "index"
  }, {
    path: "/:_icons__",
    component: _485d69ee,
    name: "_icons__"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
