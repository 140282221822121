<template>
  <div v-if="value.length > 0">
    <b-notification has-icon role="alert" type="is-danger">
      <p v-for="(error, index) in value" :key="index" v-text="error" />
    </b-notification>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
}
</script>
