import { toLower } from '@common/plugins/helpers'
import Model from '@common/models/orm/OrmCrudModel'

export default class KpiTrigger extends Model {
  static className = 'KpiTrigger'
  static entity = 'kpi-trigger'
  static primaryKey = ['id', 'storyId']

  static fields() {
    return {
      id: this.attr(''),
      name: this.string(''),
      count: this.number(0),
      url: this.string(''),
      type: this.string('', (value) => toLower(value)),
      storyId: this.attr(''),
      ...super.fields(),
    }
  }
}
