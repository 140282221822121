import stateSetters from '@common/plugins/state-setters'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    getByStoryId:
      (state, { query }) =>
      (storyId) => {
        return query().where('storyId', storyId).get()
      },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {},
}
