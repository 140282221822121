import Model from '@common/models/orm/OrmCrudModel'
import PaymentBearer from '@common/models/orm/PaymentBearer'
import BillingFactory, {
  STATUS_NORMAL,
  STATUS_INACTIVE,
  STATUS_TRIAL,
} from '@common/models/BillingFactory'

export default class Contract extends Model {
  static className = 'Contract'

  static entity = 'billing-contracts'

  static endpoint = 'contracts'

  static fields() {
    return {
      id: this.string(''),
      plan: this.string(''),
      status: this.string(''),
      nextBillingDate: this.string(''),
      trialEndDate: this.string(''),
      paymentBearer: this.hasOne(PaymentBearer, 'id'),
    }
  }

  static beforeCreate(model) {
    if (!model.plan || !BillingFactory.getPlan(model.plan)) {
      model.plan = BillingFactory.getDefaultPlan().entity
    }
  }

  static beforeUpdate(model) {
    if (!model.plan || !BillingFactory.getPlan(model.plan)) {
      model.plan = BillingFactory.getDefaultPlan().entity
    }
  }

  get isActive() {
    return this.status === STATUS_NORMAL
  }

  get isTrial() {
    return this.status === STATUS_TRIAL
  }

  get isInactive() {
    return this.status === STATUS_INACTIVE
  }

  static getDefaultPlan() {
    return BillingFactory.getDefaultPlan()
  }

  static getDefaultStatus() {
    return BillingFactory.getDefaultStatus()
  }
}
