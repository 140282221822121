import {
  APP_PUBLIC_API_PREFIX,
  APP_BILLING_API_PREFIX,
} from '@common/plugins/api'
import { NotificationProgrammatic as Notification } from 'buefy'

export default function ({ $axios, app }) {
  const { $cfg } = app

  const silentResponse = {}

  const {
    PUBLIC_API_PREFIX = '/public/v1',
    BILLING_API_PREFIX = '/billing/v1',
    APP_API_PREFIX = '/app/v1',
    BILLING_API_PATH,
    BACKEND_API_PATH,
  } = $cfg

  $axios.onRequest(({ url, baseURL, ...config }) => {
    if (url.startsWith(APP_PUBLIC_API_PREFIX)) {
      url = url.replace(APP_PUBLIC_API_PREFIX, PUBLIC_API_PREFIX)
      baseURL = BACKEND_API_PATH
    } else if (url.startsWith(APP_BILLING_API_PREFIX)) {
      url = url.replace(APP_BILLING_API_PREFIX, BILLING_API_PREFIX)
      baseURL = BILLING_API_PATH
    } else {
      url = url.replace(/^\//, `${APP_API_PREFIX}/`)
      baseURL = BACKEND_API_PATH
    }

    if (config.expectList !== undefined) {
      silentResponse[baseURL + url] = []
    }

    return {
      ...config,
      url,
      baseURL,
    }
  })

  $axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error

      const { config } = error

      if (config) {
        const url = config.baseURL + config.url

        if (silentResponse[url] && config.method === 'get') {
          Notification.open({
            message: error.message ?? 'Error',
            type: 'is-danger',
            duration: 3000,
            'has-icon': true,
            'auto-close': true,
            position: 'is-bottom-right',
            class: 'float',
          })

          return {
            data: {
              data: silentResponse[url],
            },
          }
        }
      }

      return Promise.reject(error)
    },
  )

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status)

    if (code === 401) {
      app.store.commit('user-session/setIsTokenExpired', true)
      app.$auth.logout()
    }
  })
}
