import { theme } from '@common/tailwind.config'

export const PROJECT_COLORS = [
  '#ff6565',
  '#18ccb8',
  '#ffe665',
  '#7180d5',
  '#71d3fe',
  '#ff9365',
  '#ff809f',
  '#c05621',
  '#81cf4a',
  '#d571ce',
]

export const STORY_BUILDER_BUTTON_LIST_COLORS = [
  '#81cf4a',
  '#71d3fe',
  '#ff6565',
  '#ffe665',
  '#d571ce',
  '#7180d5',
]

export const STORY_BUILDER_BUTTON_LIST_POSITIVE = theme.colors.green['500']
export const STORY_BUILDER_BUTTON_LIST_NEGATIVE = theme.colors.red['500']
export const STORY_BUILDER_BUTTON_LIST_NEUTRAL = theme.colors.grey['500']

export const STORY_BUILDER_INTENT_UNMATCHED_COLOR =
  STORY_BUILDER_BUTTON_LIST_NEUTRAL

export const STORY_BUILDER_WORKFLOW_SUCCESS_COLOR =
  STORY_BUILDER_BUTTON_LIST_POSITIVE
export const STORY_BUILDER_WORKFLOW_ERROR_COLOR =
  STORY_BUILDER_BUTTON_LIST_NEUTRAL

export const STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS = {
  positive: STORY_BUILDER_BUTTON_LIST_POSITIVE,
  negative: STORY_BUILDER_BUTTON_LIST_NEGATIVE,
  neutral: STORY_BUILDER_BUTTON_LIST_NEUTRAL,
}

export const STORY_BUILDER_EXPLANATION_LIVE_CHAT_COLORS = {
  positive: {
    color: theme.colors.green['900'],
    backgroundColor: theme.colors.green['200'],
  },
  negative: {
    color: theme.colors.red['900'],
    backgroundColor: theme.colors.red['300'],
  },
  neutral: {
    color: theme.colors['blue-grey']['900'],
    backgroundColor: theme.colors.grey['100'],
  },
}
