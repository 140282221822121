import format from 'date-fns/format'
import {
  maxLength,
  maxValue,
  minLength,
  minValue,
  numeric,
  required,
} from 'vuelidate/lib/validators'
import Model from '@common/models/orm/OrmCrudModel'

export default class PaymentBearer extends Model {
  static className = 'PaymentBearer'

  static entity = 'billing-payment-bearers'

  static endpoint = 'payment-bearers'

  static TYPE_CREDIT_CARD = 'CreditCard'

  static formFields = [
    'id',
    'type',
    'cardType',
    'cardNumber',
    'cardExpiryMonth',
    'cardExpiryYear',
    'cardCvc',
    'cardHolder',
    'cardLast4Digit',
    'cardMaskedPan',
  ]

  static fields() {
    return {
      id: this.string(''),
      type: this.string(''),
      cardType: this.string(''),
      cardNumber: this.string(''),
      cardExpiryMonth: this.number(0),
      cardExpiryYear: this.number(0),
      cardCvc: this.string(''),
      cardHolder: this.string(''),
      cardLast4Digit: this.string(''),
      cardMaskedPan: this.string(''),
    }
  }

  static getFormValidations() {
    const twoDigitYear = format(new Date(), 'yy')

    return {
      form: {
        cardNumber: {
          required,
          minLength: minLength(4),
        },
        cardHolder: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(64),
        },
        cardExpiryYear: {
          required,
          numeric,
          minValue: minValue(twoDigitYear),
          maxValue: maxValue(twoDigitYear + 15),
          minLength: minLength(1),
          maxLength: maxLength(2),
        },
        cardExpiryMonth: {
          required,
          numeric,
          minValue: minValue(1),
          maxValue: maxValue(12),
          maxLength: maxLength(2),
        },
        cardCvc: {
          required,
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(9999), // amex uses 4 digit long cvd
          minLength: minLength(3),
          maxLength: maxLength(4),
        },
      },
    }
  }
}
