import stateSetters from '@common/plugins/state-setters'
import Project from '@common/models/orm/Project'
import Theme from '@common/models/orm/Theme'

const defaultState = {
  current: null,
}

const buildProjectThemeEndpoint = (id) =>
  ['', Project.entity, id, 'theme'].join('/')

const buildProjectThemeImageEndpoint = (id, kind) =>
  ['', Project.entity, id, `theme-${kind}`].join('/')

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByProjectId:
      (state, { query }) =>
      (projectId) =>
        query()
          .where('projectId', (value) => value === projectId)
          .first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByProjectId({ dispatch }, { id }) {
      const url = buildProjectThemeEndpoint(id)

      try {
        const { data } = await this.$axios.get(url).then(({ data }) => data)
        const defaultTheme = Theme.getDefaultTheme()

        data.font = data.font === '' ? defaultTheme.font : data.font
        data.fontSize =
          data.fontSize === '' ? defaultTheme.fontSize : data.fontSize

        await this.$db().model(Theme).insertOrUpdate({
          data,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async update(_, { projectId, ...theme }) {
      const url = buildProjectThemeEndpoint(projectId)
      const { data } = await this.$axios
        .put(url, theme)
        .then(({ data }) => data)

      await this.$db().model(Theme).insertOrUpdate({
        data,
      })
    },
    async uploadImage({ dispatch }, { projectId, image, kind }) {
      const url = buildProjectThemeImageEndpoint(projectId, kind)
      const formData = new FormData()

      formData.append(kind, image)

      await this.$axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
    async deleteImage({ dispatch }, { projectId, kind }) {
      const url = buildProjectThemeImageEndpoint(projectId, kind)

      await this.$axios.delete(url)
    },
  },
}
