import { maxLength, minLength, required } from 'vuelidate/lib/validators'
import TriggerBehaviourFactory from '@common/models/TriggerBehaviourFactory'
import LanguageFactory from '@common/models/LanguageFactory'
import Model from '@common/models/orm/OrmCrudModel'
import { inArray } from '@common/plugins/validators'

export const CONDITION_REFERENCED_FROM_STORY = 'referenced-from-story'
export const CONDITION_STORY_STARTED = 'started'
export const STORY_CONDITIONS = [
  CONDITION_STORY_STARTED,
  'ended',
  'not-started',
  'not-ended',
  CONDITION_REFERENCED_FROM_STORY,
]

export default class Trigger extends Model {
  static className = 'Trigger'
  static entity = 'triggers'

  static PATTERN_ALL = '*'

  static formFields = [
    'id',
    'name',
    'storyId',
    'domain',
    'path',
    'triggerLanguages',
    'isEnabled',
    'hasConditions',
    'hasQuery',
    'platformType',
    'behaviourType',
    'behaviour',
    'query',
    'position',
    'doNotAllowContinueSession',
    'ghostMessagesEnabled',
  ]

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      storyId: this.attr(''),
      domain: this.attr(''),
      path: this.attr(''),
      triggerLanguages: this.attr([]),
      isEnabled: this.boolean(true),
      hasConditions: this.boolean(false),
      hasQuery: this.boolean(false),
      platformType: this.attr('all'),
      behaviourType: this.attr(''),
      behaviour: this.attr({}),
      query: this.attr([]),
      position: this.attr(1),
      doNotAllowContinueSession: this.boolean(false),
      ghostMessagesEnabled: this.boolean(false),
      ...super.fields(),
    }
  }

  static getFormValidations() {
    return {
      form: {
        name: {
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
        storyId: {
          required,
        },
        path: {
          required,
        },
        triggerLanguages: {
          maxLength: maxLength(5),
          $each: {
            missingSelection: inArray(
              LanguageFactory.getAll().map((item) => item.langcode),
            ),
          },
        },
        behaviourType: {
          required,
          missingSelection: inArray(
            TriggerBehaviourFactory.getTypes().map((item) => item.entity),
          ),
        },
        behaviour: {
          required,
        },
        isEnabled: {
          required,
        },
        platformType: {
          required,
        },
      },
    }
  }

  getBehaviour() {
    return TriggerBehaviourFactory.getTypes().find(
      (item) => item.entity === this.behaviourType,
    )
  }
}
