import { Model } from '@vuex-orm/core'
import BillingFactory from '@common/models/BillingFactory'

export default class ChatElementBaseModel extends Model {
  static formFields = []

  static active = true

  static label = 'general.chat_element'

  static INTERACTION_OUT = 'outgoing'
  static INTERACTION_IN = 'incoming'
  static INTERACTION_EFFECT = 'effect'

  static direction = 'outgoing'

  static getFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      isRoot: true, // allow to be the first element
      canBeLast: true, // allow to be the last element
      mustBeLast: false, // no other element may follow
      canOnlyOccurOnce: false, // can only occur once in a path
      allowOtherWidgetInBetween: true, // Other widgets can be placed before and inbetween
    }
  }

  static getBalloon() {
    return undefined
  }

  static getBalloonExplanation() {
    return undefined
  }

  static getIcon() {
    return () => import('~common/assets/inline/paragraph-normal.svg')
  }

  static getForm() {
    return undefined
  }

  static getAvailableModifiers() {
    return []
  }

  static getPlanAvailability() {
    return BillingFactory.getPlans().map(({ entity }) => entity)
  }

  props(context = {}) {
    return {}
  }

  getFormJson() {
    const result = {}
    const json = this.$toJson()

    this.constructor.formFields.forEach((key) => {
      result[key] = json[key]
    })

    return result
  }

  /**
   * Return a custom title or default fallback
   * @param {Object} i18n
   * @returns {string}
   */
  getTitle(i18n) {
    if (this.title) {
      return this.title
    }

    return i18n.t(this.constructor.label)
  }

  get direction() {
    return this.constructor.direction
  }

  get isOutgoing() {
    return this.constructor.direction === this.constructor.INTERACTION_OUT
  }

  get isIncoming() {
    return this.constructor.direction === this.constructor.INTERACTION_IN
  }

  get isEffect() {
    return this.constructor.direction === this.constructor.INTERACTION_EFFECT
  }
}
