<template>
  <main class="error-page--unauthenticated is-flex">
    <div class="container is-fullhd flex-hv-centered">
      <slot :error="error" />
    </div>
  </main>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
$simple-header-height: 200px;
.error-page--unauthenticated {
  position: relative;
  height: calc(100vh - #{$footer-height} - #{$simple-header-height});
}
</style>
