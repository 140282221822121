import ModelFactoryBase from '@common/models/ModelFactoryBase'
import { theme } from '@common/tailwind.config'

export const PLAN_INACTIVE = 'inactive'
export const PLAN_PRO = 'pro' // Professional
export const PLAN_EXPERT = 'expert' // Enterprise

export const INCLUDED_MONTHLY_ACTIVE_USERS = 500
export const DEFAULT_CURRENCY = 'CHF'

const defaultPlan = PLAN_INACTIVE

const MODULE_CHATBOT = {
  label: 'general.module.chatbot',
  icon: () => import('~common/assets/inline/messages-bubble.svg'),
}

const MODULE_AI = {
  label: 'general.module.ai',
  icon: () => import('~common/assets/inline/brain.svg'),
}

const MODULE_ROBOT = {
  label: 'general.module.robot',
  icon: () => import('~common/assets/inline/robot-head.svg'),
}

const plans = [
  {
    entity: PLAN_INACTIVE,
    price: false,
    modules: [], // remove
    label: 'general.billing.status.inactive', // unused
    shortLabel: 'general.billing.status.inactive.tag', // unused
    labelColor: theme.colors.grey['500'],
    backgroundColor: theme.colors['blue-grey']['100'],
    active: true,
    selectable: false,
    dataFilterableByDayRanges: [7, 14, 28, 90, 365],
    dataFilterableByCustomRange: true,
    dataFilterableByEntireRange: false,
  },
  {
    entity: PLAN_PRO,
    price: 29,
    modules: [MODULE_CHATBOT], // remove
    label: 'general.billing.plan.pro', // unused
    shortLabel: 'general.billing.plan.pro.tag', // unused
    labelColor: theme.colors.blue['500'],
    backgroundColor: theme.colors.blue['200'],
    active: true,
    selectable: true,
    dataFilterableByDayRanges: [7, 14, 28, 90, 365],
    dataFilterableByCustomRange: true,
    dataFilterableByEntireRange: false,
  },
  {
    entity: PLAN_EXPERT,
    price: false,
    modules: [MODULE_CHATBOT, MODULE_AI, MODULE_ROBOT], // remove
    label: 'general.billing.plan.expert', // unused
    shortLabel: 'general.billing.plan.expert.tag', // unused
    labelColor: theme.colors.blue['500'],
    backgroundColor: theme.colors.blue['200'],
    active: true,
    selectable: true,
    dataFilterableByDayRanges: [7, 14, 28, 90, 365],
    dataFilterableByCustomRange: true,
    dataFilterableByEntireRange: false,
  },
]

export const FEATURES_PLAN_PRO = [
  'general.billing.plan.pro.features.unlimited_stories',
  'general.billing.plan.pro.features.integration_website',
  'general.billing.plan.pro.features.live_chat_slack',
  'general.billing.plan.pro.features.analytics',
  'general.billing.plan.pro.features.support_standard',
]

export const FEATURES_PLAN_EXPERT = [
  'general.billing.plan.expert.features.workflows',
  'general.billing.plan.expert.features.automation',
  'general.billing.plan.expert.features.salesforce',
  'general.billing.plan.expert.features.email',
  'general.billing.plan.expert.features.widget_robot',
  'general.billing.plan.expert.features.support_vip',
]

export const PLAN_FEATURES = {
  [PLAN_PRO]: FEATURES_PLAN_PRO,
  [PLAN_EXPERT]: FEATURES_PLAN_EXPERT,
}

// From lowest to highest (most featured) plan
const planHierarchy = [PLAN_PRO, PLAN_EXPERT]

export const STATUS_NORMAL = 'normal'
export const STATUS_TRIAL = 'trial'
export const STATUS_INACTIVE = 'inactive'
const defaultStatus = STATUS_TRIAL
const subscriptionStatuses = [
  {
    entity: STATUS_NORMAL,
    label: 'general.billing.status.default',
    shortLabel: 'general.billing.status.default.tag',
    labelColor: '',
    backgroundColor: '',
  },
  {
    entity: STATUS_TRIAL,
    label: 'general.billing.status.trial',
    shortLabel: 'general.billing.status.trial.tag',
    labelColor: theme.colors.grey['500'],
    backgroundColor: theme.colors['blue-grey']['100'],
  },
  {
    entity: STATUS_INACTIVE,
    label: 'general.billing.status.inactive',
    shortLabel: 'general.billing.status.inactive.tag',
    labelColor: theme.colors.grey['500'],
    backgroundColor: theme.colors['blue-grey']['100'],
  },
]

export default class BillingFactory extends ModelFactoryBase {
  static map = plans.filter((plan) => plan.active)

  static getPlan(value) {
    return this.getModelByType(value)
  }

  static getPlans() {
    return this.map
  }

  static getSelectablePlans() {
    return this.map.filter((plan) => plan.selectable)
  }

  static getDefaultPlan() {
    return this.getModelByType(defaultPlan)
  }

  static getDefaultStatus() {
    return this.getStatus(defaultStatus)
  }

  static getStatus(value) {
    return subscriptionStatuses.find((status) => status.entity === value)
  }

  static getStatuses() {
    return subscriptionStatuses
  }

  static isValidStatus(value) {
    return !!this.getStatus(value)
  }

  static isExceptionalStatus(status) {
    return status !== STATUS_NORMAL
  }

  static isTrialStatus(status) {
    return status === STATUS_TRIAL
  }

  static isInactiveStatus(status) {
    return status === STATUS_INACTIVE
  }

  static isPlanCancelable(status) {
    return status === STATUS_TRIAL || status === STATUS_NORMAL
  }

  static isPlanSubscribable(status) {
    return status === STATUS_INACTIVE || status === STATUS_TRIAL
  }

  static isPlanChangeableInSelfService(plan) {
    return plan !== PLAN_EXPERT
  }

  static isPlanHigherOrEqual(plan, planToCompareTo) {
    if (
      !BillingFactory.isValidType(plan) ||
      !BillingFactory.isValidType(planToCompareTo)
    ) {
      return false
    }

    if (BillingFactory.isHighestPlan(plan)) {
      return true
    }

    return planHierarchy.indexOf(plan) >= planHierarchy.indexOf(planToCompareTo)
  }

  static getHighestPlan() {
    const entity = planHierarchy[planHierarchy.length - 1]

    return BillingFactory.getPlan(entity)
  }

  static isHighestPlan(plan) {
    if (BillingFactory.isValidType(plan)) {
      const entity = typeof plan === 'string' ? plan : plan.entity

      return entity === BillingFactory.getHighestPlan().entity
    }

    return false
  }

  static isPaidStatus(status) {
    return status === STATUS_NORMAL
  }

  static hasCustomPricing(plan) {
    return plan === PLAN_EXPERT
  }
}
