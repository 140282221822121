import stateSetters from '@common/plugins/state-setters'
import StoryRevision from '@common/models/orm/StoryRevision'
import ChatNode from '@common/models/orm/ChatNode'
import { createApiUrl } from '@common/plugins/helpers'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {},
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByStoryRevisionId({ dispatch }, id) {
      const url = createApiUrl(StoryRevision.entity, id, ChatNode.entity)

      const { data } = await this.$axios
        .get(url, {
          expectList: true,
        })
        .then(({ data }) => data)

      await this.$db().model(ChatNode).insertOrUpdate({
        data,
      })

      return data
    },
  },
}
