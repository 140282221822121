export const DATA_SECURITY_DASHBOARD = 'data_security_dashboard'
export const MODULE_ROBOT = 'module_robot'
export const MODULE_AI = 'module_ai'
export const ENTITY_ROBOT = 'workflow'
export const TEXT_APPEARANCE_EFFECT_ALL_AT_ONCE = 'all-at-once'
export const TEXT_APPEARANCE_EFFECT_TYPING = 'typing'
export const TEXT_APPEARANCE_EFFECT_TYPING_FASTER = 'typing-faster'
export const STREAMING_NORMAL = 'streaming-normal'
export const STREAMING_FAST = 'streaming-fast'
export const STREAMING_INSTANT = 'streaming-instant'
export const STREAMING_NORMAL_SPEED = 30 // ms
export const STREAMING_FAST_SPEED = 15 // ms
export const STREAMING_INSTANT_SPEED = 0 // ms
export const KNOWLEDGE_BASE = 'knowledge_base'
export const EXAMPLES = 'examples'
export const MOVE_UP = 'up'
export const MOVE_DOWN = 'down'
export const MAX_TEXT_LENGTH = 144
export const CHAT_FORM_MAX_BUTTON_LENGTH = 45
export const CHAT_SURVEY_NPS = 'nps'
export const CHAT_SURVEY_CSAT = 'csat'
