<template>
  <b-notification v-if="hasErrors" type="is-danger">
    <p
      v-for="(error, index) in activeErrorMessages"
      :key="index"
      v-text="error"
    />
  </b-notification>
</template>

<script>
import { multiErrorExtractorMixin } from 'vuelidate-error-extractor'

export default {
  name: 'FormSummary',
  extends: multiErrorExtractorMixin,
}
</script>
