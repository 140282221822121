const contentType = 'application/json; charset=utf-8'

export default ({ app, $axios, $auth }, inject) => {
  const { $cfg } = app
  const { ROBOT_API_PATH } = $cfg
  const token = $auth.getToken('local')

  const config = {
    baseURL: ROBOT_API_PATH,
    headers: {
      'Content-Type': contentType,
      Accept: contentType,
      Authorization: token,
    },
  }

  const instance = $axios.create(config)

  // Set the AUTH token for all requests
  instance.interceptors.request.use(function (config) {
    config.headers.Authorization = $auth.getToken('local')

    return config
  })

  inject('robot', instance)
}
