import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { toLower, spaceTo } from '@common/plugins/helpers'
import { inArray } from '@common/plugins/validators'
import Model from './OrmCrudModel'

export default class Workspace extends Model {
  static className = 'Workspace'

  static entity = 'workspaces'

  static ROLE_OWNER = 'owner'
  static ROLE_MEMBER = 'member'
  static ROLE_PROJECT_MEMBER = 'project/member'

  static PROJECT_ACCESS_MODE_SPECIFIC = 'Specific'
  static PROJECT_ACCESS_MODE_ALL = 'All Projects'

  static TFA_ENABLED = 'enabled'
  static TFA_DISABLED = 'disabled'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      companySize: this.attr('').nullable(),
      role: this.attr(''),
      mandatoryTfa: this.attr(false),
      ...super.fields(),
    }
  }

  get isAdmin() {
    return this.role === Workspace.ROLE_OWNER
  }

  get isProjectCreationAllowed() {
    return (
      this.role === Workspace.ROLE_OWNER || this.role === Workspace.ROLE_MEMBER
    )
  }

  static getRoleValidation() {
    return {
      role: {
        required,
        missingSelection: inArray(
          Workspace.getRoles().map(({ value }) => toLower(value)),
        ),
      },
    }
  }

  static getFormValidations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
      },
    }
  }

  static getDefaultRole() {
    return Workspace.getRoles()[0]
  }

  static getRoles() {
    return [
      Workspace.ROLE_OWNER,
      Workspace.ROLE_MEMBER,
      Workspace.ROLE_PROJECT_MEMBER,
    ].map((value) => ({
      key: Workspace.normaliseRoleName(value),
      value,
    }))
  }

  static getProjectAccessModes() {
    return [
      Workspace.PROJECT_ACCESS_MODE_SPECIFIC,
      Workspace.PROJECT_ACCESS_MODE_ALL,
    ].map((value) => ({ key: toLower(spaceTo(value, '_')), value }))
  }

  static getAccessModeForRole(role) {
    if (role === Workspace.ROLE_OWNER || role === Workspace.ROLE_MEMBER) {
      return Workspace.PROJECT_ACCESS_MODE_ALL
    }

    return Workspace.PROJECT_ACCESS_MODE_SPECIFIC
  }

  static normaliseRoleName(role) {
    return toLower(role.replace(/\//g, '_'))
  }
}
