const DEFAULT_ERROR = 'Error'
const TYPE_ERROR = 'TypeError'
const REFERENCE_ERROR = 'ReferenceError'
const errorTypes = [DEFAULT_ERROR, TYPE_ERROR, REFERENCE_ERROR]

export default class ErrorHandler {
  name = DEFAULT_ERROR
  title = 'error.bad_request'
  messages = ['md.error.fallback_error_message']
  error = {}
  i18n = null

  /**
   * @param {Object} error
   * @param {Object} i18n
   */
  constructor(error = {}, i18n = {}) {
    this.error = error
    this.i18n = i18n

    if (errorTypes.includes(error.name)) {
      this.name = error.name
    }

    if (this.isNetworkError(true)) {
      this.parseApiResponse(error.response.data)

      return
    }

    if (this.isNetworkError()) {
      this.title = this.i18n.t('error.network_error')
      this.messages = [this.i18n.t('md.error.network_error')]

      return
    }

    this.title = this.i18n.t(this.title)
    this.messages = [this.i18n.t(this.messages)]
    // eslint-disable-next-line no-console
    console.error(error)
  }

  /**
   * Axios did get an error
   * @returns {boolean}
   */
  isNetworkError(withResponse = false) {
    if (!this.error.isAxiosError) {
      return false
    }

    if (withResponse) {
      return this.error.response !== undefined
    }

    return this.error.response === undefined
  }

  /**
   * @param error
   */
  parseApiResponse({ error = {} }) {
    const { errors = [] } = error

    this.title = this.toTranslatedString(error)

    if (errors.length === 0) {
      this.messages = [this.i18n.t(this.messages)]
    }

    if (errors.length > 0) {
      this.messages = errors.map((item) => this.toTranslatedString(item))
    }
  }

  /**
   *
   * @param message
   * @param reason
   * @param args {Object}
   * @returns {String}
   */
  toTranslatedString({ message, reason, args }) {
    if (reason) {
      // make sure to have an actual translation

      if (reason !== this.i18n.t(reason)) {
        if (args?.attribute) {
          const key = this.i18n.t(`form_field.${args.attribute}`)

          return this.i18n.t(reason, { ...args, attribute: this.i18n.t(key) })
        }

        return this.i18n.t(reason, args)
      }
    }

    if (message) {
      return message
    }

    return this.i18n.t('error.bad_request')
  }

  /**
   *
   * @returns {String[]}
   */
  getMessageArray() {
    return [this.title, ...this.messages]
  }

  /**
   * Format for output inside a p-tag
   * @returns {string}
   */
  getNotificationString() {
    let string = `<strong>${this.title}</strong>`

    string += this.messages.map((item) => '<br/>' + item).join('')

    return string
  }
}
