import Vue from 'vue'

import Model from '@common/models/orm/OrmCrudModel'
import CountryFactory from '@common/models/CountryFactory'
import CustomerValidations from '@common/models/orm/validations/CustomerValidations'

export default class Customer extends Model {
  static className = 'Customer'

  static entity = 'billing-customers'

  static endpoint = 'customers'

  static formFields = [
    'id',
    'firstName',
    'lastName',
    'companyName',
    'email',
    'vatId',
    'customerId',
    'addressLine1',
    'street',
    'city',
    'postalCode',
    'country',
  ]

  static fields() {
    return {
      id: this.string(''),
      firstName: this.string(''),
      lastName: this.string(''),
      companyName: this.string(''),
      email: this.string(''),
      vatId: this.string(''),
      addressLine1: this.string(''),
      street: this.string(''),
      postalCode: this.string(''),
      city: this.string(''),
      country: this.string(CountryFactory.getDefaultCountry()),
    }
  }

  static getFormValidations() {
    return {
      form: CustomerValidations,
    }
  }

  static isValid(data) {
    if (!data) {
      return false
    }

    // Hack: Vuelidate does not support programmatic validation, thus we fake
    // a component here to workaround this limitation
    const vm = new Vue({
      data() {
        return {
          form: {
            email: data.email,
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName,
            vatId: data.vatId,
            addressLine1: data.addressLine1,
            street: data.street,
            city: data.city,
            postalCode: data.postalCode,
            country: data.country,
          },
        }
      },
      validations() {
        return Customer.getFormValidations()
      },
    })

    // Trigger validation
    vm.$v.form.$reset()
    vm.$v.$touch()

    // Validation result
    const valid = !vm.$v.$invalid

    // Cleanup: Destroy the temporary vue instance
    vm.$destroy()

    return valid
  }
}
