export const autofocus = {
  inserted(el, binding, vnode) {
    // Handle special case with Buefy components: the native, focusable element (eg input) is wrapped and thus
    // el is not focusable. However, Buefy components expose a focus() method on the component instance which can be used
    if (
      vnode.componentInstance &&
      typeof vnode.componentInstance.focus === 'function'
    ) {
      vnode.componentInstance.focus()
    } else {
      el.focus()
    }
  },
}
