import LanguageFactory from '@common/models/LanguageFactory'
import { getBrowserLocaleCode } from '@common/plugins/helpers'

export default {
  created() {
    let preferredLanguage = this.getQueryLanguage() || getBrowserLocaleCode()

    if (!LanguageFactory.isValidUserInterfaceCode(preferredLanguage)) {
      preferredLanguage = process.env.DEFAULT_LOCALE
    }

    this.$i18n.setLocale(preferredLanguage)
  },
  methods: {
    getQueryLanguage() {
      return this.$route.query.language || ''
    },
  },
}
