<template>
  <FormWrapper
    class="form--delete"
    :validator="{}"
    data-cy-id="delete-entity-form"
    @submit="formSubmit"
  >
    <div class="field">
      <div class="control">
        <b-button
          class="has-stamp-icon"
          native-type="submit"
          type="is-danger"
          rounded
          :class="isLoadingClass"
        >
          {{ $t('cta.confirm_delete') }}
          <IconBinCircle class="icon" />
        </b-button>
      </div>
    </div>
  </FormWrapper>
</template>

<script>
import ApiFormMixin from '@common/mixins/ApiFormMixin'
import UiNotifications from '@common/mixins/UiNotifications'
import IconBinCircle from '~common/assets/inline/circle-icon/bin-circle-flat.svg'

export default {
  components: { IconBinCircle },
  mixins: [ApiFormMixin, UiNotifications],
  props: {
    value: {
      type: Object,
      required: true,
    },
    redirectOnSuccess: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    redirectAndDelete() {
      const redirectUrl = this.localePath(this.value.redirectAfterDeleteRoute)

      this.$router.push(redirectUrl, this.onSuccess)
    },
    onSuccess() {
      this.entityDeletedNotice(this.entityName)
      this.value.$delete()
    },
    async formSubmit() {
      this.setAjaxPending()

      try {
        const deleteUrl = this.value.getApiDetailsUrl()

        await this.$axios.$delete(deleteUrl)
        this.$emit('success')

        if (this.redirectOnSuccess) {
          this.redirectAndDelete()

          return
        }

        this.onSuccess()
      } catch (error) {
        this.catchErrorResponse(error)
        // fake backend validation, for better visual feedback
        setTimeout(() => {
          this.setAjaxPending(false)
        }, 200)
      }
    },
  },
}
</script>
