import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { getInitials } from '@common/plugins/helpers'
import Model from '@common/models/orm/OrmCrudModel'
import Workspace from '@common/models/orm/Workspace'
import BillingFactory from '@common/models/BillingFactory'
import Theme from '@common/models/orm/Theme'
import Contract from '@common/models/orm/Contract'
import Story from '@common/models/orm/Story'
import {
  MODULE_ROBOT,
  DATA_SECURITY_DASHBOARD,
  MODULE_AI,
} from '@common/plugins/const.js'

export const PLAN_INACTIVE = 'inactive'

export default class Project extends Model {
  static className = 'Project'

  static entity = 'projects'
  static endpointMandatoryTfa = 'mandatory-tfa'
  static endpointVariables = 'variables'

  static DOMAIN_LIMIT = 5

  // we commit domains in a separate form
  static formFields = ['id', 'name', 'workspaceId']
  static defaultAnonymization = {
    numberOfDays: 180,
    ai: [],
    chatbot: [],
    robot: [],
  }

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      workspaceId: this.attr(''),
      configId: this.attr(''),
      domains: this.attr([]),
      enabledFeatures: this.attr([]),
      stories: this.hasMany(Story, 'projectId'),
      theme: this.hasOne(Theme, 'projectId'),
      contract: this.hasOne(Contract, 'billingContractId'),
      languages: this.attr([]),
      planName: this.string(''),
      billingPlan: this.string(''),
      billingStatus: this.string(''),
      billingContractId: this.string(''),
      mandatoryTfa: this.boolean(false),
      anonymization: this.attr({}),
      sessionContinueHours: this.number(0),
      variablesTimeFrameHours: this.number(0),
      previewBgImageUrl: this.string(''),
      isSessionContinueEnabled: this.boolean(false),
      ...super.fields(),
    }
  }

  static beforeCreate(model) {
    if (!model.billingPlan || !BillingFactory.getPlan(model.billingPlan)) {
      model.billingPlan = BillingFactory.getDefaultPlan().entity
    }
  }

  static beforeUpdate(model) {
    if (!model.billingPlan || !BillingFactory.getPlan(model.billingPlan)) {
      model.billingPlan = BillingFactory.getDefaultPlan().entity
    }
  }

  static getFormValidations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
        workspaceId: {
          required,
        },
      },
    }
  }

  get redirectAfterDeleteRoute() {
    return {
      name: Workspace.slug() + '-id',
      params: {
        id: this.workspaceId,
      },
    }
  }

  get initials() {
    return getInitials(this.name)
  }

  get hasActiveSubscription() {
    return !!this.contract?.isActive
  }

  get isInactive() {
    return this.billingStatus === PLAN_INACTIVE
  }

  get subscriptionPlan() {
    if (this.contract) {
      return this.contract.plan
    }

    return Contract.getDefaultPlan().entity
  }

  get isAiFeatureEnabled() {
    return this.enabledFeatures?.includes(MODULE_AI)
  }

  get isRobotFeatureEnabled() {
    return this.enabledFeatures?.includes(MODULE_ROBOT)
  }

  get isDataSecurityDashboardFeatureEnabled() {
    return this.enabledFeatures?.includes(DATA_SECURITY_DASHBOARD)
  }

  get subscriptionStatus() {
    if (this.contract) {
      return this.contract.status
    }

    return Contract.getDefaultStatus().entity
  }
}
