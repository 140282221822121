export const MEDIA_TYPE_ALL = 'all'
export const MEDIA_TYPE_DOCUMENT = 'document'
export const MEDIA_TYPE_IMAGE = 'image'
export const MEDIA_TYPE_VIDEO = 'video'
export const MEDIA_TYPE_LINK = 'link'
export const MEDIA_TYPE_YOUTUBE = 'youtube'
export const MEDIA_TYPE_VIMEO = 'vimeo'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const FILE_TYPE_SPECIFIERS = {
  [MEDIA_TYPE_ALL]: [],
  [MEDIA_TYPE_DOCUMENT]: [
    '.txt',
    'text/plain',
    '.rtf',
    'application/rtf',
    '.csv',
    'text/csv',

    // OpenDocument
    '.odt',
    'application/vnd.oasis.opendocument.text',
    '.ods',
    'application/vnd.oasis.opendocument.spreadsheet',
    '.odp',
    'application/vnd.oasis.opendocument.presentation',

    // MS Office
    '.doc',
    'application/msword',
    '.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.ppt',
    'application/vnd.ms-powerpoint',
    '.pptx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.xls',
    'application/vnd.ms-excel',
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

    // Adobe
    '.pdf',
    'application/pdf',
  ],
  [MEDIA_TYPE_IMAGE]: [
    '.jpeg',
    '.jpg',
    'image/jpeg',
    '.png',
    'image/png',
    '.gif',
    'image/gif',
    '.svg',
    'image/svg+xml',
  ],
  [MEDIA_TYPE_VIDEO]: [
    '.mp4',
    'video/mp4',
    '.mov',
    'video/quicktime',
    'video/H264',
    'video/H265',
  ],
}
