import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { inArray } from '@common/plugins/validators'
import LanguageFactory from '@common/models/LanguageFactory'
import { toFullName } from '@common/plugins/helpers'
import Model from './OrmBaseModel'
import Agreement from './Agreement'

export default class User extends Model {
  static className = 'User'

  static entity = 'users'

  static AUTH_METHOD = {
    EMAIL: 'email',
    TOTP: 'totp',
    SMS: 'sms',
  }

  static AUTH_METHOD_EMAIL = 'email'
  static AUTH_METHOD_TOTP = 'totp'
  static AUTH_METHOD_SMS = 'sms'

  static TFA_AVAILABLE = 'available'
  static TFA_UNAVAILABLE = 'unavailable'
  static TFA_MANDATORY = 'mandatory'
  static TFA_ENABLED = 'enabled'
  static TFA_STATUS_LOCKED = 'locked'
  static TFA_STATUS_DISABLED = 'disabled'
  static TFA_STATUS_NOT_AVAILABLE = 'N/A'
  static TFA_TOKEN_LENGTH = 6

  static formFields = ['id', 'firstName', 'lastName', 'preferredLanguage']

  // avoid circular dependency
  static ROLE_WORKSPACE_OWNER = 'owner'
  static ROLE_WORKSPACE_MEMBER = 'member'
  static ROLE_PROJECT_MEMBER = 'project/member'

  static fields() {
    return {
      id: this.attr(''),
      email: this.attr(''),
      pendingEmail: this.attr(''),
      firstName: this.attr(''),
      lastName: this.attr(''),
      projects: this.attr([]),
      role: this.attr(''),
      workspaceId: this.attr(''),
      preferredLanguage: this.attr(process.env.DEFAULT_LOCALE),
      avatar: this.attr(''),
      agreements: this.hasMany(Agreement, 'userId'),
      tfaStatus: this.attr(''),
      authMethod: this.attr(''),
      hasReporting: this.boolean(false),
    }
  }

  get name() {
    return toFullName(this)
  }

  static state() {
    return {
      tfaConversion: null,
    }
  }

  get isProjectMember() {
    return this.role === User.ROLE_PROJECT_MEMBER
  }

  get isOwner() {
    return this.role === User.ROLE_WORKSPACE_OWNER
  }

  static getFormValidations() {
    return {
      form: {
        email: {
          required,
          email,
          maxLength: maxLength(254),
        },
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
        jobTitle: {
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
        preferredLanguage: {
          required,
          missingSelection: inArray(
            LanguageFactory.getUserInterfaceLanguages().map(
              ({ langcode }) => langcode,
            ),
          ),
        },
      },
    }
  }
}
