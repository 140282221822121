import countries from 'i18n-iso-countries'
import ModelFactoryBase from '@common/models/ModelFactoryBase'

const countryCodes = () => {
  const alpha2Codes = countries.getAlpha2Codes()

  return Object.keys(alpha2Codes).map((countryCode) => ({
    entity: countryCode,
    countryCode,
  }))
}

export default class CountryFactory extends ModelFactoryBase {
  static loadedLocales = []

  static getCountryCodes = countryCodes()

  static getAll(locale) {
    locale = locale.toLowerCase()

    // If we have not yet loaded the requested locale, load it
    if (!this.loadedLocales.includes(locale)) {
      countries.registerLocale(
        require(`i18n-iso-countries/langs/${locale}.json`),
      )
      this.loadedLocales.push(locale)
    }

    const countryNames = countries.getNames(locale)

    return Object.entries(countryNames)
      .map(([key, value]) => ({
        countryCode: key,
        label: value,
      }))
      .sort((value1, value2) => {
        const label1 = value1.label.toUpperCase()
        const label2 = value2.label.toUpperCase()

        if (label1 < label2) {
          return -1
        }

        if (label1 > label2) {
          return 1
        }

        return 0
      })
  }

  static getCountry(locale, countryCode) {
    return CountryFactory.getAll(locale).find(
      (country) =>
        country.countryCode.toUpperCase() === countryCode.toUpperCase(),
    )
  }

  static isValidCode(value) {
    return !!this.map.find(({ countryCode }) => countryCode === value)
  }

  static getDefaultCountry() {
    return 'CH'
  }
}
