import Model from '@common/models/orm/OrmBaseModel'

export const TYPE_SLACK = 'slack'

export default class ChatbotExtension extends Model {
  static className = 'ChatbotExtension'

  static entity = 'chatbot-extensions'
  static endpoint = 'extensions'

  static fields() {
    return {
      id: this.attr(null),
      projectId: this.attr(''),
      type: this.attr(''),
      payload: this.attr({}),
      ...super.fields(),
    }
  }
}
