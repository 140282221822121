import { required, minLength, maxLength, not } from 'vuelidate/lib/validators'
import isURL from 'validator/lib/isURL'
import { differsTo } from '@common/plugins/validators'
import { MAX_TEXT_LENGTH } from '@common/plugins/const'
import { removeParagraphTag } from '@common/plugins/helpers'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import Model from '@/models/chatElements/ChatElementBaseModel'
import { VAR_HTML_TAG_RE } from '@/models/chatElements/ChatElementText'

const MAX__DECISION_LINK_TEXT_LENGTH = 512

const emptyContent = '<p></p>'
const validUrl = (value) => {
  value = removeParagraphTag(value)

  const isVariableAdded = VAR_HTML_TAG_RE.test(value)

  if (isVariableAdded) {
    return true
  }

  return isURL(value)
}

export default class ChatElementButtonGroupLink extends Model {
  static className = 'ChatElementButtonGroupLink'
  static entity = 'button-group-link'
  static label = 'general.chat_element_button_group_link'

  static formFields = ['buttons', 'content']
  static direction = Model.INTERACTION_IN
  static MAX_BUTTON_COUNT = 5

  static fields() {
    return {
      buttons: this.attr([
        {
          id: '',
          value: '',
          url: '',
        },
      ]),
      content: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonButtonGroupLink')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormButtonGroupLink')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormButtonGroup/ChatFormButtonGroupLinkTranslate'
      )
  }

  static getIcon() {
    return () => import('@/assets/inline/button-links.svg')
  }

  static getFormValidations() {
    return {
      form: {
        buttons: {
          required,
          minLength: minLength(1),
          $each: {
            value: {
              required,
              maxLength: maxLength(MAX_TEXT_LENGTH),
              sameAsRawValue: not((value) => {
                return value === emptyContent
              }),
            },
            url: {
              required,
              maxLength: maxLength(MAX__DECISION_LINK_TEXT_LENGTH),
              sameAsRawValue: not((value) => value === emptyContent),
              validUrl: (value) => validUrl(value),
            },
          },
        },
        content: {
          empty: differsTo(emptyContent),
        },
      },
      variableId: {},
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        buttons: {},
        content: {},
      },
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
      isRoot: true,
    }
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      buttons: this.buttons,
      content: this.content,
      variables,
      storyLinkTargets,
    }
  }
}
