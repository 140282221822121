import Model from '@common/models/orm/OrmBaseModel'

export default class SessionChat extends Model {
  static className = 'SessionChat'

  static entity = 'session-chats'
  static endpoint = 'sessions'

  static fields() {
    return {
      id: this.string(''),
      lastEngagementAt: this.string(''),
      startedAt: this.string(''),
      variables: this.attr([]),
      userId: this.string(''),
      projectId: this.string(''),
      isTest: this.boolean(false),
      url: this.string(''),
    }
  }

  get files() {
    if (!this.variables) {
      return []
    }

    return this.variables.filter((i) => ['files', 'file'].includes(i.valueType))
  }

  get inputs() {
    if (!this.variables) {
      return []
    }

    return this.variables.filter(
      (i) => !['files', 'file'].includes(i.valueType),
    )
  }
}
