import uuid4 from 'uuid4'
import ChatNode from '@common/models/orm/ChatNode'
import {
  STORY_BUILDER_BUTTON_LIST_COLORS,
  STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS,
} from '@common/constants/project-colors'
import ChatElementFactory from '@/models/ChatElementFactory'

const ROOT_PARENT_VID = ''

const COLORS_BY_DECISION_BLOCK = {
  survey: STORY_BUILDER_BUTTON_LIST_COLORS,
  'button-group': STORY_BUILDER_BUTTON_LIST_COLORS,
  'live-chat': Object.values(STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS),
}

const DECISION_BLOCKS = ['button-group', 'live-chat', 'survey']

/**
 *
 * @param {Object} a
 * @param {Object} b
 * @returns {number}
 */
const byWeight = (a, b) => a.weight - b.weight

/**
 *
 * @param {ChatNode} node
 * @param {String} storyRevisionId
 */
const handleErrorsOnNodeInsert = (node, storyRevisionId = '') => {
  if (node.constructor.entity !== ChatNode.entity) {
    throw new Error('Can only add type ChatNodes')
  }

  if (node.vid && !uuid4.valid(node.vid)) {
    throw new Error('Can not add node without valid vid')
  }

  if (node.parentVid && !uuid4.valid(node.parentVid)) {
    throw new Error('Can not add node without valid parent vid')
  }

  if (node.storyRevisionId && !uuid4.valid(node.storyRevisionId)) {
    throw new Error('Can not add node without valid storyRevisionId')
  }

  // if (!storyRevisionId && !node.storyRevisionId) {
  //   throw new Error('Can not add node without storyRevisionId set')
  // }
  //
  // if (storyRevisionId && node.storyRevisionId) {
  //   if (storyRevisionId !== node.storyRevisionId) {
  //     throw new Error('Can not add node divergent storyRevisionId')
  //   }
  // }
}

// TODO pass store through when instantiating a new instance of NodeGraph
// so we can make much better custom validators

export default class NodeGraph {
  nodes = []
  storyRevisionId = ''
  errors = []

  /**
   * @param data
   */
  constructor(data = []) {
    if (data.length === 0) {
      return
    }

    this.nodes = data.map((item) => {
      if (item instanceof ChatNode) {
        return item
      }

      return new ChatNode(item)
    })
    this.storyRevisionId = this.nodes[0].storyRevisionId

    const hasRevisionIdMismatch = this.nodes.some(
      (item) => item.storyRevisionId !== this.storyRevisionId,
    )

    if (hasRevisionIdMismatch) {
      throw new Error('Not a consistent storyRevisionId set')
    }
  }

  /**
   * Return a list of active nodes
   * @returns {ChatNode[]}
   */
  getNodesInActivePath() {
    if (this.nodes.length <= 1) {
      return this.nodes
    }

    const result = []
    let nextChild = this.getRootNode()

    if (!nextChild) {
      throw new Error('Please add a root node')
    }

    do {
      if (result.includes(nextChild)) {
        /* eslint-disable no-console */
        console.warn('Cyclic data found', nextChild)

        break
      }

      result.push(nextChild)

      const { vid, activeChildWeight } = nextChild

      nextChild = this.getNodesByParentVid(vid).find(
        ({ weight }) => weight === activeChildWeight,
      )
    } while (nextChild)

    return result
  }

  /**
   * Return the last element of the active path
   * @returns {ChatNode}
   */
  getLastNodeOfActivePath() {
    const nodes = this.getNodesInActivePath()

    if (nodes.length === 0) {
      return null
    }

    return nodes[nodes.length - 1]
  }

  /**
   * The root node
   * @param {String} payloadType
   * @param {String|Number} weight
   * @returns {String}
   */
  getDecisionBlockColor(payloadType, weight) {
    if (!payloadType || weight === undefined) {
      return null
    }

    return COLORS_BY_DECISION_BLOCK[payloadType][weight]
  }

  /**
   * The root node
   * @returns {ChatNode|undefined}
   * @private
   */
  getRootNode() {
    return this.nodes.find(({ parentVid }) => parentVid === ROOT_PARENT_VID)
  }

  /**
   * Return a single node by its vid
   * @param {String} vid
   * @param {Boolean} throwError
   * @returns {ChatNode|undefined}
   */
  getNodeByVid(vid = '', throwError = true) {
    const node = this.nodes.find((node) => node.vid === vid)

    if (throwError && !node) {
      throw new Error('Can find node with vid: ' + vid)
    }

    return node
  }

  /**
   * Return sorted children with the given parent vid
   * @param {String} parentVid
   * @returns {ChatNode[]}
   * @private
   */
  getNodesByParentVid(parentVid = '') {
    return this.nodes
      .filter((node) => node.parentVid === parentVid)
      .sort(byWeight)
  }

  /**
   * Searches the parent decision block by parentVid
   * @param {String} parentVid
   * @param {Object} nodes
   * @returns {ChatNode|null}
   */
  getParentDecisionBlock(parentVid = '', nodes) {
    if (!nodes) {
      return null
    }

    const parent = nodes[parentVid]

    if (!parent) {
      return null
    }

    if (DECISION_BLOCKS.includes(parent.payloadType)) {
      return parent
    }

    if (!parent.parentVid) {
      return null
    }

    return this.getParentDecisionBlock(parent.parentVid, nodes)
  }

  /**
   * Remove all children and descendants to a given parent vid recursively
   * @param {String} parentVid
   * @private
   */
  removeChildrenByParentVid(parentVid = '') {
    while (parentVid) {
      const children = this.getNodesByParentVid(parentVid)

      // remove leafs with parent vid, done
      if (children.length === 0) {
        this.nodes = this.nodes.filter(({ vid }) => vid !== parentVid)
        parentVid = ''

        break
      }

      children.forEach(({ vid }) => {
        this.removeChildrenByParentVid(vid)
      })
    }
  }

  /**
   * Set vid if needed
   * @param {ChatNode} node
   * @private
   */
  setNodeVid(node) {
    // set vid if new element
    if (node.isNew()) {
      node.vid = uuid4()
    }
  }

  /**
   * Set id if needed
   * @param {ChatNode} node
   * @private
   */
  setNodeId(node, id) {
    node.id = id
  }

  /**
   * Insert a new node to the list
   * @param {ChatNode} node
   */
  insertNode(node) {
    if (this.getNodeByVid(node.vid, false)) {
      throw new Error('Can only add new nodes')
    }

    handleErrorsOnNodeInsert(node, this.storyRevisionId)
    this.setNodeVid(node)

    const competitorNode = this.getNodesByParentVid(node.parentVid).find(
      ({ weight }) => weight === node.weight,
    )

    // append competitor to new Node
    this.appendChild(node.vid, competitorNode, node.activeChildWeight)
    this.nodes.push(node)
  }

  /**
   *
   * @param {String} vid
   * @param {String} parentVid
   * @param {Number} weight
   */
  moveNode(vid = '', parentVid = '', weight = ChatNode.DEFAULT_WEIGHT) {
    // do not append to itself
    if (vid === parentVid) {
      return
    }

    const node = this.getNodeByVid(vid)

    // early return on very same position
    if (node.parentVid === parentVid && node.weight === weight) {
      return
    }

    this.unlinkNodeFromActivePath(vid)

    const childNode = this.getNodesByParentVid(parentVid).find(
      (item) => item.weight === weight,
    )

    // append competitor to new Node
    this.appendChild(vid, childNode, node.activeChildWeight)

    node.parentVid = parentVid
    node.weight = weight
  }

  /**
   * Clone the current instance
   * @returns this
   */
  clone() {
    const nodes = this.nodes.map((item) => new ChatNode(item.$toJson()))

    return new NodeGraph(nodes)
  }

  /**
   * Change the weight order of childNodes by their drag index
   * @param {String} parentVid
   * @param {Number} oldIndex
   * @param {Number} newIndex
   */
  switchChildrenByWeight(parentVid = '', oldIndex, newIndex) {
    const children = this.getNodesByParentVid(parentVid)
    const reducedChildren = children.filter((item) => item.weight !== oldIndex)

    // switch to new weight
    if (children[oldIndex]) {
      children[oldIndex].weight = newIndex
    }

    reducedChildren.forEach((node) => {
      // skip lower weights
      if (node.weight < oldIndex && node.weight < newIndex) {
        return
      }

      // skip upper weight
      if (node.weight > oldIndex && node.weight > newIndex) {
        return
      }

      // on move up, move in betweener down
      if (node.weight > oldIndex && node.weight <= newIndex) {
        node.weight = node.weight - 1
      }

      // on move down, move in betweener up
      if (node.weight < oldIndex && node.weight >= newIndex) {
        node.weight = node.weight + 1
      }
    })
  }

  /**
   * Removes a single node by vid. Keep or remove active or all children.
   * @param {String} vid
   * @param {Boolean} deleteAllChildren
   */
  deleteNode(vid = '', deleteAllChildren = false) {
    const children = this.getNodesByParentVid(vid)

    // without children, just remove node
    if (children.length === 0) {
      this.nodes = this.nodes.filter((item) => item.vid !== vid)

      return
    }

    const targetNode = this.getNodeByVid(vid)
    const parentNode = this.getNodeByVid(targetNode.parentVid, false)

    // delete everything, if first element
    if (!parentNode && deleteAllChildren) {
      this.nodes = []

      return
    }

    // remove node with multiple children, keep active trail
    if (!deleteAllChildren) {
      const activeChild = children[targetNode.activeChildWeight]

      // maybe there is no child in the active path
      if (activeChild) {
        const { activeChildWeight = ChatNode.DEFAULT_WEIGHT } = parentNode || {}

        activeChild.parentVid = targetNode.parentVid
        activeChild.weight = activeChildWeight

        children.splice(targetNode.activeChildWeight, 1)
      }
    }

    children.forEach((node) => {
      this.removeChildrenByParentVid(node.vid)
    })

    // finally remove target from list
    this.nodes = this.nodes.filter((item) => item.vid !== vid)
  }

  /**
   * Removing a single child and its descendants by parentVid and its weight
   * @param {String} parentVid
   * @param {Number} weight
   */
  removeChildrenByWeight(parentVid = '', weight = ChatNode.DEFAULT_WEIGHT) {
    const children = this.getNodesByParentVid(parentVid)
    const targetNode = children.find((child) => child.weight === weight)

    // Reduce weight index even if targetNode is not existing,
    // since switching order may resulted in empty index.
    children.forEach((node) => {
      if (node.weight <= weight) {
        return
      }

      node.weight = node.weight - 1
    })

    if (!targetNode) {
      return
    }

    this.deleteNode(targetNode.vid, true)
  }

  /**
   * Link the child to vid in active path to parent of vid
   * @param {String} vid
   * @private
   */
  unlinkNodeFromActivePath(vid = '') {
    const node = this.getNodeByVid(vid)
    const children = this.getNodesByParentVid(vid)

    // without children, nothing to do
    if (children.length === 0) {
      return
    }

    this.appendChild(
      node.parentVid,
      children[node.activeChildWeight],
      node.weight,
    )
  }

  /**
   *
   * @param {String} parentVid
   * @param {ChatNode} childNode
   * @param {Number} weight
   * @private
   */
  appendChild(parentVid = '', childNode, weight = ChatNode.DEFAULT_WEIGHT) {
    if (childNode !== undefined) {
      childNode.parentVid = parentVid
      childNode.weight = weight
    }
  }

  /**
   * Set the virtual property 'activeChildWeight' of a node
   * @param {String} vid
   * @param {Number} weight
   */
  setActiveChildWeightByVid(vid = '', weight = ChatNode.DEFAULT_WEIGHT) {
    const node = this.getNodeByVid(vid)

    node.activeChildWeight = weight
  }

  /**
   * Update a single node
   * @param {String} vid
   * @param {Object} payload
   * @param {Array} modifiers
   * @param {String} variableId
   */
  updateNode(vid = '', payload = {}, modifiers, variableId) {
    const node = this.getNodeByVid(vid)

    node.payload = payload

    if (modifiers) {
      node.modifiers = modifiers
    }

    if (variableId !== undefined) {
      node.variableId = variableId
    }
  }

  /**
   * Throw errors, if nodes is not an valid set of ChatNodes
   * @returns {boolean}
   */
  validate() {
    // throw error on missing root
    if (this.nodes.length > 0 && !this.nodes.find((item) => !item.parentVid)) {
      throw new Error('There needs to be a root set')
    }

    // throw error on multiple roots
    if (this.nodes.filter((item) => !item.parentVid).length > 1) {
      throw new Error('There are multiple roots in the set')
    }

    // throw error on missing parentVid
    if (this.nodes.length > 1) {
      const nodesHaveMissingParentVid = this.nodes.some(
        (item) =>
          item.parentVid &&
          !this.nodes.find((node) => node.vid === item.parentVid),
      )

      if (nodesHaveMissingParentVid) {
        throw new Error('There are missing parent nodes')
      }
    }

    return true
  }

  /**
   * walk through each node and validate
   * @returns {boolean}
   */
  softValidateNodes() {
    this.errors = []
    this.nodes.forEach((node) => {
      const error = this.validateNodeByVid(
        node.vid,
        ChatElementFactory.fromChatNode(node).constructor.getGraphValidations(),
      )

      if (!error) {
        return
      }

      this.errors.push({
        vid: node.vid,
        label: ChatElementFactory.fromChatNode(node).constructor.label,
        error,
      })
    })

    return this.errors.length === 0
  }

  /**
   * Validate a node by its position
   *
   * @param vid
   * @param validations
   * @returns {string}
   */
  validateNodeByVid(vid, validations) {
    const node = this.nodes.find((item) => item.vid === vid)

    if (!node) {
      return ''
    }

    if (validations.isRoot === false) {
      if (node.parentVid === '') {
        return 'graph_validation.isRoot'
      }
    }

    const childNode = this.nodes.find((item) => item.parentVid === vid)

    if (validations.canBeLast === false && childNode) {
      return 'graph_validation.isLeaf'
    }

    if (validations.mustBeLast && childNode) {
      return 'graph_validation.isLeafOnly'
    }

    if (
      validations.allowOtherWidgetInBetween === false &&
      this.isOtherWidgetInBetween(node)
    ) {
      return 'graph_validation.noInBetween'
    }

    if (validations.hasCustomValidator) {
      const { constructor } = ChatElementFactory.fromChatNode(node)
      const validator =
        typeof hasCustomValidator === 'string'
          ? constructor[validations.hasCustomValidator]
          : constructor.customGraphValidator

      const error = validator(node, this.nodes)

      if (error) {
        return error
      }
    }

    return ''
  }

  /**
   * Convert instance to object
   * @returns {{}[]}
   */
  toJson() {
    return this.nodes.map((node) => node.getFormJson())
  }

  /**
   * Make sure to make a node with and softValidate error visible in activePath
   */
  revealActivePathWithError() {
    if (this.errors.length === 0) {
      return
    }

    // if some errors are in active paths, don't do anything
    const nodeWithErrorInActivePath = this.getNodesInActivePath().some((node) =>
      this.errors.find((item) => item.vid === node.vid),
    )

    if (nodeWithErrorInActivePath) {
      return
    }

    // reveal the first hidden error
    this.revealActivePathByVid(this.errors[0].vid)
  }

  /**
   * Open the active path till the given vid
   * @param vid
   */
  revealActivePathByVid(vid) {
    const targetNode = this.getNodeByVid(vid)

    let activeChildWeight = targetNode.weight
    let parentNode = this.nodes.find(
      (node) => node.vid === targetNode.parentVid,
    )

    while (parentNode) {
      parentNode.activeChildWeight = activeChildWeight
      activeChildWeight = parentNode.weight
      parentNode = this.nodes.find((node) => node.vid === parentNode.parentVid)
    }
  }

  isOtherWidgetInBetween(node) {
    const child = this.nodes.find((_node) => _node.vid === node.parentVid)

    if (child && child.payloadType !== node.payloadType) {
      return true
    }

    return false
  }
}
