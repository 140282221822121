import {
  required,
  requiredIf,
  minLength,
  maxLength,
} from 'vuelidate/lib/validators'
import Model from '@common/models/orm/OrmCrudModel'
import Project from '@common/models/orm/Project'
import StoryRevision from '@common/models/orm/StoryRevision'
import Trigger from '@common/models/orm/Trigger'

export default class Story extends Model {
  static className = 'Story'

  static entity = 'stories'

  static STATUS_OK = 'ok'

  static formFields = [
    'id',
    'name',
    'defaultLanguage',
    'languageAbbr',
    'languageId',
    'projectId',
    'isActive',
  ]

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
      defaultLanguage: this.attr(''),
      languageAbbr: this.attr(''),
      languageId: this.attr(''),
      status: this.attr(''),
      isActive: this.boolean(false),
      projectId: this.attr(''),
      publishedRevisionId: this.attr(null).nullable(),
      project: this.belongsTo(Project, 'projectId'),
      storyRevisions: this.hasMany(StoryRevision, 'storyId'),
      triggers: this.hasMany(Trigger, 'storyId'),
      behaviours: this.attr([]),
      translations: this.attr([]),
      language: this.attr(),
      ...super.fields(),
    }
  }

  static getFormValidations() {
    return {
      form: {
        name: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
        projectId: {
          required,
        },
        defaultLanguage: {
          required: requiredIf(({ languageId }) => !languageId),
        },
      },
    }
  }

  get detailsRoute() {
    return {
      name: this.constructor.slug() + '-builder-id',
      params: {
        id: this.$id,
      },
    }
  }

  get redirectAfterDeleteRoute() {
    return {
      name: Project.slug(),
      params: {
        id: this.projectId,
      },
    }
  }

  get isStatusOK() {
    return this.status === Story.STATUS_OK
  }
}
