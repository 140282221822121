// @link https://github.com/vuejs/vuex/issues/1268

export default (state) => {
  return Object.keys(state).reduce((setters, key) => {
    const setKey = 'set' + key.charAt(0).toUpperCase() + key.slice(1)

    setters[setKey] = (state, payload) => (state[key] = payload)

    return setters
  }, {})
}
