import Model from '@common/models/orm/OrmCrudModel'
import { DEFAULT_CURRENCY } from '@common/models/BillingFactory'

export default class LineItem extends Model {
  static className = 'LineItem'

  static entity = 'billing-line-items'

  static formFields = []

  static fields() {
    return {
      id: this.attr(null),
      contractId: this.string(''),
      description: this.string(''),
      name: this.string(''),
      periodEnd: this.string(''),
      periodStart: this.string(''),
      pricePerUnit: this.number(0),
      total: this.number(0),
      isNew: this.boolean(false),
      isProrated: this.boolean(false),
      currency: this.string(DEFAULT_CURRENCY),
    }
  }

  static getFormValidations() {
    return {}
  }
}
