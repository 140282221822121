import Model from '@common/models/orm/OrmBaseModel'

export default class WorkflowVariable extends Model {
  static className = 'WorkflowVariable'
  static entity = 'workflow-variables'

  static primaryKey = ['workflowId', 'name', 'direction']

  static formFields = []

  static fields() {
    return {
      workflowId: this.attr(''),
      name: this.attr(''),
      dataType: this.attr(''),
      direction: this.attr(''),
      expectedValues: this.attr([]),
      ...super.fields(),
    }
  }

  static getFormValidations() {
    return {
      form: {},
    }
  }
}
