import Model from '@common/models/orm/OrmCrudModel'

export default class SelfServiceToken extends Model {
  static className = 'SelfServiceToken'

  static entity = 'billing-self-service-tokens'

  static endpoint = 'self-service-tokens'

  static fields() {
    return {
      id: this.string(''),
      token: this.string(''),
    }
  }
}
