import { required, minLength } from 'vuelidate/lib/validators'
import { PLAN_EXPERT } from '@common/models/BillingFactory'
import Model from '@/models/chatElements/ChatElementBaseModel'

import Classifiers from '@/models/orm/Classifiers'
import ClassifierLabels from '@/models/orm/ClassifierLabels'

export default class ChatElementIntent extends Model {
  static className = 'ChatElementIntent'

  static entity = 'intent'

  static label = 'general.chat_element_intent'

  static formFields = ['classifier', 'labels']

  static direction = Model.INTERACTION_IN

  static fields() {
    return {
      classifier: this.attr(''),
      labels: this.attr([]),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonIntent')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormIntent')
  }

  static getTranslateForm() {
    return () =>
      import('@/components/chatForms/translations/ChatFormNoTranslate')
  }

  static getIcon() {
    return () => import('~common/assets/inline/brain.svg')
  }

  static getPlanAvailability() {
    return [PLAN_EXPERT]
  }

  static getFormValidations() {
    return {
      form: {
        classifier: {
          required,
        },
        labels: {
          required,
          minLength: minLength(1),
        },
      },
      variableId: {
        required,
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
    }
  }

  props({ $store }) {
    const { labels, classifier } = this

    // FIXME: how to use .getters('getter')(param) ??
    const linkedClassifier = $store
      .$db()
      .model(Classifiers)
      .query()
      .whereId(classifier)
      .first()

    // if the classifier doesnt exist anymore, we still want to show
    // the labels but mark them as errored
    if (!linkedClassifier) {
      return {
        classifier: null,
        erroredLabels: labels,
        labels,
      }
    }

    // FIXME: how to use .getters('getter')(param) ??
    const labelsInLinkedClassifier = $store
      .$db()
      .model(ClassifierLabels)
      .query()
      .where('classifierId', classifier)
      .get()

    const validLabels = labelsInLinkedClassifier.map((i) => i.id)
    const erroredLabels = labels.filter((label) => !validLabels.includes(label))

    return {
      classifier: linkedClassifier,
      erroredLabels,
      labels,
    }
  }
}
