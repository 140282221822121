import Model from '@common/models/orm/OrmBaseModel'

export const AGREEMENT_TYPE_GTC = 'gtc'
export const AGREEMENT_TYPE_GDPR = 'gdpr'
export const AGREEMENT_TYPE_AVV = 'avv'

export default class Agreement extends Model {
  static className = 'Agreement'

  static entity = 'agreements'

  static primaryKey = ['userId', 'type']

  static fields() {
    return {
      userId: this.attr(''),
      type: this.attr(''),
      hasAccepted: this.boolean(false),
      version: this.attr(''),
    }
  }
}
