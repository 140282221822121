const middleware = {}

middleware['app-init'] = require('../middleware/app-init.js')
middleware['app-init'] = middleware['app-init'].default || middleware['app-init']

middleware['billwerk'] = require('../middleware/billwerk.js')
middleware['billwerk'] = middleware['billwerk'].default || middleware['billwerk']

middleware['browser-detect'] = require('../middleware/browser-detect.js')
middleware['browser-detect'] = middleware['browser-detect'].default || middleware['browser-detect']

export default middleware
