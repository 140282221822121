import stateSetters from '@common/plugins/state-setters'
import Contract from '@common/models/orm/Contract'
import { APP_BILLING_API_PREFIX } from '@common/plugins/api'
import { createApiUrl } from '@common/plugins/helpers'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) =>
      query().whereId(state.current).withAll().first(),
    getById:
      (state, { query }) =>
      (id) =>
        query().whereId(id).withAll().first(),
    getByProjectId:
      (state, { query }) =>
      (projectId) =>
        query()
          .where('projectId', (value) => value === projectId)
          .first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById({ commit, dispatch }, id) {
      const uri = createApiUrl(
        APP_BILLING_API_PREFIX.slice(1),
        Contract.endpoint,
        id,
      )

      const { data } = await this.$axios.get(uri)

      await this.$db().model(Contract).insertOrUpdate({ data: data.data })

      commit('setCurrent', id)
    },
    async fetchPaymentData({ dispatch }, id) {
      const uri = createApiUrl(Contract.endpoint, id, 'payment-data-changed')

      const { data } = await this.$axios
        .get(`${APP_BILLING_API_PREFIX}${uri}`)
        .then(({ data }) => data)

      await this.$db().model(Contract).insertOrUpdate({ data })
    },
  },
}
