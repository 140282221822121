import Model from '@common/models/orm/OrmCrudModel'

export const DEFAULT_KPI_TIME_FRAME_DAYS = 28

export default class KpiStat extends Model {
  static className = 'KpiStat'
  static entity = 'kpi-stat'
  static primaryKey = ['date', 'type', 'storyId']

  static TYPE_ACTIVATION = 'activation'
  static TYPE_ENGAGEMENT = 'engagement'
  static TYPE_COMPLETION = 'completion'

  static fields() {
    return {
      storyId: this.attr(''),
      type: this.attr(''),
      date: this.number(0),
      count: this.number(0),
    }
  }
}
