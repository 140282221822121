import { required } from 'vuelidate/lib/validators'
import Story from '@common/models/orm/Story'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementStoryLink extends Model {
  static className = 'ChatElementStoryLink'

  static entity = 'story-link'

  static active = true

  static label = 'general.chat_element_story_link'

  static formFields = ['storyId', 'chatNodeVid']

  static direction = Model.INTERACTION_EFFECT

  static fields() {
    return {
      storyId: this.attr(''),
      chatNodeVid: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonStoryLink')
  }

  static getBalloonExplanation() {
    return () =>
      import('@/components/chatBalloons/ChatBalloonExplanationStoryLink')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormStoryLink')
  }

  static getTranslateForm() {
    return () =>
      import('@/components/chatForms/translations/ChatFormNoTranslate')
  }

  static getIcon() {
    return () => import('~/assets/inline/messages-bubble-forward.svg')
  }

  static getFormValidations() {
    return {
      form: {
        storyId: { required },
        chatNodeVid: {},
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
      isRoot: false,
      mustBeLast: true,
      canOnlyOccurOnce: true,
    }
  }

  props({ $store, $i18n }) {
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    const story = storyLinkTargets.find((i) => i.id === this.storyId)

    let storyName = story?.name ?? null

    // set current story to user-friendlier name
    const currentStory = $store.$db().model(Story).getters('current')

    if (currentStory?.id === story?.id) {
      storyName = $i18n.t('general.chat_element.story_link.current_balloon')
    }

    // validate goto
    const goto = story?.gotos?.find((i) => i.value === this.chatNodeVid) ?? null
    const gotoName = goto?.text ?? null

    return {
      storyId: this.storyId,
      chatNodeVid: this.chatNodeVid,
      storyName,
      gotoName,
      isPublished: story?.isPublished,
      isActive: story?.isActive,
    }
  }
}
