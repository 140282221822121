import Model from '@common/models/orm/OrmCrudModel'

export default class KpiGoal extends Model {
  static className = 'KpiGoal'
  static entity = 'kpi-goal'

  static fields() {
    return {
      id: this.attr(''),
      name: this.string(''),
      count: this.number(0),
      conversion: this.number(0),
      storyId: this.attr(''),
      ...super.fields(),
    }
  }
}
