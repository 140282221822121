import stateSetters from '@common/plugins/state-setters'
import stateGetters from '@common/plugins/state-getters'

const paymentSuccessParameter = 'paymentSuccess'

// Added by Billwerk
const paymentTransactionIdParameter = 'pactasTransactionId'
const paymentSecretParameter = 'secret'
const paymentTriggerParameter = 'trigger'
const paymentLanguageParameter = 'language'

const defaultState = {
  initialised: false,
  publicApiKey: null,
  paymentService: null,
}

export const state = () => defaultState

export const getters = {
  ...stateGetters(defaultState),
  paymentBearerType(state, getters, rootState, rootGetters) {
    return rootGetters['env-config/getConfig']?.BILLWERK_PAYMENT_BEARER
  },
}

export const mutations = {
  ...stateSetters(defaultState),
}

export const actions = {
  async initialise(
    { state, commit, dispatch },
    { publicApiKey, providerReturnUrl },
  ) {
    await dispatch('waitForSubscriptionJs')

    const paymentService = await dispatch('createPaymentService', {
      publicApiKey,
      providerReturnUrl,
    })

    commit('setPaymentService', paymentService)
    commit('setInitialised', true)
  },
  // eslint-disable-next-line require-await
  async waitForSubscriptionJs() {
    return new Promise((resolve, reject) => {
      // Wait a maximum of 50 * 100ms => 5s before aborting
      const timeout = 100
      const maximumAttempts = 50
      let attempt = 0

      const wait = () => {
        attempt++

        if (attempt > maximumAttempts) {
          reject(new Error('Could not load Billwerk SubscriptionJS in time!'))

          return
        }

        if (!window.SubscriptionJS) {
          setTimeout(wait, timeout)
        } else {
          resolve()
        }
      }

      setTimeout(wait, 0)
    })
  },
  // eslint-disable-next-line require-await
  async createPaymentService({ state }, { publicApiKey, providerReturnUrl }) {
    return new Promise((resolve, reject) => {
      const paymentConfiguration = {
        publicApiKey,
        providerReturnUrl,
      }

      // eslint-disable-next-line no-undef
      const paymentService = new SubscriptionJS.Payment(
        paymentConfiguration,
        () => {
          resolve(paymentService)
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error('Could not initialise payment service:', err)
          reject(err)
        },
      )
    })
  },
  // eslint-disable-next-line require-await
  async createPortalService({ state }, { selfServiceToken }) {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const portalService = new SubscriptionJS.Portal(selfServiceToken)

      resolve(portalService)
    })
  },
  // eslint-disable-next-line require-await
  async constructReturnUrl({ state }, url) {
    return new Promise((resolve) => {
      const parsedUrl = new URL(url)
      const queryParams = new URLSearchParams(parsedUrl.search)

      queryParams.append(paymentSuccessParameter, '1')
      parsedUrl.search = queryParams.toString()

      resolve(parsedUrl.toString())
    })
  },
  removePaymentFinalisedUrlParameter({ state }, params) {
    return new Promise((resolve) => {
      const query = { ...params }

      const parametersToRemove = [
        paymentSuccessParameter,
        paymentTransactionIdParameter,
        paymentSecretParameter,
        paymentTriggerParameter,
        paymentLanguageParameter,
      ]

      for (const parameterToRemove of parametersToRemove) {
        delete query[parameterToRemove]
      }

      resolve(query)
    })
  },
  // eslint-disable-next-line require-await
  async isPaymentSuccessUrl({ state }, url) {
    return new Promise((resolve) => {
      const parsedUrl = new URL(url)
      const queryParams = new URLSearchParams(parsedUrl.search)

      resolve(queryParams.has(paymentSuccessParameter))
    })
  },
  async changePaymentData(
    { state, dispatch },
    { selfServiceToken, paymentData },
  ) {
    const portalService = await dispatch('createPortalService', {
      selfServiceToken,
    })

    return new Promise((resolve, reject) => {
      portalService.paymentChange(
        state.paymentService,
        paymentData,
        (data) => {
          // In case of a black label provider, we need to redirect to the actual payment page
          if (data.Url) {
            window.location.href = data.Url
          }

          resolve(data)
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error('Error changing payment data', err)
          reject(err)
        },
      )
    })
  },
  // eslint-disable-next-line require-await
  async finalisePaymentProcess({ state }) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      SubscriptionJS.finalize(
        (data) => {
          resolve(data)
        },
        (err) => {
          // eslint-disable-next-line no-console
          console.error('Payment could not be successfully finalised', err)
          reject(err)
        },
      )
    })
  },
  getErrorCode({ state }, err) {
    // https://developer.billwerk.io/docs/subscriptionJS/reference/callbacks#errorerrordata
    if (!err || !err.errorCode || err.errorCode.length === 0) {
      return null
    }

    // The last item in the errorCode array contains the cause
    return err.errorCode[err.errorCode.length - 1]
  },
  getErrorCodeTranslationKey({ state }, errorCode) {
    return `error.billwerk.${errorCode.toLowerCase()}`
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
