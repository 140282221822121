import stateSetters from '@common/plugins/state-setters'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByProjectId:
      (state, { query }) =>
      (projectId) =>
        query().whereId(projectId).first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {},
}
