import { sum, toUpper } from '@common/plugins/helpers'
import Model from '@common/models/orm/OrmCrudModel'
import KpiSurveyFeedback from '@/models/orm/KpiSurveyFeedback'

export default class KpiSurvey extends Model {
  static className = 'KpiSurvey'
  static entity = 'survey'

  static TYPE_EMOJI = 'EMOJI'
  static TYPE_EMOJI_3 = 'EMOJI-3'
  static TYPE_CSAT = 'CSAT'
  static TYPE_STAR = 'STARS'
  static TYPE_NPS = 'NPS'

  static fields() {
    return {
      id: this.attr(''),
      name: this.string(''),
      type: this.string('', (value) => toUpper(value)),
      feedback: this.hasMany(KpiSurveyFeedback, 'surveyId'),
      storyId: this.attr(''),
      ...super.fields(),
    }
  }

  respondents() {
    return this.feedback.map(({ respondents }) => respondents)
  }

  get totalRespondents() {
    return sum(this.respondents())
  }

  get netPromoterScore() {
    if (this.totalRespondents === 0) {
      return 0
    }

    return Math.round(
      ((this.totalPromoters - this.totalDetractors) / this.totalRespondents) *
        100,
    )
  }

  get progressByWeek() {
    return 1.3
  }

  get detractors() {
    if (!this.isTypeNPS) {
      return []
    }

    return KpiSurveyFeedback.query()
      .where(({ surveyId, rating }) => {
        return surveyId === this.id && rating <= 6
      })
      .get()
  }

  get passives() {
    if (!this.isTypeNPS) {
      return []
    }

    return KpiSurveyFeedback.query()
      .where(({ surveyId, rating }) => {
        return surveyId === this.id && rating >= 7 && rating <= 8
      })
      .get()
  }

  get promoters() {
    if (!this.isTypeNPS) {
      return []
    }

    return KpiSurveyFeedback.query()
      .where(({ surveyId, rating }) => {
        return surveyId === this.id && rating >= 9 && rating <= 10
      })
      .get()
  }

  get totalDetractors() {
    if (!this.isTypeNPS) {
      return 0
    }

    return sum(this.detractors.map(({ respondents }) => respondents))
  }

  get totalPromoters() {
    if (!this.isTypeNPS) {
      return 0
    }

    return sum(this.promoters.map(({ respondents }) => respondents))
  }

  get totalPassives() {
    if (!this.isTypeNPS) {
      return 0
    }

    return sum(this.passives.map(({ respondents }) => respondents))
  }

  get isTypeNPS() {
    return this.type === KpiSurvey.TYPE_NPS
  }
}
