import Model from '@common/models/orm/OrmBaseModel'
import TextIcon from '~common/assets/inline/paragraph-normal.svg'
import PhoneIcon from '~/assets/inline/phone.svg'
import EmailIcon from '~common/assets/inline/email-action-unread.svg'
import FilesIcon from '~common/assets/inline/download-icon.svg'

export default class ChatbotSessionVariable extends Model {
  static className = 'ChatbotSessionVariable'

  static entity = 'chatbot-session-variables'

  static TYPE_EMAIL = 'email'

  static TYPE_PHONE = 'phone'

  static TYPE_TEXT = 'text'

  static TYPE_FILE = 'file'

  static TYPE_FILES = 'files'

  static fields() {
    return {
      name: this.attr(''),
      value: this.attr(''),
      valueType: this.attr(''),
      createdAt: this.attr(''),
      chatbotSessionId: this.attr(''),
      ...super.fields(),
    }
  }

  get icon() {
    if (this.isEmail()) {
      return EmailIcon
    }

    if (this.isPhone()) {
      return PhoneIcon
    }

    if (this.isFiles() || this.isFile()) {
      return FilesIcon
    }

    return TextIcon
  }

  isPhone() {
    return this.valueType === ChatbotSessionVariable.TYPE_PHONE
  }

  isText() {
    return this.valueType === ChatbotSessionVariable.TYPE_TEXT
  }

  isEmail() {
    return this.valueType === ChatbotSessionVariable.TYPE_EMAIL
  }

  isFiles() {
    return this.valueType === ChatbotSessionVariable.TYPE_FILES
  }

  isFile() {
    return this.valueType === ChatbotSessionVariable.TYPE_FILE
  }
}
