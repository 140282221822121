import stateSetters from '@common/plugins/state-setters'
import stateGetters from '@common/plugins/state-getters'

const defaultState = {
  isSidebarOpen: false,
}

export const state = () => defaultState

export const mutations = {
  ...stateSetters(defaultState),
  toggleSidebar(state) {
    state.isSidebarOpen = !state.isSidebarOpen
  },
}

export const getters = {
  ...stateGetters(defaultState),
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
}
