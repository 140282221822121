import Model from '@common/models/orm/OrmBaseModel'

export default class SessionUser extends Model {
  static className = 'SessionUser'

  static entity = 'session-users'
  static endpoint = 'chatbot-users'
  static SESSION_FILTER = 'chatbot.userSession.filter'

  static fields() {
    return {
      id: this.attr(''),
      lastEngagementAt: this.string('').nullable(),
      createdAt: this.string('').nullable(),
      lastFileAt: this.string('').nullable(),
      lastVariableAt: this.string('').nullable(),
      projectId: this.string('').nullable(),
      device: this.string('').nullable(),
      variables: this.attr().nullable(),
      isCreatedFromPreview: this.boolean().nullable(),
      isCreatedFromStoryBuilderPreview: this.boolean(),
    }
  }
}
