import stateSetters from '@common/plugins/state-setters'
import Customer from '@common/models/orm/Customer'
import { APP_BILLING_API_PREFIX } from '@common/plugins/api'
import { createApiUrl } from '@common/plugins/helpers'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getById:
      (state, { query }) =>
      (id) =>
        query().whereId(id).first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById({ commit, dispatch }, id) {
      await dispatch(
        'fetchByModelId',
        {
          Model: Customer,
          id,
          apiPrefix: APP_BILLING_API_PREFIX,
        },
        {
          root: true,
        },
      )

      commit('setCurrent', id)
    },
    async apiUpdateOrCreate({ dispatch }, formData) {
      const uri = createApiUrl(
        APP_BILLING_API_PREFIX.slice(1),
        Customer.endpoint,
        formData.id,
      )

      const { data } = await this.$axios.$put(uri, formData)

      if (!data) {
        return {}
      }

      const { [Customer.entity]: entities } = await this.$db()
        .model(Customer)
        .insertOrUpdate({
          data,
        })

      return entities.shift()
    },
  },
}
