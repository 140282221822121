import pkg from '@common/package'
import {
  SHOW_TEST_ATTENTION,
  SHOW_MESSAGING_INTEGRATION,
  SHOW_CHATBOT_CONTINUE_INTEGRATION,
  SHOW_MS_TEAM_OPTION,
} from '@common/constants/feature-flags'

export const AVAILABLE_FEATURE_FLAGS = [
  SHOW_TEST_ATTENTION,
  SHOW_MESSAGING_INTEGRATION,
  SHOW_CHATBOT_CONTINUE_INTEGRATION,
  SHOW_MS_TEAM_OPTION,
]
const FEATURE_FLAGS = (process.env.FEATURE_FLAGS || '')
  .replace(/\s/, '')
  .split(',')
const FEATURE_FLAGS_VERSION = pkg.version
const FEATURE_FLAGS_STORAGE_KEY = 'feature.flags'

const defaultState = {
  version: FEATURE_FLAGS_VERSION,
  features: FEATURE_FLAGS,
}

export const state = () => defaultState

export const getters = {
  allAvailable(state) {
    return AVAILABLE_FEATURE_FLAGS
  },
  all(state) {
    return state.features
  },
  isEnabled: (state) => (feature) => {
    return state.features.includes(feature)
  },
}

export const mutations = {
  setFeatures(state, features) {
    state.features = features
  },
}

export const actions = {
  async initialise({ dispatch, commit }) {
    const item = localStorage.getItem(FEATURE_FLAGS_STORAGE_KEY)

    if (item) {
      const parsedItem = JSON.parse(item)
      const features = parsedItem.features

      await commit('setFeatures', features)
      await dispatch('persist', features)

      // There were no feature flags stored so far, so store them all at once
    } else {
      await dispatch('persist', FEATURE_FLAGS)
    }
  },
  persist({ state }, features) {
    const flags = {
      version: FEATURE_FLAGS_VERSION,
      features: [...new Set(features)], // rm duplicate values
    }

    localStorage.setItem(FEATURE_FLAGS_STORAGE_KEY, JSON.stringify(flags))
  },
  async save({ state, dispatch }) {
    const features = state.features

    await dispatch('persist', features)
  },
  reset({ dispatch }) {
    localStorage.removeItem(FEATURE_FLAGS_STORAGE_KEY)
  },
  async toggle({ dispatch, state }, feature) {
    const currentlyEnabled = state.features.includes(feature)

    if (!currentlyEnabled) {
      await dispatch('enable', feature)
    } else {
      await dispatch('disable', feature)
    }
  },

  async enable({ state, commit, dispatch }, feature) {
    if (state.features.includes(feature)) {
      return
    }

    const features = [...state.features, feature]

    await commit('setFeatures', features)
  },
  async disable({ state, commit, dispatch }, feature) {
    if (!state.features.includes(feature)) {
      return
    }

    const features = state.features.filter((f) => f !== feature)

    await commit('setFeatures', features)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
