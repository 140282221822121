import Model from '@common/models/orm/OrmBaseModel'

export default class SlotsEntity extends Model {
  static className = 'SlotsEntity'

  static entity = 'slots-entities'

  static SYSTEM_ENTITIES = ['email', 'location', 'phone', 'time']

  static VALID_COUNTRIES = [
    { value: 'CH', label: 'general.country.switzerland' },
    { value: 'DE', label: 'general.country.germany' },
    { value: 'AT', label: 'general.country.austria' },
  ]

  static TIME = 'Time'
  static LOCATION = 'Location'
  static EMAIL = 'Email'
  static PHONE = 'Phone'

  static fields() {
    return {
      text: this.string(''),
      value: this.string(''),
    }
  }
}
