async function getCurrentModel(app, entityName) {
  const current = `entities/${entityName}/current`

  if (!app.store.getters[current]) {
    await app.store.dispatch('initializeOrm')
  }

  return app.store.getters[current]
}

export default async ({ app, route }) => {
  // With empty Bearer Token directly go to login
  if (!app.$auth.loggedIn) {
    app.$auth.redirect('login')

    return
  }

  await app.store.dispatch('feature-flags/initialise')

  // Always load the current user details
  try {
    await app.store.dispatch('entities/users/fetchMe')

    const user = await getCurrentModel(app, 'users')
    const { preferredLanguage } = user

    app.i18n.setLocale(preferredLanguage)
  } catch (error) {
    if (error.response && error.response.status === 401) {
      return
    }

    // '/user/me' has thrown an 404, so Bearer token is invalid. Reset it.
    app.$auth.setToken('local', false)
    // user needs to login
    app.$auth.redirect('login')

    throw new Error(error)
  }

  const name = route.name

  if (!name) {
    return
  }

  // Load workspace if it has not been loaded
  if (!app.store.getters['entities/workspaces/defaultWorkspace']) {
    await app.store.dispatch('entities/workspaces/fetchAll')

    try {
      // Set current workspace to the default workspace
      const { id: workspaceId } =
        app.store.getters['entities/workspaces/defaultWorkspace']

      await app.store.dispatch('entities/workspaces/setCurrent', workspaceId)
    } catch (e) {}
  }
}
