import { required } from 'vuelidate/lib/validators'
import { inArray } from '@common/plugins/validators'
import {
  DOWNLOAD_TYPE_UPLOAD,
  DOWNLOAD_TYPE_URL,
  DOWNLOAD_TYPE_VARIABLE,
} from '@common/constants/download-types'

import { STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS } from '@common/constants/project-colors'

import ProjectVariable from '@common/models/orm/ProjectVariable'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import Model from '@/models/chatElements/ChatElementBaseModel'

const FailIcon = () => import('~/assets/inline/cross-circle.svg')
const SuccessIcon = () => import('~/assets/inline/check-box.svg')

export const allowedSourceTypes = [
  {
    label: 'general.chat_element.file_download.upload',
    key: DOWNLOAD_TYPE_UPLOAD,
  },
  { label: 'general.chat_element.file_download.url', key: DOWNLOAD_TYPE_URL },
  {
    label: 'general.chat_element.file_download.variable',
    key: DOWNLOAD_TYPE_VARIABLE,
  },
]

// cant use FILE_TYPE_SPECIFIERS because svg not supported
export const allowedFileTypes = [
  'text/txt',
  'application/rtf',
  'text/csv',
  'application/odt',
  'application/ods',
  'application/odp',
  'application/doc',
  'application/docx',
  'application/ppt',
  'application/pptx',
  'application/xls',
  'application/xlsx',
  'application/pdf',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'video/mp4',
]

const VAR_TAG_RE = /\${([A-z0-9-]+)}/gi

export default class ChatElementFileDownload extends Model {
  static className = 'ChatElementFileDownload'
  static entity = 'download'
  static active = true

  static label = 'general.chat_element_file_download'

  static formFields = [
    'title',
    'sourceType',
    'url',
    'content',
    'fileId',
    'filePath',
  ]

  static fields() {
    return {
      title: this.attr(''),
      sourceType: this.attr(''),
      url: this.attr(''),
      content: this.attr(''),
      fileId: this.attr(''),
      filePath: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonDownload')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormDownloadFile')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormFileDownload/ChatFormFileDownloadTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/file-download.svg')
  }

  static getAvailableModifiers() {
    return []
  }

  static getFormValidations() {
    return {
      form: {
        sourceType: {
          required,
          missingSelection: inArray(allowedSourceTypes.map((item) => item.key)),
        },
        url: {},
        title: {},
        content: {},
        fileId: {
          required,
        },
        filePath: {},
      },
      variableId: {},
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        content: {},
      },
    }
  }

  static getUsedVariables({ title, fileId, url, content }) {
    const fileIdContent = fileId ? '${' + fileId + '}' : null

    return [
      ...Array.from(url ? url.matchAll(VAR_TAG_RE) : []).map(([, id]) => ({
        id,
      })),
      ...Array.from(content ? content.matchAll(VAR_TAG_RE) : []).map(
        ([, id]) => ({ id }),
      ),
      ...Array.from(title ? title.matchAll(VAR_TAG_RE) : []).map(([, id]) => ({
        id,
      })),
      ...Array.from(
        fileIdContent ? fileIdContent.matchAll(VAR_TAG_RE) : [],
      ).map(([, id]) => ({
        id,
      })),
    ]
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      sourceType: this.sourceType,
      url: this.url,
      content: this.content,
      title: this.title,
      fileId: this.fileId,
      filePath: this.filePath,
      variables,
      storyLinkTargets,
    }
  }

  /**
   * Return a custom title or default fallback with type indication
   * @param {Object} i18n
   * @returns {string}
   */
  getTitle(i18n) {
    if (this.title) {
      // {type}: {title}
      return [i18n.t(this.constructor.label), this.title].join(' : ')
    }

    // {label}: {type}
    return [i18n.t(this.constructor.label)].join(' : ')
  }

  /**
   *
   * @returns {Object}
   */
  getSourceTypeConfig() {
    return allowedSourceTypes.find(({ key }) => key === this.sourceType)
  }

  static getSourceTypes() {
    return allowedSourceTypes
  }

  static defaultType() {
    return allowedSourceTypes[0]
  }

  static getResponses(i18n = {}) {
    return [
      {
        label: i18n.t('general.chat_element.file_download.success'),
        color: STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS.positive,
        icon: SuccessIcon,
      },
      {
        label: i18n.t('general.chat_element.file_download.error'),
        color: STORY_BUILDER_BUTTON_LIST_LIVE_CHAT_COLORS.negative,
        icon: FailIcon,
      },
    ]
  }
}
