export const monetaryAmount = (
  amount,
  currency = 'CHF',
  precision = 2,
  locale = 'en',
  omitZeroFractionDigits = false,
) => {
  // Bounds check as Intl.NumberFormat only allows maximumFractionDigits of 0-20
  const minimumFractionDigits =
    precision >= 0 && precision <= 20 ? precision : 2

  // If locale is German, force Swiss German locale -- it's just the nicer format ;)
  if (locale.toLowerCase() === 'de') {
    locale = 'de-CH'
  }

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency.toUpperCase(),
    currencyDisplay: 'code',
    minimumFractionDigits,
    useGrouping: true,
  })

  const formatted = formatter.format(amount)

  // Special case: Integer amounts in Swiss Franks (CHF) can be written like 29.–
  if (omitZeroFractionDigits && Number.isInteger(amount)) {
    // This is a bit tricky as depending on the locale the delimiter is ., or something else
    // and the currency might be on the left or right of the amount
    const delimiterPosition = formatted.indexOf(amount)
    const currencyPosition = formatted.indexOf(currency.toUpperCase())
    const currencyOnLeft = currencyPosition < delimiterPosition

    if (currencyOnLeft) {
      return `${currency.toUpperCase()} ${amount}.–`
    }

    return `${amount}.– ${currency.toUpperCase()}`
  }

  return formatted
}
