export default async ({ store }, inject) => {
  const cfg = await store.dispatch(
    'env-config/fetchConfig',
    'chatbot-web-app-config.json',
  )

  const info = await store.dispatch('env-config/fetchInfo', {
    fileName: 'chatbot-web-app-info.json',
    module: 'chatbot',
  })

  inject('cfg', cfg)
  inject('info', info)
}
