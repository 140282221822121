import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'
import { inArray, isUuid4 } from '@common/plugins/validators'
import { toEmailId, toLower } from '@common/plugins/helpers'
import Model from '@common/models/orm/OrmCrudModel'
import Workspace from '@common/models/orm/Workspace'

export default class Invitation extends Model {
  static className = 'Invitation'

  static entity = 'invitations'

  static STATUS_PENDING = 'pending'
  static STATUS_ACCEPTED = 'accepted'
  static STATUS_CANCELED = 'canceled'

  static formFields = ['email', 'projects', 'role', 'token', 'workspaceId']

  static fields() {
    return {
      id: this.attr(''),
      email: this.attr(''),
      avatar: this.string(''),
      projects: this.attr([]),
      role: this.attr(Workspace.ROLE_MEMBER),
      status: this.attr(Invitation.STATUS_PENDING),
      workspaceId: this.attr(''),
      ...super.fields(),
    }
  }

  get name() {
    return toEmailId(this.email)
  }

  static getRoles() {
    return [Workspace.ROLE_MEMBER, Workspace.ROLE_PROJECT_MEMBER].map(
      (value) => ({
        key: value.replace(/\//g, '_'),
        value,
      }),
    )
  }

  static getRoleValidation() {
    return {
      role: {
        required,
        missingSelection: inArray(
          Invitation.getRoles().map(({ value }) => toLower(value)),
        ),
      },
    }
  }

  static getFormValidations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        token: {
          required,
          isUuid4,
        },
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
        lastName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(64),
        },
      },
    }
  }

  get isProjectMember() {
    return this.role === Workspace.ROLE_PROJECT_MEMBER
  }
}
