import Model from '@common/models/orm/OrmCrudModel'

export default class StoryLinkTarget extends Model {
  static className = 'StoryLinkTarget'
  static entity = 'storylink-target'

  static fields() {
    return {
      id: this.attr(''),
      name: this.string(''),
      isPublished: this.boolean(false),
      isActive: this.boolean(false),
      gotos: this.attr([]),
    }
  }
}
