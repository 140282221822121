import Model from '@common/models/orm/OrmCrudModel'

export const PLAN_INACTIVE = 'inactive'

export default class Language extends Model {
  static className = 'Language'

  static entity = 'languages'

  static fields() {
    return {
      id: this.attr(''),
      isActive: this.boolean(false),
      isCustom: this.boolean(false),
      isTranslationInProgress: this.boolean(false),
      translationUpdatedAt: this.string(''),
      language: this.attr(null),
      ...super.fields(),
    }
  }
}
