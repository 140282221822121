import Model from '@common/models/orm/OrmBaseModel'

export default class Timezone extends Model {
  static className = 'Timezone'
  static endpoint = 'timezones'
  static entity = 'time-zones'

  static DEFAULT_TIMEZONE = {
    name: 'Europe/Zurich',
    offset: '+02:00',
    includeInShortList: true,
  }

  static fields() {
    return {
      id: this.uid(),
      name: this.string(''),
      offset: this.string(''),
      includeInShortList: this.boolean(false),
    }
  }
}
