import Vue from 'vue'
import VTooltip, { options } from 'v-tooltip'
import 'v-tooltip/dist/v-tooltip.css'

options.disposeTimeout = 100

Vue.use(VTooltip, {
  themes: {
    'sb-widgets-disabled': {
      $extend: 'tooltip',
    },
  },
})
