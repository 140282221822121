import { Model } from '@vuex-orm/core'
import { camelCaseToDash } from '@common/plugins/helpers'

export default class OrmBaseModel extends Model {
  static formFields = []

  static getFormValidations() {
    return {
      form: {},
    }
  }

  static slug() {
    return camelCaseToDash(this.className)
  }

  static fields() {
    return {
      createdAt: this.attr(''),
      updatedAt: this.attr(''),
    }
  }

  getFormJson() {
    const result = {}
    const json = JSON.parse(JSON.stringify(this.$toJson()))

    this.constructor.formFields.forEach((key) => {
      result[key] = json[key]
    })

    return result
  }

  get detailsRoute() {
    return {
      name: this.constructor.slug() + '-id',
      params: {
        id: this.$id,
      },
    }
  }

  getApiDetailsUrl() {
    return ['', this.constructor.entity, this.$id].join('/')
  }

  get redirectAfterDeleteRoute() {
    return 'index'
  }
}
