import stateSetters from '@common/plugins/state-setters'
import Trigger from '@common/models/orm/Trigger'
import Story from '@common/models/orm/Story'
import { createApiUrl } from '@common/plugins/helpers'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByStoryId: (state, { query }) => {
      return (storyId) =>
        query()
          .where('storyId', (value) => value === storyId)
          .orderBy('path', 'asc')
          .withAll()
          .get()
    },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async apiUpdateOrCreate({ dispatch }, formData) {
      const apiAction = formData.id ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(Trigger.entity, formData.id)
      const { data } = await apiAction(url, formData)

      if (!data) {
        return {}
      }

      const { [Trigger.entity]: entities } = await dispatch('insertOrUpdate', {
        entity: Trigger.entity,
        data,
      })

      return entities.shift()
    },
    async fetchById({ dispatch }, id) {
      await dispatch(
        'fetchByModelId',
        { Trigger, id },
        {
          root: true,
        },
      )
    },
    async setCurrent({ commit }, id) {
      await commit('setCurrent', id)
    },
    async fetchByStoryId({ commit }, id) {
      const url = createApiUrl(Story.entity, id, Trigger.entity)
      const { data: triggers } = await this.$axios
        .get(url, { expectList: true })
        .then(({ data }) => data)

      await Trigger.deleteAll()
      await Trigger.insertOrUpdate({
        data: triggers,
      })
    },
    async fetchByOnlyStoryId({ commit }, id) {
      const url = createApiUrl(Story.entity, id, Trigger.entity)
      const { data: triggers } = await this.$axios
        .get(url, { expectList: true })
        .then(({ data }) => data)

      await Trigger.insertOrUpdate({
        data: triggers,
      })
    },
    async deleteById({ commit }, id) {
      const url = createApiUrl(Trigger.entity, id)

      await this.$axios.delete(url)
      await Trigger.delete(id)
    },

    async fetchTriggerConditions({ commit }, triggerId) {
      const url = createApiUrl(Trigger.entity, triggerId)

      const { data } = await this.$axios.get(`${url}/conditions`)

      return data.data
    },

    async updateTriggerCondition({ commit }, data) {
      await this.$axios.post(`/trigger-conditions`, data)
    },

    async deleteTriggerCondition({ commit }, triggerConditionId) {
      await this.$axios.delete(`/trigger-conditions/${triggerConditionId}`)
    },

    async updateTriggerPosition({ dispatch }, formData) {
      const apiAction = formData.id ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(Trigger.entity, formData.id)
      const { data } = await apiAction(url + '/position', formData)

      if (!data) {
        return {}
      }

      await dispatch('insertOrUpdate', {
        entity: Trigger.entity,
        data,
      })

      return []
    },
  },
}
