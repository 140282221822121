import Model from '@common/models/orm/OrmCrudModel'

export const PLAN_INACTIVE = 'inactive'

export default class DeeplLanguage extends Model {
  static className = 'DeeplLanguage'

  static entity = 'deepl-languages'

  static fields() {
    return {
      id: this.attr(''),
      abbr: this.string(''),
      name: this.string(''),
      ...super.fields(),
    }
  }
}
