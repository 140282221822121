import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { MODIFIER_SURVEY_USER_INTERACTION_REQUIRED } from '@common/constants/chat-element-modifiers'
import { inArray } from '@common/plugins/validators'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import Model from '@/models/chatElements/ChatElementBaseModel'

const allowedSurveyTypes = [
  {
    label: 'form.survey_types.nps',
    description: 'form.survey_types.nps.description',
    icon: () => import(`@/assets/inline/survey-nps.svg`),
    key: 'nps',
    min: 0,
    max: 10,
    default: 6,
    map: {
      0: [9, 10],
      1: [7, 8],
      2: [0, 1, 2, 3, 4, 5, 6],
    },
  },
  {
    label: 'form.survey_types.csat',
    description: 'form.survey_types.csat.description',
    icon: () => import(`@/assets/inline/survey-csat.svg`),
    key: 'csat',
    min: 1,
    max: 5,
    default: 4,
    map: {
      0: [4, 5],
      1: [3],
      2: [1, 2],
    },
  },
  {
    label: 'form.survey_types.emoji',
    description: 'form.survey_types.emoji.description',
    icon: () => import(`@/assets/inline/survey-emoji.svg`),
    key: 'emoji',
    min: 1,
    max: 5,
    default: 4,
    map: {
      0: [5],
      1: [3, 4],
      2: [1, 2],
    },
  },
  {
    label: 'form.survey_types.emoji_3',
    description: 'form.survey_types.emoji_3.description',
    icon: () => import(`@/assets/inline/survey-emoji-3.svg`),
    key: 'emoji-3',
    min: 1,
    max: 3,
    default: 2,
    map: {
      0: [3],
      1: [2],
      2: [1],
    },
  },
  {
    label: 'form.survey_types.stars',
    description: 'form.survey_types.stars.description',
    icon: () => import(`@/assets/inline/survey-stars.svg`),
    key: 'stars',
    min: 1,
    max: 5,
    default: 4,
    map: {
      0: [5],
      1: [3, 4],
      2: [1, 2],
    },
  },
]

export default class ChatElementSurvey extends Model {
  static className = 'ChatElementSurvey'

  static entity = 'survey'

  static active = true

  static label = 'general.chat_element_survey'

  static formFields = ['surveyType', 'title', 'content', 'defaultValue']

  static direction = Model.INTERACTION_IN

  static fields() {
    return {
      surveyType: this.attr(''),
      title: this.attr(''),
      content: this.attr(''),
      defaultValue: this.attr(5),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonSurvey')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormSurvey')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormSurvey/ChatFormSurveyTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/user-question.svg')
  }

  static getAvailableModifiers() {
    return [MODIFIER_SURVEY_USER_INTERACTION_REQUIRED]
  }

  static getFormValidations() {
    return {
      form: {
        surveyType: {
          required,
          missingSelection: inArray(allowedSurveyTypes.map((item) => item.key)),
        },
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(64),
        },
        content: {},
      },
      variableId: {},
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        title: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(64),
        },
        content: {},
      },
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
      isRoot: true,
    }
  }

  /**
   * Return a custom title or default fallback with type indication
   * @param {Object} i18n
   * @returns {string}
   */
  getTitle(i18n) {
    const type = this.getSurveyTypeConfig().label

    if (this.title) {
      // {type}: {title}
      return [i18n.t(type), this.title].join(': ')
    }

    // {label}: {type}
    return [i18n.t(this.constructor.label), i18n.t(type)].join(': ')
  }

  /**
   * @param {String} surveyType
   * @param {Object} i18n
   * @returns {Array}
   */
  static getResponsesByType(surveyType = '', i18n = {}) {
    const responses = {
      nps: [
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.positive'),
          value: '9-10',
        }),
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.neutral'),
          value: '7-8',
        }),
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.negative'),
          value: '0-6',
        }),
      ],
      csat: [
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.satisfied'),
          value: '4-5',
        }),
        i18n.tc('md.survey.sentiment_description', 1, {
          sentiment: i18n.t('general.neutral'),
          value: '3',
        }),
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.unsatisfied'),
          value: '1-2',
        }),
      ],
      emoji: [
        i18n.tc('md.survey.sentiment_description', 1, {
          sentiment: i18n.t('general.positive'),
          value: '5',
        }),
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.neutral'),
          value: '3-4',
        }),
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.negative'),
          value: '1-2',
        }),
      ],
      'emoji-3': [
        i18n.tc('md.survey.sentiment_description', 1, {
          sentiment: i18n.t('general.positive'),
          value: '3',
        }),
        i18n.tc('md.survey.sentiment_description', 1, {
          sentiment: i18n.t('general.neutral'),
          value: '2',
        }),
        i18n.tc('md.survey.sentiment_description', 1, {
          sentiment: i18n.t('general.negative'),
          value: '1',
        }),
      ],
      stars: [
        i18n.tc('md.survey.sentiment_description', 1, {
          sentiment: i18n.t('general.positive'),
          value: '5',
        }),
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.neutral'),
          value: '3-4',
        }),
        i18n.tc('md.survey.sentiment_description', 2, {
          sentiment: i18n.t('general.negative'),
          value: '1-2',
        }),
      ],
    }

    return responses[surveyType] || []
  }

  /**
   * @returns {Object}
   */
  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      surveyType: this.surveyType,
      surveyTypeConfig: this.getSurveyTypeConfig(),
      getReplyByInput: this.getReplyByInput(),
      getRangeFromResponse: this.getRangeFromResponse(),
      content: this.content,
      userSetDefaultValue: this.defaultValue,
      variables,
      storyLinkTargets,
    }
  }

  /**
   *
   * @returns {Object}
   */
  getSurveyTypeConfig() {
    return allowedSurveyTypes.find(({ key }) => key === this.surveyType)
  }

  /**
   *
   * @returns {Function}
   */
  getReplyByInput() {
    const map = this.getSurveyTypeConfig().map

    return (value) => {
      if (map.length === 0) {
        return 0
      }

      const keys = Object.keys(map)

      for (const reply of keys) {
        if (map[reply].includes(parseInt(value))) {
          return parseInt(reply)
        }
      }

      return 0
    }
  }

  /**
   * @returns {Function}
   */
  getRangeFromResponse() {
    const map = this.getSurveyTypeConfig().map

    return (value) => {
      if (map.length === 0) {
        return -1
      }

      return map[value][0]
    }
  }

  /**
   *
   * @returns {*[]}
   */
  static getSurveyTypes() {
    return allowedSurveyTypes
  }

  /**
   *
   * @returns {Object}
   */
  static getSurveyTypeConfigByKey(findKey) {
    return allowedSurveyTypes.find(({ key }) => key === findKey)
  }

  /**
   *
   * @returns {{default, min, max, responses, label, map, key}|*}
   */
  static defaultType() {
    return allowedSurveyTypes[0]
  }

  /**
   *
   * @returns {*[]}
   */
  static emojiData(emojiType) {
    if (emojiType === 'emoji-3') {
      return [
        {
          id: 1,
          key: 'very_unsatisfied',
          label: 'Angry Face',
          emoji: '😡', // not used, just a fallback
          file: 'angry-face.png',
        },
        {
          id: 2,
          key: 'neutral',
          label: 'Neutral Face',
          emoji: '😶', // not used, just a fallback
          file: 'neutral-face.png',
        },
        {
          id: 3,
          key: 'very_satisfied',
          label: 'Smiling face with open Mouth',
          emoji: '😃', // not used, just a fallback
          file: 'smiling-face-with-open-mouth.png',
        },
      ]
    }

    return [
      {
        id: 1,
        key: 'very_unsatisfied',
        label: 'Angry Face',
        emoji: '😡', // not used, just a fallback
        file: 'angry-face.png',
      },
      {
        id: 2,
        key: 'unsatisfied',
        label: 'Disappointed Face',
        emoji: '😞', // not used, just a fallback
        file: 'disappointed-face.png',
      },
      {
        id: 3,
        key: 'neutral',
        label: 'Neutral Face',
        emoji: '😶', // not used, just a fallback
        file: 'neutral-face.png',
      },
      {
        id: 4,
        key: 'satisfied',
        label: 'Slightly Smiling Face',
        emoji: '🙂', // not used, just a fallback
        file: 'slightly-smiling-face.png',
      },
      {
        id: 5,
        key: 'very_satisfied',
        label: 'Smiling face with open Mouth',
        emoji: '😃', // not used, just a fallback
        file: 'smiling-face-with-open-mouth.png',
      },
    ]
  }
}
