import Story from '@common/models/orm/Story'
import FaqQuestion from '@common/models/orm/FaqQuestion'

export default {
  namespaced: true,
  actions: {
    async fetchFAQQuestions({ dispatch }, storyId) {
      const { data } = await this.$db()
        .model(Story)
        .dispatch('fetchChatNodeData', {
          id: storyId,
          node: 'faq',
          endpoint: 'options',
        })

      if (!data) {
        return
      }

      // we create instead of insertOrUpdate to clear the outdated ones between stories
      await this.$db()
        .model(FaqQuestion)
        .create({
          data: data.entities.map(({ value, text }) => ({
            id: value,
            name: text,
          })),
        })

      return data
    },
  },
}
