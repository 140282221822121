import Model from '@common/models/orm/OrmCrudModel'

export default class FaqQuestion extends Model {
  static className = 'FaqQuestion'
  static entity = 'faq-question'

  static fields() {
    return {
      id: this.attr(''),
      name: this.string(''),
    }
  }
}
