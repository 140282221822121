import stateSetters from '@common/plugins/state-setters'
import Story from '@common/models/orm/Story'
import SlotsEntity from '@/models/orm/SlotsEntity'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByStoryId({ dispatch }, { id }) {
      const { data } = await this.$db().model(Story).dispatch(
        'fetchChatNodeData',
        {
          id,
          node: 'slots',
          endpoint: 'options',
        },
        { root: true },
      )

      const { entities } = data

      await SlotsEntity.create({
        data: entities.map(({ value, text }) => ({
          value,
          text,
        })),
      })
    },
  },
}
