import { required, maxLength } from 'vuelidate/lib/validators'
import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementGoTo extends Model {
  static className = 'ChatElementGoTo'

  static entity = 'goto'

  static label = 'general.chat_element_goto'

  static formFields = ['name']

  static direction = Model.INTERACTION_EFFECT

  static fields() {
    return {
      name: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonGoal')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormGoTo')
  }

  static getTranslateForm() {
    return () =>
      import('@/components/chatForms/translations/ChatFormNoTranslate')
  }

  static getIcon() {
    return () => import('~/assets/inline/goto.svg')
  }

  static getFormValidations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
    }
  }

  props() {
    return {
      name: this.name,
    }
  }
}
