import Model from '@common/models/orm/OrmBaseModel'
import ChatbotSessionVariable from '@/models/orm/ChatbotSessionVariable'

export default class ChatbotSessionHandover extends Model {
  static className = 'ChatbotSessionHandover'

  static entity = 'chatbot-session-handovers'

  static fields() {
    return {
      id: this.attr(''),
      startedAt: this.attr(''),
      description: this.attr(''),
      variables: this.attr([]),
      ...super.fields(),
    }
  }

  get variableList() {
    return this.variables.map((data) => new ChatbotSessionVariable(data))
  }
}
