import { required } from 'vuelidate/lib/validators'
import { inArray } from '@common/plugins/validators'
import {
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_LINK,
  MEDIA_TYPE_VIMEO,
  MEDIA_TYPE_YOUTUBE,
} from '@common/constants/media-types'
import {
  MODIFIER_FOCUS_MODE,
  MODIFIER_READ_TIME,
  MODIFIER_SESSION_ONCE,
  MODIFIER_DONT_ENLARGE_IMAGE,
  MODIFIER_HIDDEN_FOR_MESSAGING_API,
  MODIFIER_VISIBLE_ONLY_FOR_MESSAGING_API,
} from '@common/constants/chat-element-modifiers'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import Model from '@/models/chatElements/ChatElementBaseModel'

export const allowedMediaTypes = [
  { label: 'form.media_type.image', key: MEDIA_TYPE_IMAGE },
  { label: 'form.media_type.vimeo', key: MEDIA_TYPE_VIMEO },
  { label: 'form.media_type.youtube', key: MEDIA_TYPE_YOUTUBE },
  { label: 'form.media_type.anchor_link', key: MEDIA_TYPE_LINK },
]

// cant use FILE_TYPE_SPECIFIERS because svg not supported
export const allowedFileTypes = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/jpg',
]

const VAR_TAG_RE = /\${([A-z0-9-]+)}/gi

export default class ChatElementMedia extends Model {
  static className = 'ChatElementMedia'
  static entity = 'media'
  static active = true

  static label = 'general.chat_element_media'

  static formFields = ['mediaType', 'url', 'description']

  static fields() {
    return {
      mediaType: this.attr(''),
      url: this.attr(''),
      description: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonMedia')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormMedia')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormMedia/ChatFormMediaTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/video-player-movie.svg')
  }

  static getAvailableModifiers() {
    return [
      MODIFIER_FOCUS_MODE,
      MODIFIER_READ_TIME,
      MODIFIER_SESSION_ONCE,
      MODIFIER_DONT_ENLARGE_IMAGE,
      MODIFIER_HIDDEN_FOR_MESSAGING_API,
      MODIFIER_VISIBLE_ONLY_FOR_MESSAGING_API,
    ]
  }

  static getFormValidations() {
    return {
      form: {
        mediaType: {
          required,
          missingSelection: inArray(allowedMediaTypes.map((item) => item.key)),
        },
        url: {
          required,
        },
        description: {},
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        description: {},
      },
    }
  }

  static getUsedVariables({ url, description }) {
    return [
      ...Array.from(url.matchAll(VAR_TAG_RE)).map(([, id]) => ({
        id,
      })),
      ...Array.from(description.matchAll(VAR_TAG_RE)).map(([, id]) => ({ id })),
    ]
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()

    return {
      mediaType: this.mediaType,
      url: this.url,
      description: this.description,
      variables,
    }
  }

  /**
   * Return a custom title or default fallback with type indication
   * @param {Object} i18n
   * @returns {string}
   */
  getTitle(i18n) {
    const type = this.getMediaTypeConfig().label

    if (this.title) {
      // {type}: {title}
      return [i18n.t(type), this.title].join(': ')
    }

    // {label}: {type}
    return [i18n.t(this.constructor.label), i18n.t(type)].join(': ')
  }

  /**
   *
   * @returns {Object}
   */
  getMediaTypeConfig() {
    return allowedMediaTypes.find(({ key }) => key === this.mediaType)
  }

  static getMediaTypes() {
    return allowedMediaTypes
  }

  static defaultType() {
    return allowedMediaTypes[0]
  }
}
