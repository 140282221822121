import Model from '@common/models/orm/OrmCrudModel'

export default class ProjectVariable extends Model {
  static className = 'ProjectVariable'
  static entity = 'project-variable'
  static endpoint = 'variables'

  static TYPE_ALL = 'all'

  static TYPE_TEXT = 'text'
  static TYPE_EMAIL = 'email'
  static TYPE_PHONE = 'phone'
  static TYPE_LOCATION = 'location'
  static TYPE_DATETIME = 'datetime'
  static TYPE_DATE = 'date'
  static TYPE_DATE_RANGE = 'date_range'
  static TYPE_DATETIME_RANGE = 'datetime_range'
  static TYPE_NER_CUSTOM_ENTITY = 'ner_custom_entity'

  static TYPE_FILE = 'file'
  static TYPE_FILES = 'files'

  static TYPE_LIST = 'named_list'
  static TYPE_CAROUSEL = 'carousel'
  static TYPE_CAROUSEL_ITEM = 'carousel_item'

  static TYPES = [
    { value: this.TYPE_ALL, text: 'general.variables.type.all' },
    { value: this.TYPE_TEXT, text: 'general.variables.type.text' },
    { value: this.TYPE_EMAIL, text: 'general.variables.type.email' },
    { value: this.TYPE_PHONE, text: 'general.variables.type.phone' },
    { value: this.TYPE_LOCATION, text: 'general.variables.type.location' },
    { value: this.TYPE_DATETIME, text: 'general.variables.type.datetime' },
    { value: this.TYPE_DATE, text: 'general.variables.type.date' },
    { value: this.TYPE_DATE_RANGE, text: 'general.variables.type.date_range' },
    {
      value: this.TYPE_DATETIME_RANGE,
      text: 'general.variables.type.datetime_range',
    },
    {
      value: this.TYPE_NER_CUSTOM_ENTITY,
      text: 'general.variables.type.ner_custom_entity',
    },
    { value: this.TYPE_FILE, text: 'general.variables.type.file' },
    { value: this.TYPE_FILES, text: 'general.variables.type.files' },
    { value: this.TYPE_LIST, text: 'general.variables.type.list' },
    { value: this.TYPE_CAROUSEL, text: 'general.variables.type.carousel' },
    {
      value: this.TYPE_CAROUSEL_ITEM,
      text: 'general.variables.type.carousel_item',
    },
  ]

  static fields() {
    return {
      id: this.attr(''),
      name: this.string(''),
      type: this.string(''),
      projectId: this.string(''),
      isConfig: this.boolean(false),
    }
  }
}
