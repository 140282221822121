import { requiredIf } from 'vuelidate/lib/validators'
import Story from '@common/models/orm/Story'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementSubStory extends Model {
  static className = 'ChatElementSubStory'

  static entity = 'substory'

  static active = true

  static label = 'general.chat_element_sub_story'

  static formFields = [
    'loadFromVariable',
    'storyId',
    'chatNodeVid',
    'variableStoryId',
    'variableChatNodeVid',
  ]

  static direction = Model.INTERACTION_EFFECT

  static fields() {
    return {
      loadFromVariable: this.boolean(false),
      storyId: this.attr(''),
      chatNodeVid: this.attr(''),
      variableStoryId: this.attr(''),
      variableChatNodeVid: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonSubStory')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormSubStory')
  }

  static getTranslateForm() {
    return () =>
      import('@/components/chatForms/translations/ChatFormNoTranslate')
  }

  static getIcon() {
    return () => import('~/assets/inline/substory.svg')
  }

  static getFormValidations() {
    return {
      form: {
        storyId: {
          required: requiredIf(({ loadFromVariable }) => !loadFromVariable),
        },
        variableStoryId: {
          required: requiredIf(({ loadFromVariable }) => loadFromVariable),
        },
        chatNodeVid: {},
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
    }
  }

  props({ $store, $i18n }) {
    const substoryTargets = $store.$db().model(StoryLinkTarget).all()

    const story = substoryTargets.find((i) => i.id === this.storyId)

    // set current story to user-friendlier name
    const currentStory = $store.$db().model(Story).getters('current')

    let storyName = story?.name ?? null

    if (currentStory?.id === story?.id) {
      storyName = $i18n.t('general.chat_element.story_link.current_balloon')
    }

    // validate goto
    const goto = story?.gotos?.find((i) => i.value === this.chatNodeVid) ?? null
    const gotoName = goto?.text ?? null

    return {
      storyId: this.storyId,
      chatNodeVid: this.chatNodeVid,
      storyName,
      gotoName,
      isPublished: story?.isPublished,
      isActive: story?.isActive,
      variable: this.variable,
      variableStoryId: this.variableStoryId,
      variableChatNodeVid: this.variableChatNodeVid,
    }
  }
}
