import { required, maxLength } from 'vuelidate/lib/validators'
import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementGoal extends Model {
  static className = 'ChatElementGoal'

  static entity = 'goal'

  static label = 'general.chat_element_goal'

  static formFields = ['name']

  static direction = Model.INTERACTION_EFFECT

  static fields() {
    return {
      name: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonGoal')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormGoal')
  }

  static getTranslateForm() {
    return () =>
      import('@/components/chatForms/translations/ChatFormNoTranslate')
  }

  static getIcon() {
    return () => import('~/assets/inline/chat-goal.svg')
  }

  static getFormValidations() {
    return {
      form: {
        name: {
          required,
          maxLength: maxLength(64),
        },
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {},
    }
  }

  static getGraphValidations() {
    return {
      ...super.getGraphValidations(),
    }
  }

  props() {
    return {
      name: this.name,
    }
  }
}
