export default class ModelFactoryBase {
  static map = [
    {
      entity: '',
      Model: {},
      instance: {},
      label: '',
    },
  ]

  static getTypes() {
    return this.map
  }

  static isValidType(type) {
    return !!this.getModelByType(type)
  }

  static getModelByType(type) {
    if (!type) {
      throw new Error('Can not create Model from empty type definition')
    }

    return this.getTypes().find((item) => item.entity === type)
  }

  static create(data, type = '') {
    if (typeof data === 'string') {
      type = data
      data = {}
    } else if (typeof data === 'object') {
      type = data._type || type
    }

    const Model = this.getClass(type)

    return new Model(data)
  }

  static getClass(type) {
    const item = this.getModelByType(type)

    if (item) {
      return item.Model
    }

    throw new Error('Unknown type definition ' + item)
  }
}
