import stateSetters from '@common/plugins/state-setters'
import Model from '@common/models/orm/Group'
import Project from '@common/models/orm/Project'
import { createApiUrl } from '@common/plugins/helpers'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByProjectId:
      (state, { query }) =>
      (projectId) =>
        query()
          .where('projectId', (value) => value === projectId)
          .orderBy('position', 'asc')
          .withAll()
          .get(),
    getByStoryId:
      (state, { query }) =>
      (projectId, storyId) => {
        return query()
          .where(
            (group) =>
              group.projectId === projectId && group.items.includes(storyId),
          )
          .first()
      },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchAll({ dispatch }, projectId, entityType = 'story') {
      const url = createApiUrl(Project.entity, projectId, Model.entity)

      const query = new URLSearchParams()

      query.append('entityType', entityType)

      try {
        const { data } = await this.$axios
          .get(`settings/v1${url}?${query.toString()}`, {
            expectList: true,
          })
          .then(({ data }) => data)

        if (!data) {
          return
        }

        await Model.deleteAll()

        await this.$db().model(Model).insertOrUpdate({
          data,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async apiUpdateOrCreate({ dispatch }, formData) {
      const apiAction = formData.groupId ? this.$axios.$put : this.$axios.$post
      const url = createApiUrl(
        Project.entity,
        formData.projectId,
        Model.entity,
        formData.groupId,
      )

      const { data } = await apiAction(`settings/v1${url}`, formData)

      if (!data) {
        return {}
      }

      const { [Model.entity]: entities } = await this.$db()
        .model(Model)
        .insertOrUpdate({
          data,
        })

      return entities.shift()
    },
    async apiUpdate({ dispatch }, formData) {
      const apiAction = this.$axios.$put
      const url = createApiUrl(
        Project.entity,
        formData.projectId,
        Model.entity,
        formData.groupId,
      )

      const { data } = await apiAction(`settings/v1${url}`, formData)

      if (!data) {
        return {}
      }

      const group = data.find(({ id }) => id === formData.groupId)

      this.$db().model(Model).insertOrUpdate({
        data: group,
      })
    },
    async apiSwap({ dispatch }, formData) {
      const apiAction = this.$axios.$put
      const url = createApiUrl(Project.entity, formData.projectId, Model.entity)
      const { data } = await apiAction(`settings/v1${url}/swap`, formData)

      if (!data) {
        return {}
      }
    },
    async apiDelete({ dispatch }, formData) {
      const apiAction = this.$axios.$delete
      const url = createApiUrl(
        Project.entity,
        formData.projectId,
        Model.entity,
        formData.groupId,
      )
      const { data } = await apiAction(`settings/v1${url}`)

      if (!data) {
        return {}
      }
    },

    /**
     * @param {function} dispatch - Function used to call a store action
     * @param {string} projectId - Project ID
     * @param {string} currentStoryId - Original Story
     * @param {string} newStoryId - Newly created story
     */
    async addToGroup({ dispatch }, { projectId, currentStoryId, newStoryId }) {
      await this.$db().model(Model).dispatch('fetchAll', projectId)

      const currentStoryGroup = this.$db().model(Model).getters('getByStoryId')(
        projectId,
        currentStoryId,
      )

      if (!currentStoryGroup) {
        return
      }

      const groupPayload = {
        groupId: currentStoryGroup.id,
        items: [...currentStoryGroup.items, newStoryId],
        projectId,
      }

      await this.$db()
        .model(Model)
        .dispatch('apiUpdate', {
          ...groupPayload,
        })
    },
  },
}
