import stateSetters from '@common/plugins/state-setters'
import Model from '@common/models/orm/Project'
import LanguageModel from '@common/models/orm/Language'
import { createApiUrl } from '@common/plugins/helpers'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {},
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchAddedLanguages({ dispatch }, id) {
      const url = createApiUrl(Model.entity, id, 'languages')

      const { data } = await this.$axios
        .get(url, { expectList: true })
        .then(({ data }) => data)

      if (!data) {
        return
      }

      await this.$db()
        .model(LanguageModel)
        .insertOrUpdate({
          data: data.map((item) => ({ ...item, projectId: id })),
        })
    },
    async addLanguage({ dispatch }, formData) {
      const url = createApiUrl(Model.entity, formData.id, 'languages')

      try {
        const { data } = await this.$axios
          .post(url, formData)
          .then(({ data }) => data)

        this.$db().model(LanguageModel).insertOrUpdate({
          data,
        })
      } catch (err) {}
    },
    async deleteLanguage({ dispatch }, formData) {
      const url = createApiUrl(
        Model.entity,
        formData.id,
        'languages',
        formData.langId,
      )

      try {
        await this.$axios.delete(url)

        this.$db().model(LanguageModel).delete(formData.langId)
      } catch (err) {}
    },
    async toggleLanguageActivity({ dispatch }, formData) {
      const url = createApiUrl(
        Model.entity,
        formData.id,
        'languages',
        formData.languageId,
      )

      try {
        const { data } = await this.$axios
          .put(url, {
            isActive: formData.isActive,
          })
          .then(({ data }) => data)

        this.$db().model(LanguageModel).insertOrUpdate({
          data,
        })
      } catch (err) {}
    },
    async saveLanguageDeterminers({ dispatch }, formData) {
      const url = createApiUrl(
        Model.entity,
        formData.id,
        'language-determiners',
      )

      try {
        await this.$axios.post(url, formData.payload).then(({ data }) => data)
      } catch (err) {}
    },
    async fetchLanguageDeterminers({ dispatch }, id) {
      const url = createApiUrl(Model.entity, id, 'language-determiners')

      const { data } = await this.$axios
        .get(url, { expectList: true })
        .then(({ data }) => data)

      if (!data) {
        return
      }

      return data
    },
  },
}
