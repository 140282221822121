import Model from '@common/models/orm/OrmBaseModel'
import User from '@common/models/orm/User'

export default class OrmCrudModel extends Model {
  static className = 'OrmCrudModel'

  static fields() {
    return {
      createdAt: this.attr(''),
      createdBy: this.belongsTo(User, 'createdById'),
      createdById: this.attr(''),
      updatedAt: this.attr(''),
      updatedBy: this.belongsTo(User, 'updatedById'),
      updatedById: this.attr(''),
    }
  }
}
