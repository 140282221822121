import {
  required,
  integer,
  minLength,
  maxLength,
  minValue,
  maxValue,
} from 'vuelidate/lib/validators'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import {
  MEDIA_TYPE_ALL,
  MEDIA_TYPE_IMAGE,
  MEDIA_TYPE_DOCUMENT,
  MEDIA_TYPE_VIDEO,
} from '@common/constants/media-types'
import { MODIFIER_FILE_UPLOAD } from '@common/constants/chat-element-modifiers'
import Model from '@/models/chatElements/ChatElementBaseModel'

export const MEDIA_TYPES = [
  { label: 'form.media_type.all', key: MEDIA_TYPE_ALL },
  { label: 'form.media_type.document', key: MEDIA_TYPE_DOCUMENT },
  { label: 'form.media_type.image', key: MEDIA_TYPE_IMAGE },
  { label: 'form.media_type.video', key: MEDIA_TYPE_VIDEO },
]

export default class ChatElementFileUpload extends Model {
  static className = 'ChatElementFileUpload'

  static entity = 'file-upload'
  static label = 'general.chat_element_file_upload'
  static direction = Model.INTERACTION_IN
  static active = true

  static formFields = ['maxFiles', 'allowedTypes', 'content']

  static fields() {
    return {
      maxFiles: this.number(),
      allowedTypes: this.attr([]),
      content: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonFileUpload')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormFileUpload')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormFileUpload/ChatFormFileUploadTranslate'
      )
  }

  static getIcon() {
    return () => import('~common/assets/inline/cloud-upload.svg')
  }

  static getAvailableModifiers() {
    return [MODIFIER_FILE_UPLOAD]
  }

  static getFormValidations() {
    return {
      form: {
        maxFiles: {
          required,
          integer,
          minValue: minValue(1),
          maxValue: maxValue(10),
        },
        allowedTypes: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(MEDIA_TYPES.length - 1),
          missingSelection: (types) =>
            types.every((i) => MEDIA_TYPES.map((i) => i.key).includes(i)),
        },
        content: {},
      },
      variableId: {
        required,
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        content: {},
      },
    }
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      maxFiles: this.maxFiles,
      allowedTypes: this.allowedTypes,
      content: this.content,
      variables,
      storyLinkTargets,
    }
  }
}
