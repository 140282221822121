import stateSetters from '@common/plugins/state-setters'
import { createApiUrl } from '@common/plugins/helpers'
import Timezone from '@/models/orm/Timezone'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchAll(context) {
      const url = createApiUrl(Timezone.endpoint)

      try {
        const { data } = await this.$robot.get(url)

        await Timezone.create({
          data,
        })
      } catch (err) {
        throw err
      }
    },
    async addToShortlisted({ dispatch }, { value, $id }) {
      if (!value) {
        return
      }

      await Timezone.update({
        where: $id,
        data: { includeInShortList: true },
      })
    },
  },
}
