<template>
  <main class="error-page--authenticated">
    <div class="container is-fullhd flex-hv-centered">
      <slot :error="error" />
    </div>
  </main>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
}
</script>

<style lang="scss" scoped>
$background-wave-height: 416px;
$background-wave-width: 2574px;

.error-page--authenticated {
  position: relative;
  height: calc(100vh - #{$header-height});
  min-height: calc($background-wave-height * 1.5);
  padding-top: calc($background-wave-height / 4);
  border-top: 7vh solid $white;
  background-image: linear-gradient(
    $white 0%,
    $white 400px,
    $blue-500 400px,
    $blue-500 100%
  );

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: '';
    width: 100%;
    height: $background-wave-height;
    background: url('/images/background-wave.svg') repeat-x top left;
    background-size: $background-wave-width $background-wave-height;
  }
  @media screen and (min-width: calc($tablet - 1px)) {
    padding-top: calc($background-wave-height / 2);
    border-top: 17vh solid $white;
  }
}
</style>
