import stateSetters from '@common/plugins/state-setters'
import User from '@common/models/orm/User'
import { createApiUrl } from '@common/plugins/helpers'
import Model from '@common/models/orm/Workspace'
import Project from '@common/models/orm/Project'

const defaultState = {
  current: localStorage.getItem('current_workspace'),
  changing: false,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    defaultWorkspace: (state, { query }) => {
      if (state.current) {
        const selectedWorkpspace = query().whereId(state.current).first()

        if (selectedWorkpspace) {
          return selectedWorkpspace
        }
      }

      const defaultWorkspace = query().first()

      if (defaultWorkspace) {
        localStorage.setItem('current_workspace', defaultWorkspace.id)
      }

      return defaultWorkspace
    },

    isChanging: (state) => state.changing,
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async setCurrent({ commit }, id) {
      await commit('setCurrent', id)
    },
    setChanging({ commit }, value) {
      commit('setChanging', value)
    },
    async fetchAll({ dispatch }) {
      const url = createApiUrl(Model.entity)

      const { data } = await this.$axios.get(url, {
        expectList: true,
      })

      const entity = await Model.insertOrUpdate({
        data: data.data,
      })

      return entity
    },
    async fetchRelations({ dispatch }, id) {
      await this.$db().model(Project).dispatch('fetchAll', id)

      const projectContracts = this.$db()
        .model(Project)
        .getters('getByWorkspaceId')(id)
        .forEach(({ billingContractId }) => {
          if (billingContractId) {
            this.$db()
              .model(Project)
              .dispatch('fetchContract', billingContractId)
          }
        })

      if (projectContracts) {
        await Promise.all(projectContracts)
      }
    },
    async fetchMember({ state, commit, dispatch }, { workspaceId, userId }) {
      const url = createApiUrl(Model.entity, workspaceId, 'users', userId)

      try {
        const data = await this.$axios
          .$get(url)
          .then(({ data }) => data)
          .then(({ projects, role, user, ...data }) => {
            const projectIds = projects.map(({ id }) => id)

            return { ...user, ...data, role, projects: projectIds, workspaceId }
          })

        await this.$db().model(User).insertOrUpdate({
          data,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
    async fetchById({ state, commit, dispatch }, id) {
      if (!state.data[id]) {
        await dispatch(
          'fetchByModelId',
          { Model, id },
          {
            root: true,
          },
        )
      }

      commit('setCurrent', id)
    },
    async updateUserPermission(
      { state, commit, dispatch },
      { userId, workspaceId, role, projects = [] },
    ) {
      const url = createApiUrl(Model.entity, workspaceId, 'users', userId)

      try {
        const data = await this.$axios
          .$put(url, { role, projects })
          .then(({ data }) => data)
          .then(({ projects, role, user, ...data }) => {
            const projectIds = projects.map(({ id }) => id)

            return { ...user, ...data, role, projects: projectIds, workspaceId }
          })

        await this.$db().model(User).update({
          where: userId,
          data,
        })
      } catch (error) {
        throw error
      }
    },
    async createWorkspace({ state, commit, dispatch }, name) {
      const url = createApiUrl(Model.entity)

      try {
        const data = await this.$axios
          .$post(url, { name })
          .then(({ data }) => data)

        return data
      } catch (error) {
        throw error
      }
    },
    async removeUser({ state, commit, dispatch }, { userId, workspaceId }) {
      const url = createApiUrl(Model.entity, workspaceId, 'users', userId)

      try {
        await this.$axios.$delete(url)

        await this.$db().model(User).delete(userId)
      } catch (error) {
        throw new Error(error)
      }
    },
    async fetchMembers({ state, commit, dispatch }, id) {
      try {
        const url = createApiUrl(Model.entity, id, 'users')
        const { data } = await this.$axios.$get(url, {
          expectList: true,
        })

        if (!Array.isArray(data)) {
          return
        }

        const currentUser = this.$db().model(User).getters('current')

        const users = data.map(({ projects, role, user, ...data }) => {
          const projectIds = projects.map(({ id }) => id)

          const userData = {
            ...user,
            ...data,
            role,
            projects: projectIds,
            workspaceId: id,
          }

          if (!currentUser.email) {
            delete userData.email
          }

          return userData
        })

        await this.$db().model(User).insertOrUpdate({
          data: users,
        })
      } catch (error) {
        throw new Error(error)
      }
    },
  },
}
