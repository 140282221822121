import ModelFactoryBase from '@common/models/ModelFactoryBase'

const allowedLanguages = [
  {
    entity: 'de',
    langcode: 'de',
    label: 'general.german',
    nativeLabel: 'Deutsch',
    userInterfaceLanguage: true,
  },
  {
    entity: 'en',
    langcode: 'en',
    label: 'general.english',
    nativeLabel: 'English',
    userInterfaceLanguage: true,
  },
  {
    entity: 'fr',
    langcode: 'fr',
    label: 'general.french',
    nativeLabel: 'Français',
    userInterfaceLanguage: false,
  },
  {
    entity: 'it',
    langcode: 'it',
    label: 'general.italian',
    nativeLabel: 'Italiano',
    userInterfaceLanguage: false,
  },
]

export default class LanguageFactory extends ModelFactoryBase {
  static map = allowedLanguages

  static getAll() {
    return this.map
  }

  static getAllBaseOrCustomLanguage(langcode) {
    const isBaseLanguage = this.map.find((l) => l.langcode === langcode)

    if (isBaseLanguage) {
      return this.map
    }

    return [
      {
        entity: langcode,
        langcode,
        label: langcode,
        nativeLabel: langcode,
        userInterfaceLanguage: false,
      },
    ]
  }

  static getUserInterfaceLanguages() {
    return this.map.filter((language) => language.userInterfaceLanguage)
  }

  static isValidCode(value) {
    return !!this.map.find(({ langcode }) => langcode === value)
  }

  static isValidUserInterfaceCode(value) {
    return !!this.map.find(
      ({ langcode, userInterfaceLanguage }) =>
        langcode === value && !!userInterfaceLanguage,
    )
  }

  static getDefaultLanguage() {
    return this.map[0]
  }
}
