import stateSetters from '@common/plugins/state-setters'
import Story from '@common/models/orm/Story'

import Classifiers from '@/models/orm/Classifiers'
import ClassifierLabels from '@/models/orm/ClassifierLabels'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    classifiers: (state) => Classifiers.query().get(),
    classifierById:
      (state, { query }) =>
      (classifierId) => {
        return Classifiers.query().whereId(classifierId).get()
      },
    labels: (state) => ClassifierLabels.query().get(),
    labelsByClassifierId:
      (state, { query }) =>
      (classifierId) => {
        return ClassifierLabels.query()
          .where('classifierId', classifierId)
          .get()
      },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByStoryId({ dispatch }, storyId) {
      const { data } = await this.$db().model(Story).dispatch(
        'fetchChatNodeData',
        {
          id: storyId,
          node: 'intent',
          endpoint: 'options',
        },
        { root: true },
      )

      const { classifiers } = data

      // Clear all existing
      await Classifiers.delete()

      await Classifiers.insert({
        data: classifiers.map((classifier) => ({
          id: classifier.value,
          name: classifier.text,
        })),
      })

      return classifiers
    },
    async fetchLabelsByClassifierId({ dispatch }, { storyId, classifierId }) {
      const { data } = await this.$db()
        .model(Story)
        .dispatch(
          'fetchChatNodeData',
          {
            id: storyId,
            node: 'intent',
            endpoint: 'options',
            suffix: `/${classifierId}`,
          },
          { root: true },
        )

      const { labels } = data

      await ClassifierLabels.insertOrUpdate({
        data: labels.map((label) => ({
          id: label.value,
          name: label.text,
          classifierId,
        })),
      })

      return labels
    },
    async clearLabels({ commit }) {
      await ClassifierLabels.deleteAll()
    },
  },
}
