import stateSetters from '@common/plugins/state-setters'
import { createApiUrl } from '@common/plugins/helpers'
import Project from '@common/models/orm/Project'
import ChatbotExtension from '@/models/orm/ChatbotExtension'

const defaultState = {
  current: null,
}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    current: (state, { query }) => query().whereId(state.current).first(),
    getByProjectId:
      (state, { query }) =>
      (projectId) =>
        query().where('projectId', projectId).first(),
    getByProjectIdAndType:
      (state, { query }) =>
      (projectId, type) =>
        query().where('projectId', projectId).where('type', type).first(),
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchById({ dispatch }, id) {
      const url = createApiUrl(Project.entity, id, ChatbotExtension.endpoint)

      try {
        const data = await this.$axios.$get(url).then(({ data }) => data)

        if (!data.length) {
          return
        }

        await this.$db().model(ChatbotExtension).insertOrUpdate({ data })
      } catch (error) {
        throw error
      }
    },
    async createExtension({ dispatch }, { projectId, type, extensionData }) {
      const payload = {
        projectId,
        type,
        payload: extensionData,
      }

      try {
        const data = await this.$axios
          .$post(`/${ChatbotExtension.endpoint}`, payload)
          .then(({ data }) => data)

        await this.$db().model(ChatbotExtension).insertOrUpdate({ data })
      } catch (error) {
        throw error
      }
    },
    async deleteExtension({ dispatch }, extensionId) {
      const url = createApiUrl(ChatbotExtension.endpoint, extensionId)

      try {
        await this.$axios.$delete(url)
        await this.$db().model(ChatbotExtension).delete(extensionId)
      } catch (error) {
        throw error
      }
    },
    async changeExtensionSelection({ dispatch }, { extensionId, selected }) {
      const url = createApiUrl(ChatbotExtension.endpoint, extensionId)

      const data = {
        selected,
      }

      try {
        await this.$axios.$put(url, data)
      } catch (error) {
        throw error
      }
    },
  },
}
