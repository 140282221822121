import stateSetters from '@common/plugins/state-setters'

import Story from '@common/models/orm/Story'
import Workflow from '@/models/orm/Workflow'

const defaultState = {}

export default {
  namespaced: true,
  state: () => defaultState,
  getters: {
    getByProjectId:
      (state, { query }) =>
      (projectId) => {
        return Workflow.query().where('projectId', projectId).get()
      },
    getById:
      (state, { query }) =>
      (id) => {
        return Workflow.query().whereId(id).first()
      },
  },
  mutations: {
    ...stateSetters(defaultState),
  },
  actions: {
    async fetchByProjectId({ dispatch }, { storyId, projectId }) {
      const { data } = await this.$db().model(Story).dispatch(
        'fetchChatNodeData',
        {
          id: storyId,
          node: 'workflow',
          endpoint: 'options',
        },
        { root: true },
      )

      // Add the projectId to the workflows, so we can query it later on
      const workflows = data.workflows.map((workflow) => ({
        id: workflow.id,
        name: workflow.name,
        paths: workflow.paths,
        workflowId: workflow.workflowId,
        virtual: workflow.virtual,
        projectId,
      }))

      await Workflow.insertOrUpdate({
        data: workflows,
      })

      // delete all isNew workflows
      await Workflow.delete((wf) => wf.isNew)
    },
  },
}
