<template>
  <div class="site">
    <AppHeader
      :workspace="workspace"
      :user="user"
      :project="project"
      :is-on-project-page="isOnProject"
    />
    <nuxt class="site--content" />
  </div>
</template>

<script>
import User from '@common/models/orm/User'
import Workspace from '@common/models/orm/Workspace'
import Project from '@common/models/orm/Project'

const AppHeader = () => import('@common/components/AppHeader')

export default {
  components: {
    AppHeader,
  },
  computed: {
    user() {
      return this.$store.$db().model(User).getters('current')
    },
    workspace() {
      return this.$store.$db().model(Workspace).getters('current')
    },
    project() {
      return this.$store.$db().model(Project).getters('current')
    },
    isOnProject() {
      return !!this.$route?.fullPath.includes('project')
    },
  },
  head() {
    return {
      bodyAttrs: {
        class: ['bg-primary-radial-gradient'],
      },
    }
  },
}
</script>

<style lang="scss">
.site {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
</style>
