export const DOWNLOAD_TYPE_UPLOAD = 'upload'
export const DOWNLOAD_TYPE_URL = 'url'
export const DOWNLOAD_TYPE_VARIABLE = 'variable'

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const FILE_TYPE_SPECIFIERS = {
  [DOWNLOAD_TYPE_UPLOAD]: [
    '.txt',
    'text/plain',
    '.rtf',
    'application/rtf',
    '.csv',
    'text/csv',

    // OpenDocument
    '.odt',
    'application/vnd.oasis.opendocument.text',
    '.ods',
    'application/vnd.oasis.opendocument.spreadsheet',
    '.odp',
    'application/vnd.oasis.opendocument.presentation',

    // MS Office
    '.doc',
    'application/msword',
    '.docx',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.ppt',
    'application/vnd.ms-powerpoint',
    '.pptx',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    '.xls',
    'application/vnd.ms-excel',
    '.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',

    // Adobe
    '.pdf',
    'application/pdf',

    // image
    '.jpeg',
    '.jpg',
    'image/jpeg',
    '.png',
    'image/png',
    '.gif',
    'image/gif',
    '.svg',
    'image/svg+xml',

    // media

    '.mp4',
    'video/mp4',
    '.mov',
    'video/quicktime',
    'video/H264',
    'video/H265',
  ],
}
