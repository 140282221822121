import Model from '@common/models/orm/OrmBaseModel'

export default class Workflow extends Model {
  static className = 'Workflow'
  static entity = 'workflows'

  static formFields = []

  static fields() {
    return {
      ...super.fields(),
      id: this.string(''), // force string
      name: this.attr(''),
      paths: this.attr([]),
      projectId: this.attr(''),
      workflowId: this.attr(''),
      isNew: this.boolean(false),
      virtual: this.boolean(false),
    }
  }

  static getFormValidations() {
    return {
      form: {},
    }
  }
}
