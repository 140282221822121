import Model from '@common/models/orm/OrmCrudModel'

export default class Classifiers extends Model {
  static className = 'Classifiers'

  static entity = 'classifiers'

  static fields() {
    return {
      id: this.attr(''),
      name: this.attr(''),
    }
  }
}
