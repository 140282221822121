import Model from '@common/models/orm/OrmCrudModel'
import LineItem from '@common/models/orm/LineItem'
import { DEFAULT_CURRENCY } from '@common/models/BillingFactory'

export default class ContractBilling extends Model {
  static className = 'ContractBilling'

  static entity = 'billing-contract-billings'

  static endpoint = 'contracts'

  static formFields = []

  static fields() {
    return {
      id: this.string(''),
      currency: this.string(DEFAULT_CURRENCY),
      totalCostGross: this.number(0),
      totalCostNet: this.number(0),
      totalVat: this.number(0),
      plannedUpgradeDowngrade: this.attr({}).nullable(),
      itemIds: this.attr([]),
      items: this.hasManyBy(LineItem, 'itemIds'),
    }
  }

  static getFormValidations() {
    return {}
  }
}
