import DOMpurify from 'dompurify'
import { required } from 'vuelidate/lib/validators'
import { MODIFIER_INPUT_EXAMPLES } from '@common/constants/chat-element-modifiers'

import { PLAN_EXPERT } from '@common/models/BillingFactory'
import StoryLinkTarget from '@common/models/orm/StoryLinkTarget'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementFaq extends Model {
  static className = 'ChatElementFaq'
  static entity = 'faq'
  static label = 'general.chat_element_faq'

  static TYPE_FAQ = 'faq'
  static formFields = ['question', 'content']

  static fields() {
    return {
      question: this.attr({}),
      content: this.attr(''),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonFAQ')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormFAQ')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormFAQ/ChatFormFaqTranslate'
      )
  }

  static getIcon() {
    return () => import('~/assets/inline/faq-icon.svg')
  }

  static getAvailableModifiers() {
    return [MODIFIER_INPUT_EXAMPLES]
  }

  static getFormValidations() {
    return {
      form: {
        question: {
          id: {
            required,
          },
          name: {
            required,
          },
          required,
        },
        content: {},
      },
      variableId: {
        required,
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        content: {},
      },
    }
  }

  static getPlanAvailability() {
    return [PLAN_EXPERT]
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()
    const storyLinkTargets = $store.$db().model(StoryLinkTarget).all()

    return {
      storyLinkTargets,
      variables,
      question: this.question,
      content: DOMpurify.sanitize(this.content),
    }
  }
}
