<template>
  <portal to="modals">
    <b-modal
      role="modal"
      :active="value"
      :width="width"
      scroll="keep"
      :can-cancel="canCancel"
      :custom-class="[customClasses, classes]"
      data-cy-id="modal-dialog"
      @close="closeModal"
    >
      <div class="box box-large no-box-shadow">
        <slot />
      </div>
      <button
        v-if="showIcon && isCloseIcon"
        type="button"
        class="modal-close is-large button is-primary"
        :aria-label="$t('cta.close')"
        :title="$t('cta.close')"
        @click="closeModal"
      >
        <Close />
      </button>
    </b-modal>
  </portal>
</template>

<script>
import { toClassList } from '@common/plugins/helpers'

export default {
  components: {
    Close: () => import('~common/assets/inline/close.svg'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isDark: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 640,
    },
    canCancel: {
      type: [Boolean, Array, String],
      default: () => ['escape', 'outside'],
    },
    isCloseIcon: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: String,
      default: '',
    },
  },
  computed: {
    customClasses() {
      const obj = {
        'is-dark': this.isDark,
      }

      return toClassList(obj)
    },
    showIcon() {
      return this.canCancel !== false || this.canCancel.length === 0
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        if (value) {
          document.body.style.setProperty('overflow', 'hidden')
        } else {
          document.body.style.removeProperty('overflow')
        }
      },
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal')
      this.$emit('input', !this.value)
    },
  },
}
</script>

<style lang="scss" scoped>
.no-box-shadow {
  padding: $baseline;
}
</style>
