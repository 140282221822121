import { required } from 'vuelidate/lib/validators'
import { inArray } from '@common/plugins/validators'
import ProjectVariable from '@common/models/orm/ProjectVariable'
import Model from '@/models/chatElements/ChatElementBaseModel'

export default class ChatElementDynamicList extends Model {
  static className = 'ChatElementDynamicList'

  static entity = 'dynamic-list'

  static label = 'general.chat_element_dynamic_list'

  static formFields = [
    'source',
    'heading',
    'format',
    'required',
    'multipleChoice',
  ]

  static direction = Model.INTERACTION_EFFECT

  static LIST_TYPE_LIST = 'list'
  static LIST_TYPE_CAROUSEL = 'carousel'

  static LIST_TYPES = [
    ChatElementDynamicList.LIST_TYPE_LIST,
    ChatElementDynamicList.LIST_TYPE_CAROUSEL,
  ]

  static fields() {
    return {
      source: this.string(''),
      heading: this.string(''),
      format: this.string(ChatElementDynamicList.LIST_TYPE_LIST),
      required: this.boolean(true),
      multipleChoice: this.boolean(false),
    }
  }

  static getBalloon() {
    return () => import('@/components/chatBalloons/ChatBalloonDynamicList')
  }

  static getForm() {
    return () => import('@/components/chatForms/ChatFormDynamicList')
  }

  static getTranslateForm() {
    return () =>
      import(
        '@/components/chatForms/translations/ChatFormDynamicList/ChatFormDynamicListTranslate'
      )
  }

  static getIcon() {
    return () => import('~/assets/inline/dynamic-list.svg')
  }

  static getFormValidations() {
    return {
      form: {
        source: {
          required,
        },
        format: {
          required,
          missingSelection: inArray(ChatElementDynamicList.LIST_TYPES),
        },
      },
      variableId: {
        required,
      },
    }
  }

  static getTranslateFormValidations() {
    return {
      form: {
        heading: {},
      },
    }
  }

  static getUsedVariables({ source }) {
    return [{ id: source }]
  }

  props({ $store }) {
    const variables = $store.$db().model(ProjectVariable).all()

    const source = variables.find((i) => i.id === this.source)

    return {
      source: source?.name,
      heading: this.heading,
      format: this.format,
    }
  }
}
