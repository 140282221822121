<template>
  <button
    class="sb-header--story-link"
    @click="showModal = !showModal"
    :disabled="!entity"
  >
    <MessagesBubbleSquare aria-hidden="false" tabindex="-1" />
    <span class="sb-header--story-name ellipsis" v-text="entityName" />
    <Pen />

    <StoryModal
      :visible="showModal"
      :entity="entity"
      :title="$t('md.form.story.update.title')"
      is-update
      is-dark
      @close="onClose"
      @update="onUpdate"
    />
  </button>
</template>

<script>
import StoryModal from '@/components/modals/StoryModal'
import Pen from '~common/assets/inline/pen.svg'
import MessagesBubbleSquare from '~common/assets/inline/messages-bubble-square.svg'

export default {
  components: {
    MessagesBubbleSquare,
    StoryModal,
    Pen,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
    }
  },
  computed: {
    entityName() {
      if (!this.entity) {
        return this.$tc('general.story', 1)
      }

      return this.entity.name
    },
  },
  methods: {
    onClose() {
      this.showModal = false
      this.$emit('close')
    },
    onUpdate() {
      this.showModal = false
      this.$emit('update')
    },
  },
}
</script>

<style lang="scss" scoped>
.sb-header--story-link {
  padding: 0 calc($column-gap / 2);
  display: flex;
  color: $white;
  position: relative;
  align-items: center;
  font-weight: $weight-semibold;
  font-family: $title-family;
  line-height: 1;
  background-color: transparent;
  font-size: $fontbase;
  border: none;
  outline: none !important;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    color: $white;

    svg:last-of-type {
      color: $teal;
    }
  }

  &:focus,
  &:active {
    color: $white;
    background-color: rgba($white, 0.2);
  }

  span {
    padding: 0 $nudge;
    line-height: 2;
  }

  svg:last-of-type {
    color: $grey-600;
  }
}
</style>
